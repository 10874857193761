import { AxiosError } from "axios"
import Button from "components/button"
import { ReactComponent as ExclamationWarning } from "assets/icons/merchants/exclamation-warning.svg"
import Modal from "components/modal/Modal"
import React from "react"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { updateUserStatus } from "services/api/users"
import { queryClient } from "index"
import { queryKeys } from "constants/queryKeys"
import { handleError } from "utils/getAxiosErrorMessage"

type MerchantStatusUpdaterProps = {
  isOpen: boolean
  closeStatusDialog: () => void
  user: User
}
const MerchantStatusUpdater: React.FC<MerchantStatusUpdaterProps> = ({
  isOpen,
  closeStatusDialog,
  user,
}) => {
  const { mutate: handleStatusUpdate, isLoading } = useMutation(
    updateUserStatus,
    {
      onSuccess: (response) => {
        toast.success(response.data.message)
        closeStatusDialog()
        queryClient.invalidateQueries(queryKeys.users.fetchUsers)
      },
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    }
  )

  return (
    <Modal isOpen={isOpen} closeModal={closeStatusDialog}>
      <div className="flex flex-col items-center p-6">
        <div className="flex max-w-xs flex-col items-center">
          <ExclamationWarning className="mb-5" />
          <h5 className="text-xl font-semibold ">
            {user.activated ? "Deactivate" : "Activate"} User
          </h5>
          <span className="mt-4 text-center text-sm font-normal text-gray-10/50">
            You are about to {user.activated ? "Deactivate" : "Activate"}{" "}
            <span className="font-medium text-gray-10">{`${user.firstName} ${user.lastName}`}</span>{" "}
            from your list of users.
          </span>
          <div className="mt-8 flex w-full max-w-xs justify-center gap-10">
            <Button
              onClick={closeStatusDialog}
              variant="default"
              className="h-9 w-24 text-sm"
            >
              Cancel
            </Button>
            <Button
              loading={isLoading}
              onClick={() =>
                handleStatusUpdate({
                  activate: !user.activated,
                  userId: user.id,
                })
              }
              className="h-9 w-24 text-sm"
              variant={user.activated ? "danger" : "primary"}
            >
              Proceed
            </Button>
          </div>
        </div>
      </div>{" "}
    </Modal>
  )
}

export default MerchantStatusUpdater
