import clsx from "clsx"
import Modal from "components/modal/Modal"
import { FileRenderer } from "pages/protected/KYC/Details/FileRenderer/FileRenderer"
import React from "react"
import { DisputeEvidenceType } from "./Details"

type DisputeEvidenceProps = {
  isOpen: boolean
  closeDisputeEvidence: () => void
  disputeEvidence: DisputeEvidenceType
}
const DisputeEvidence: React.FC<DisputeEvidenceProps> = ({
  isOpen,
  closeDisputeEvidence,
  disputeEvidence,
}) => {
  return (
    <Modal
      contentContainerClass={clsx(" min-h-300", {
        "max-w-[90%]": disputeEvidence.contentType === "application/pdf",
      })}
      isOpen={isOpen}
      closeModal={closeDisputeEvidence}
    >
      <div className="border-b border-b-gray-default px-8 pb-3 pt-8">
        {disputeEvidence.fileName}
      </div>
      <div className="relative my-6 flex min-h-[60vh] flex-col items-center px-8 pb-4">
        <FileRenderer
          filePath={disputeEvidence.filePath}
          contentType={disputeEvidence.contentType}
        />
      </div>
    </Modal>
  )
}

export default DisputeEvidence
