import React from "react"
import { MetricCard } from "./components/MetricCard"
import useDashboard from "./hooks/useDashboard"
import { ReactComponent as IncomeIcon } from "assets/icons/dashboard/income.svg"
import { ReactComponent as CurrencyIcon } from "assets/icons/dashboard/currency.svg"
import { ReactComponent as HashtagIcon } from "assets/icons/dashboard/hashtag.svg"
import { ReactComponent as BadgeCheckIcon } from "assets/icons/dashboard/badge-check.svg"
import { ReactComponent as ClipboardCheckIcon } from "assets/icons/dashboard/clipboard-check.svg"
import { ReactComponent as UserGroupIcon } from "assets/icons/dashboard/user-group.svg"
import { ReactComponent as CalculatorIcon } from "assets/icons/dashboard/calculator.svg"
import { ReactComponent as TransactionsIcon } from "assets/icons/dashboard/transactions.svg"
import { queryKeys } from "constants/queryKeys"
import { numberUtils } from "utils/numberUtils"
import { DateGrouping } from "constants/dashboard"

const MetricCards: React.FC<{
  startDate: string
  endDate: string
  dateGrouping: DateGrouping
}> = ({ startDate, endDate, dateGrouping }) => {
  const { data, loading } = useDashboard({
    startDate,
    endDate,
    targetQuerykeys: [queryKeys.payment.metricCards],
    dateGrouping,
  })

  return (
    <div className="mb-12 flex flex-wrap gap-6">
      <MetricCard
        Icon={TransactionsIcon}
        value={numberUtils.formatNumber(data.totalValueByDate || 0)}
        label="Total Transaction Value"
        loading={loading.loadingAllTxValueByDate}
      />
      <MetricCard
        Icon={HashtagIcon}
        value={numberUtils.formatNumber(data.totalPayout || 0)}
        label="Total Payout"
        loading={loading.loadingTotalPayout}
      />
      <MetricCard
        Icon={IncomeIcon}
        value={numberUtils.formatNumber(data.totalIncome || 0)}
        label="TGI Income"
        loading={loading.loadingTotalIncome}
      />
      <MetricCard
        Icon={BadgeCheckIcon}
        value={numberUtils.formatNumber(data.successfulByDateCount || 0)}
        label="Number of Transactions"
        loading={loading.loadingSuccessfulByDateCount}
      />
      <MetricCard
        Icon={ClipboardCheckIcon}
        value={numberUtils.formatNumber(data.activeMerchantsByDateCount || 0)}
        label="Active Merchants"
        loading={loading.loadingActiveMerchantsByDateCount}
      />
      <MetricCard
        Icon={UserGroupIcon}
        value={numberUtils.formatNumber(data.pendingByDateCount || 0)}
        label="Pending KYCs"
        loading={loading.loadingPendingByDateCount}
      />
      <MetricCard
        Icon={CalculatorIcon}
        value={numberUtils.formatNumber(data.pendingSettlementCount || 0)}
        label="Pending Settlement"
        loading={loading.loadingPendingSettlementCount}
      />
    </div>
  )
}

export default MetricCards
