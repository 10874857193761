export const customDateRanges: { [key: string]: string } = {
  allTime: "All Time",
  today: "Today",
  yesterday: "Yesterday",
  thisWeek: "This week",
  last30Days: "Last 30 days",
  last90Days: "Last 90 days",
  thisYear: "This year",
  custom: "Custom",
}

export type CustomDateRange =
  (typeof customDateRanges)[keyof typeof customDateRanges]
