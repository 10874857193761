export function parseJSON(jsonString: string | null) {
  let jsonData

  if (!jsonString) {
    return {}
  }
  try {
    jsonData = JSON.parse(jsonString)
  } catch (error) {
    console.log("Error parsing JSON: ", error)
    return {}
  }
  return jsonData
}
