import React from "react"
import TGIPLogo from "../../assets/images/TGIPrimaryLogo.svg"
import NotFoundImg from "../../assets/images/NotFound.svg"
import Button from "components/button"
import { useNavigate } from "react-router-dom"
import { ReactComponent as NotFoundGraphic } from "assets/icons/not-found-graphic.svg"
const NotFound: React.FC = () => {
  const navigate = useNavigate()
  return (
    <div className="relative flex h-screen flex-col items-center bg-[#F9FAFB]">
      <img src={TGIPLogo} alt="logo" className="mt-16 h-[1.375rem] w-[5rem]" />

      <h2 className="mt-12 text-5xl font-semibold">Oops,</h2>

      <img src={NotFoundImg} alt="not found" className="mt-7 w-80" />

      <h4 className="mt-8 text-3xl font-medium">Page Not Found</h4>

      <span className="mt-7 text-2xl font-normal text-[#5F5F5F]">
        The page you are looking for does not exist
      </span>

      <Button className="mt-24 w-96" onClick={() => navigate("/")}>
        Return To Login
      </Button>

      <NotFoundGraphic className="absolute bottom-0 right-0 w-96" />
    </div>
  )
}

export default NotFound
