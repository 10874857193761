import React, { useState } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import { useNavigate } from "react-router-dom"
import Modal from "components/modal/Modal"
import Status from "components/Status/Status"
import { numberUtils } from "utils/numberUtils"

const columnHelper = createColumnHelper<Transaction>()

export const transactionColumns = [
  columnHelper.accessor("tgipayReference", {
    cell: (info) => info.getValue(),
    header: () => <span>Transaction Ref</span>,
  }),
  columnHelper.accessor("merchantId", {
    cell: (info) => info.getValue(),
    header: () => <span>Merchant ID</span>,
  }),
  columnHelper.accessor((row) => row.amount, {
    id: "transactionAmount",
    cell: (info) => <span>{numberUtils.formatNumber(info.getValue())}</span>,
    header: () => <span>Amount(NGN)</span>,
  }),
  columnHelper.accessor("fee", {
    header: () => "TGI Pay Income (NGN)",
    cell: (info) => <span>{numberUtils.formatNumber(info.getValue())}</span>,
  }),
  columnHelper.accessor("paymentChannel", {
    header: () => "Payment Channel",
    cell: (info) => <span>{info.renderValue()}</span>,
  }),
  columnHelper.accessor("status", {
    header: () => <span>Status</span>,
    cell: (info) => (
      <Status variant={info.getValue()}>{info.getValue()}</Status>
    ),
  }),
  columnHelper.accessor("transactionDate", {
    header: "Created",
    cell: (info) => <span>{info.getValue()}</span>,
  }),
  columnHelper.accessor("action", {
    header: "Action",
    cell: ({ cell }) => {
      const {
        row: { original: originalData },
      } = cell
      const navigate = useNavigate()
      const [showModal, setShowModal] = useState(false)
      function closeModal() {
        setShowModal(false)
      }
      return (
        <>
          <span
            onClick={() => {
              navigate(
                `details?transactionId=${originalData.id}&merchantId=${originalData.merchantId}`
              )
              setShowModal(true)
            }}
            className="cursor-pointer font-semibold text-primary-default"
          >
            View
          </span>
          <Modal isOpen={showModal} closeModal={closeModal}>
            <div className="p-6">What do you want to put here?</div>
          </Modal>
        </>
      )
    },
  }),
]
