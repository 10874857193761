import { yupResolver } from "@hookform/resolvers/yup"
import React, { useEffect } from "react"
import { FieldValues, FormProvider, useForm } from "react-hook-form"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import Button from "components/button"
import Input from "components/input"
import { PublicLayout } from "layout"
import { resetPasswordSchema } from "./validation-schemas"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { useMutation, useQuery } from "react-query"
import { resetPassword, validateAccessCode } from "services/api/auth"
import { handleError } from "utils/getAxiosErrorMessage"
import FullScreenLoader from "components/FullscreenLoader"

const ResetPassword = () => {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const code = searchParams.get("code")

  const { isFetching } = useQuery(
    "validate-access-code",
    () => validateAccessCode({ accessCode: code }),
    {
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(handleError(error))
        navigate("/invalid-reset-link")
      },
    }
  )

  useEffect(() => {
    if (!code) {
      navigate("/invalid-reset-link")
    }
  }, [code])

  const methods = useForm({
    resolver: yupResolver(resetPasswordSchema),
  })
  const { handleSubmit } = methods

  const { mutate: handlePasswordReset, isLoading } = useMutation(
    resetPassword,
    {
      onSuccess: (response) => {
        navigate("/reset-success")
      },
      onError: (error: AxiosError) => {
        const errorMessage = handleError(error)
        toast.error(errorMessage)

        if (errorMessage.includes("expired")) {
          navigate("/invalid-reset-link")
        }
      },
    }
  )

  const onSubmit = (data: FieldValues) => {
    handlePasswordReset({
      accessKey: code,
      password: data.password,
      repeatPassword: data.confirmPassword,
    })
  }

  return (
    <PublicLayout
      mainTitle="Reset Password"
      contentArea={
        <>
          {isFetching ? (
            <FullScreenLoader
              loading={isFetching}
              message="Verifying access code"
            />
          ) : (
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Input label="New Password" name="password" type="password" />

                <Input
                  label="Confirm New Password"
                  type="password"
                  name="confirmPassword"
                  inputContainerProps={{ className: "mt-8" }}
                />

                <Button
                  loading={isLoading}
                  className="mt-8 w-full"
                  variant="primary"
                >
                  Confirm
                </Button>
              </form>
            </FormProvider>
          )}
        </>
      }
    />
  )
}

export default ResetPassword
