import { AxiosResponse } from "axios"
import { NoAuthAxiosInstance } from "services/axios/authApi"
import { LoginParameters, LoginResponse } from "types/auth"
import { authEndpoints } from "../../constants/endpoints"
import { decodeJWT } from "utils/decodeJWT"
import { store } from "redux/store"
import { saveProfileDetails } from "redux/profile/profileSlice"
import { getAuthUser } from "utils/auth"

export const login = async (
  loginData: LoginParameters
): Promise<AxiosResponse<LoginResponse>> => {
  const response = await NoAuthAxiosInstance.post(
    authEndpoints.login,
    loginData
  )
  return response
}

export const validateOTP = async (otpData: {
  otp: string
  accessKey: string | null
}): Promise<AxiosResponse> => {
  const response = await NoAuthAxiosInstance.post(
    authEndpoints.validateOTP,
    otpData
  )

  return response
}

export const validateAccessCode = async (payload: {
  accessCode: string | null
}): Promise<AxiosResponse> => {
  const response = await NoAuthAxiosInstance.get(
    `${authEndpoints.validateAccessCode}/${payload.accessCode}`
  )

  return response
}

export const forgotPassword = async (forgotPwdData: {
  email: string
}): Promise<AxiosResponse> => {
  const response = await NoAuthAxiosInstance.post(
    authEndpoints.forgotPassword,
    forgotPwdData
  )

  return response
}

export const resetPassword = async (
  resetPwdData: unknown
): Promise<AxiosResponse> => {
  const response = await NoAuthAxiosInstance.post(
    authEndpoints.resetPassword,
    resetPwdData
  )

  return response
}

export const regerateOtp = async (data: unknown) => {
  const response = await NoAuthAxiosInstance.post(
    authEndpoints.regerateOtp,
    data
  )
  return response
}

export const generateNewAccessToken = async () => {
  const authUser = sessionStorage.getItem("authUser")
  const authInfo = authUser && JSON.parse(authUser)
  const response = await NoAuthAxiosInstance.post(
    `${authEndpoints.refreshToken}`,
    null,
    {
      headers: {
        refresh: getAuthUser()?.authUser.refreshToken,
      },
    }
  )

  const newAuthInfo = {
    ...authInfo,
    accessToken: response.data.data.accessToken,
    refreshToken: response.data.data.refreshToken,
  }

  sessionStorage.setItem("authUser", JSON.stringify(newAuthInfo))

  const decoded = decodeJWT(response.data.data.accessToken)
  store.dispatch(saveProfileDetails(decoded))
  return response
}
