import React, { useState } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import { MerchantApprovalRequest } from "types/merchants"
import MerchantApprovalModal from "../Details/ApprovalRequestDetails"
import ApprovalTwoFactor from "../Details/TwoFactor"
import { useOTP } from "hooks/useOTP"
import { useAuthUser } from "hooks/useAuthUser"

const columnHelper = createColumnHelper<MerchantApprovalRequest>()

export const approvalRequestColumns = [
  columnHelper.accessor("merchantId", {
    cell: (info) => info.getValue(),
    header: () => <span>Merchant ID</span>,
  }),
  columnHelper.accessor((row) => row.businessName, {
    id: "businessName",
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <span>Business Name</span>,
  }),
  columnHelper.accessor("state", {
    header: () => <span>State</span>,
  }),
  columnHelper.accessor("action", {
    header: "Action",
    cell: ({ cell }) => {
      const {
        row: { original: originalData },
      } = cell

      const { otp } = useOTP()
      const { authUser } = useAuthUser()

      const [showModal, setShowModal] = useState(false)
      const [showOTPDialog, setShowOTPDialog] = useState(false)
      const [operation, setOperation] = useState<"approve" | "decline">(
        "approve"
      )

      function closeModal() {
        setShowModal(false)
      }

      const handleApprove = () => {
        closeModal()
        if (authUser?.email) {
          otp.generate.handleOTPGeneration(authUser.email, {
            onSuccess: () => {
              setShowOTPDialog(true)
              setOperation("approve")
            },
          })
        }
      }

      const handleDecline = () => {
        closeModal()
        if (authUser?.email) {
          otp.generate.handleOTPGeneration(authUser.email, {
            onSuccess: () => {
              setShowOTPDialog(true)
              setOperation("decline")
            },
          })
        }
      }

      const close2fa = () => {
        setShowOTPDialog(false)
      }

      return (
        <>
          <span
            onClick={() => {
              setShowModal(true)
            }}
            className="cursor-pointer font-semibold text-primary-default"
          >
            View
          </span>
          <MerchantApprovalModal
            isOpen={showModal}
            closeModal={closeModal}
            originalData={originalData}
            handleApprove={handleApprove}
            handleDecline={handleDecline}
          />

          <ApprovalTwoFactor
            isOpen={showOTPDialog}
            closeTwoFactor={close2fa}
            merchantApprovalDetails={originalData}
            operation={operation}
          />
        </>
      )
    },
  }),
]
