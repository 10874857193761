import React from "react"
type Props = {
  message: string
  sender: string
  dateTime: string
}
const DisputeMessage: React.FC<Props> = ({ message, dateTime }) => {
  return (
    <div className="my-8 flex flex-col gap-5">
      <div className="font-medium">{message}</div>
      <div className="flex flex-col">
        <div className="ml-auto text-gray-10/60">{dateTime}</div>
      </div>
    </div>
  )
}

export default DisputeMessage
