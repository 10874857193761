// import { UserAxiosInstance } from "../axios/userApi"
import { UserAxiosInstance } from "services/axios/userApi"
import { auditEndpoints } from "../../constants/endpoints"
import axios from "axios"

export const fetchAudit = async (payload: {
  currentPage: number
  pageSize: number
  search: string
}) => {
  const response = await UserAxiosInstance.get(
    `${auditEndpoints.fetchAudit}?page=${payload.currentPage}&size=${payload.pageSize}&${payload.search}`
  )
  return response
}

export const fetchAuditPDF = async (payload: {
  currentPage: number
  pageSize: number
  search: string
}) => {
  const response = await UserAxiosInstance.get(
    `${auditEndpoints.fetchAudit}?page=${payload.currentPage}&size=${payload.pageSize}&${payload.search}`
  )
  return response
}

export const downloadAudit = async (payload: {
  auditId: string | null | undefined
}) => {
  const response = await axios.get(
    `${auditEndpoints.downloadAudit}/${payload.auditId}`
  )
  return response
}
