import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { ReactComponent as UsersIcon } from "assets/icons/teams/users.svg"
import Button from "components/button"
import TopBar from "layout/protected/ContentArea/TopBar"
import React, { Dispatch, SetStateAction, useState } from "react"
import UserTable from "./UserTable/UserTable"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import NewUserDialog from "./NewUserDialog"
import useUser from "./hooks/useUser"
import PermissionGate from "components/PermissionGate/PermissionGate"
import { PERMISSIONS } from "constants/permissions"
import ListAccessDenied from "components/PermissionGate/ListAccessDenied"

const ManageUsers: React.FC<{
  setshowRoleMgtUI: Dispatch<SetStateAction<boolean>>
}> = ({ setshowRoleMgtUI }) => {
  const [showNewUserModal, setShowNewUserModal] = useState(false)
  const { user, pagination } = useUser()

  return (
    <PermissionGate
      allowedPermissions={[PERMISSIONS.VIEW_TGIPAY_USERS]}
      fallback={<ListAccessDenied />}
    >
      <TopBar
        pageTitle="Admin"
        subTitle="Manage TGI Pay admin roles and users."
      />

      <div className="flex justify-end py-2">
        <div className="flex gap-3">
          <Button
            variant="link"
            className="flex h-11-5 items-center gap-3 bg-white"
          >
            <span className="stroke-primary-default">
              <UsersIcon className="w-5 fill-primary-default" />
            </span>
            <span>{pagination.totalElements} Users</span>
          </Button>
          <Button
            onClick={() => setshowRoleMgtUI(true)}
            variant="outlinePrimary"
            className="flex items-center gap-3"
          >
            <span>
              <FontAwesomeIcon icon={faPlus} />
            </span>
            Manage User Roles
          </Button>
          {user.userList?.length > 0 && (
            <Button
              onClick={() => setShowNewUserModal(true)}
              className="flex items-center gap-3"
            >
              <span>
                <FontAwesomeIcon icon={faPlus} />
              </span>
              Create New User
            </Button>
          )}
        </div>
      </div>

      {user.userList?.length === 0 && !user.isLoading ? (
        <div className="my-auto flex flex-col items-center justify-center self-center pb-14">
          <span className=" text-secondary-default">
            <UsersIcon className="w-8" />
          </span>
          <h6 className="mt-5 text-xl font-semibold">No Added Users</h6>
          <div className="text-sm font-normal text-gray-10/50">
            Add other users to your account
          </div>

          <Button
            onClick={() => setShowNewUserModal(true)}
            className="mt-6 flex h-9 items-center gap-2 text-sm font-medium"
          >
            <FontAwesomeIcon icon={faPlus} /> Create A User
          </Button>
        </div>
      ) : (
        <UserTable />
      )}
      {showNewUserModal ? (
        <NewUserDialog
          isOpen={showNewUserModal}
          handleClose={() => setShowNewUserModal(false)}
        />
      ) : null}
    </PermissionGate>
  )
}

export default ManageUsers
