import React from "react"
import { createColumnHelper } from "@tanstack/react-table"
import Status from "components/Status/Status"

const columnHelper = createColumnHelper<PayoutTransaction>()

export const settlementTxColumns = [
  columnHelper.accessor("tgipayReference", {
    cell: (info) => info.getValue(),
    header: () => <span>Reference</span>,
  }),
  columnHelper.accessor(
    (row) => row.customerLastName + " " + row.customerFirstName,
    {
      id: "customerName",
      cell: (info) => <span>{info.getValue()}</span>,
      header: () => <span>Customer Name</span>,
    }
  ),
  columnHelper.accessor("amount", {
    header: () => "Transaction Amount (NGN)",
    cell: (info) => <span>{info.renderValue()}</span>,
  }),
  columnHelper.accessor("income", {
    header: () => "Settlement Amount (NGN)",
    cell: (info) => <span>{info.renderValue()}</span>,
  }),
  columnHelper.accessor("fee", {
    header: () => <span>Transaction Fee (NGN)</span>,
  }),
  columnHelper.accessor("status", {
    header: "Settlement Status",
    cell: (info) => (
      <Status variant={info.getValue()}>{info.getValue()}</Status>
    ),
  }),
  columnHelper.accessor("transactionDate", {
    header: () => <span>Date</span>,
    cell: (info) => <div className="w-max">{info.renderValue()}</div>,
  }),
]
