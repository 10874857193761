import React from "react"
import { useAppSelector } from "redux/hooks"

export const BreadCrumb: React.FC = () => {
  const breadcrumbData = useAppSelector((state) => state.ui.breadcrumbData)

  return (
    <div className="hidden gap-2 md:flex">
      {breadcrumbData?.length > 0 &&
        breadcrumbData?.map((breadcrumb, index) => (
          <span
            key={`breadcrumb-${index}`}
            className={`${
              index === breadcrumbData?.length - 1
                ? "text-gray-10"
                : "text-gray-10/50"
            }`}
          >
            {breadcrumb}
          </span>
        ))}
    </div>
  )
}
