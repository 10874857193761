import React from "react"
import * as yup from "yup"
import { useAppDispatch, useAppSelector } from "redux/hooks"
import { ReactComponent as Hamburger } from "../../../assets/icons/hamburger.svg"
import { BreadCrumb } from "./BreadCrumb"
import { toggleMobileSidebar } from "redux/ui/uiSlice"

export const searchSchema = yup.object().shape({
  searchText: yup.string(),
})

const Header: React.FC = () => {
  const dispatch = useAppDispatch()
  const isSidebarOpen = useAppSelector((state) => state.ui.isSidebarOpen)

  const toggleSidebar = () => {
    dispatch(toggleMobileSidebar())
  }
  return (
    <header className="fixed top-0 z-20 flex h-16 w-full items-center bg-white opacity-100">
      <div className="flex w-full items-center px-8 md:ml-48">
        {!isSidebarOpen && (
          <Hamburger onClick={toggleSidebar} className="w-7 md:hidden" />
        )}
        <BreadCrumb />
        {/* <div className="ml-auto flex items-center gap-5">
          <div
            className="relative cursor-pointer text-2xl"
            onClick={() => navigate("/notifications")}
          >
            <FontAwesomeIcon icon={faBell} />
            {notificationCount > 0 ? (
              <>
                <span className="absolute -right-2 -top-2 flex h-5 w-5 items-center justify-center rounded-full bg-primary-default text-sm font-bold text-white">
                  {notificationCount}
                </span>
                <span
                  className={`absolute -right-2 -top-2 inline-flex h-5 w-5 ${
                    notificationCount > 0 ? "animate-ping" : ""
                  } rounded-full bg-primary-default opacity-40`}
                ></span>
              </>
            ) : null}
          </div>
          <img
            className="h-10 w-10 rounded-[50%]"
            src="https://via.placeholder.com/150 "
            alt="placeholder"
          />
        </div> */}
      </div>
    </header>
  )
}

export default Header
