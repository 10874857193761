import React from "react"
import { DataRow } from "../../../../../components/DataRow/DataRow"
import NoImage from "assets/images/no-image.png"
import { ImageWithFallback } from "components/ImageWithFallback/ImageWithFallback"

type MerchantBusinessInfo = {
  businessName?: string
  businessCategory?: string
  businessType?: string
  businessAddress?: string
  state?: string
  country?: string
  website?: string
  supportMail?: string
  description?: string
  businessLogoUrl?: string
  tin?: string
}

type Props = {
  merchantBusinessInfo: MerchantBusinessInfo
  isLoading?: boolean
}

const BusinessProfile: React.FC<Props> = ({
  merchantBusinessInfo: data,
  isLoading,
}) => {
  const businessData = [
    { label: "Business Name", value: data.businessName },
    { label: "Business Category", value: data.businessCategory },
    { label: "Business Type", value: data.businessType },
    { label: "Business Address", value: data.businessAddress },
    { label: "State", value: data.state },
    { label: "Country", value: data.country },
    { label: "Website", value: data.website },
    { label: "Support Mail", value: data.supportMail },
    { label: "Description", value: data.description },
    { label: "Tax Identification Number", value: data.tin },
  ]

  return (
    <div className="mt-11 flex w-full">
      <div className="flex-2 flex min-h-352 w-3/5 flex-col gap-8 pr-6">
        {businessData.map((row) => (
          <DataRow
            key={row.label}
            isLoading={isLoading}
            label={row.label}
            value={row.value}
          />
        ))}
      </div>
      <div className="flex w-2/5 flex-1 items-center border-l border-l-gray-10/20 pl-14">
        <div className="space  flex flex-col items-center justify-center gap-[10px] rounded-lg">
          <span className="text-sm font-medium">Business Logo</span>
          <div className="custom-border flex h-52 w-52 items-center justify-center p-2">
            <ImageWithFallback
              src={data.businessLogoUrl ?? ""}
              fallbackSrc={NoImage}
              alt="logo"
            />
          </div>
          <span className="text-xs font-normal">Format: png, 5MB</span>
        </div>
      </div>
    </div>
  )
}

export default BusinessProfile
