import { queryKeys } from "constants/queryKeys"
import useBreadcrumb from "hooks/useBreadcrumb"
import React, { useState } from "react"
import { useQuery } from "react-query"
import { encodeSearchParams } from "utils/encodeSearchParams"

import { useForm } from "react-hook-form"
import { fetchAudit, fetchAuditPDF } from "services/api/audit"
import { Audit } from "types/audit"

const useAudit = (queryKey: string) => {
  const [showExportModal, setShowExportModal] = React.useState(false)
  const [searchParams, setSearchParams] = useState<{
    status?: { label: string; value: string | undefined }
    startDate?: string
    endDate?: string
    search?: string | undefined
    role?: { label: string; value: string | undefined }
    merchantId?: string
  }>({
    status: { label: "", value: undefined },
    role: { label: "", value: undefined },
    startDate: "",
    endDate: "",
  })

  useBreadcrumb([])

  const [pageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)

  const { data: auditResponse, isLoading } = useQuery(
    [queryKeys.audit.fetchAudit, currentPage, pageSize, searchParams],
    () =>
      fetchAudit({
        pageSize,
        currentPage,
        search: encodeSearchParams({
          status: searchParams.status?.value,
          startDate: searchParams.startDate,
          endDate: searchParams.endDate,
          activity: searchParams?.search?.trim(),
          role: searchParams.role?.label,
          merchantId: searchParams.merchantId,
        }),
      }),
    {
      enabled: queryKey === queryKeys.audit.fetchAudit,
    }
  )

  const { data: auditPDFResponse, isLoading: isLoadingPDF } = useQuery(
    [queryKeys.audit.fetchAuditPDF, currentPage, pageSize, searchParams],
    () =>
      fetchAuditPDF({
        pageSize: 100,
        currentPage,
        search: encodeSearchParams({
          status: searchParams.status?.value,
          startDate: searchParams.startDate,
          endDate: searchParams.endDate,
          activity: searchParams?.search?.trim(),
          role: searchParams.role?.value,
          merchantId: searchParams.merchantId,
        }),
      }),
    {
      enabled: queryKey === queryKeys.audit.fetchAuditPDF,
    }
  )

  const auditList: Audit[] = auditResponse?.data?.data?.content
  const auditPDFList: Audit[] = auditPDFResponse?.data?.data?.content

  const totalElements = auditResponse?.data?.data?.totalElements
  const totalPages = auditResponse?.data?.data?.totalPages

  const filterMethods = useForm()
  const searchMethods = useForm()

  const { handleSubmit: handleFilter } = filterMethods
  const { handleSubmit: handleSearch } = searchMethods

  const updateSearchParams = (
    payload: React.SetStateAction<{
      startDate: string
      endDate: string
      search?: string | undefined
      merchantId?: string
      role?: { label: string; value: string | undefined }
    }>
  ) => {
    setSearchParams({
      ...searchParams,
      ...payload,
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFilter = (data: any) => {
    updateSearchParams({
      ...data,
    })
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSearch = (data: any) => {
    setSearchParams({
      ...searchParams,
      ...data,
    })
  }

  const handlePageChange = (page: { selected: number }) => {
    setCurrentPage(page.selected)
  }

  const handleresetFilter = () => {
    setSearchParams({
      status: { label: "", value: undefined },
      startDate: "",
      endDate: "",
    })
    setCurrentPage(0)
  }

  const pagination = {
    pageSize,
    currentPage,
    totalElements,
    totalPages,
    handlePageChange,
  }

  const search = {
    searchMethods,
    handleSearch,
    onSearch,
    updateSearchParams,
    searchParams,
  }

  const filter = {
    filterMethods,
    handleFilter,
    onFilter,
    resetFilter: handleresetFilter,
  }

  const audit = { auditList, isLoading, isLoadingPDF, auditPDFList }

  const exportManager = { showExportModal, setShowExportModal }

  return {
    audit,
    search,
    filter,
    pagination,
    exportManager,
  }
}

export default useAudit
