import IdleTimer from "components/IdleTimer"
import React, { useEffect } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { useAppSelector } from "redux/hooks"
import ContentArea from "./ContentArea/ContentArea"
import Header from "./Header/Header"
import MobileSidebar from "./Sidebar/MobileSidebar"
import Sidebar from "./Sidebar/Sidebar"
import { generateNewAccessToken } from "services/api/auth"
import { getAuthUser } from "utils/auth"

const ProtectedLayout: React.FC = () => {
  const isSidebarOpen = useAppSelector((state) => state.ui.isSidebarOpen)
  const location = useLocation()

  const navigate = useNavigate()

  useEffect(() => {
    const checkTokenExpiration = async () => {
      const user = getAuthUser()

      if (user?.userDetails) {
        const { exp: expiryTimestamp } = user.userDetails
        const currentTimestamp = Math.floor(Date.now() / 1000)

        const timeUntilExpiry = expiryTimestamp - currentTimestamp
        const timeBeforeExpiry = 5 * 60

        if (timeUntilExpiry <= timeBeforeExpiry) {
          try {
            await generateNewAccessToken()
          } catch (error) {
            sessionStorage.clear()
            window.location.replace("/")
          }
        }
      }
    }

    const tokenRefreshInterval = setInterval(checkTokenExpiration, 1 * 3 * 1000)

    return () => clearInterval(tokenRefreshInterval)
  }, [])

  const handleIdle = () => {
    sessionStorage.removeItem("authUser")
    navigate(`/expired-session?redirectUrl=${location.pathname}`)
  }

  return (
    <div className="relative h-screen  w-screen overflow-x-hidden bg-[#F9FAFB]">
      {isSidebarOpen && <MobileSidebar />}
      <Sidebar />
      <Header />
      <ContentArea>
        <Outlet />
      </ContentArea>
      <IdleTimer timeout={1800000} onIdle={handleIdle} />
    </div>
  )
}

export default ProtectedLayout
