import Button from "components/button"
import BackArrow from "components/button/BackArrow"
import Input from "components/input"
import TextArea from "components/input/TextArea"
import TopBar from "layout/protected/ContentArea/TopBar"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useNavigate, useSearchParams } from "react-router-dom"

const CreateChannel: React.FC = () => {
  const [searchParams] = useSearchParams()
  const tabIndex = searchParams.get("tabIndex")
  const navigate = useNavigate()

  const methods = useForm()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onCreate = () => {
    // console.log(data)
  }

  return (
    <div>
      <TopBar
        pageTitle="Create Channel"
        subTitle="Manage TGI Pay payment channels."
      />
      <div className="mt-8 w-full max-w-[65.25rem] rounded-lg bg-white p-7 lg:w-[60%] 2xl:max-w-full">
        <FormProvider {...methods}>
          <form
            className="flex w-[30rem] flex-col gap-4"
            onSubmit={methods.handleSubmit(onCreate)}
          >
            <Input label="Channel Name" name="channelName" />
            <Input label="Code/Key" name="code" />
            <TextArea
              label="Description(Optional)"
              name="description"
              rows={5}
            />
          </form>
        </FormProvider>
      </div>
      <div className="flex max-w-[65.25rem] justify-end gap-6 lg:w-[60%] ">
        <Button
          className="ml-4 mt-6"
          variant="outlinePrimary"
          onClick={() => navigate(`/configuration?tabIndex=${tabIndex}`)}
        >
          Cancel
        </Button>
        <Button className="mt-6" onClick={() => methods.handleSubmit(onCreate)}>
          Create Channel
        </Button>
      </div>
      <BackArrow
        onClick={() => navigate(`/configuration?tabIndex=${tabIndex}`)}
        label="Return to Configuration Page"
        className="mt-6"
      />
    </div>
  )
}

export default CreateChannel
