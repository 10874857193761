import ProtectedRoute from "components/PrivateRoute/PrivateRoute"
import { ProtectedLayout } from "layout"
import NotFound from "pages/not-found/NotFound"
import SetPassword from "pages/protected/Teams/AdminUserRoles/ManageUsers/SetPassword"
import ExpiredSession from "pages/public/ExpiredSession/ExpiredSession"
import {
  ForgotPassword,
  ResetPassword,
  ResetSuccess,
} from "pages/public/ForgotPassword"
import InvalidResetLink from "pages/public/ForgotPassword/InvalidResetLink"
import ResetInfo from "pages/public/ForgotPassword/ResetInfo"
import Login from "pages/public/login"
import TwoFactor from "pages/public/TwoFactor/TwoFactor"
import React from "react"
import { protectedRoutes } from "./protectedRoutes"

export const publicRoutes = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/two-factor",
    element: <TwoFactor />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-initiated",
    element: <ResetInfo />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/set-password",
    element: <SetPassword />,
  },
  {
    path: "/reset-success",
    element: <ResetSuccess />,
  },
  {
    path: "/expired-session",
    element: <ExpiredSession />,
  },
  {
    path: "/invalid-reset-link",
    element: <InvalidResetLink />,
  },
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <ProtectedLayout />
      </ProtectedRoute>
    ),
    children: protectedRoutes,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]
