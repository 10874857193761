import Modal from "components/modal/Modal"
import React from "react"
import { KYCDocument } from "./Details"
import { FileRenderer } from "./FileRenderer/FileRenderer"

type DocumentDetailsProps = {
  isOpen: boolean
  closeDocumentDetails: () => void
  url?: string
  selectedDoc: KYCDocument
  selectedDocType: string | null
}
const DocumentDetails: React.FC<DocumentDetailsProps> = ({
  isOpen,
  closeDocumentDetails,
  selectedDocType,
  selectedDoc,
}) => {
  return (
    <Modal
      contentContainerClass="max-w-4xl min-h-300"
      isOpen={isOpen}
      closeModal={closeDocumentDetails}
    >
      <div className="border-b border-b-gray-default px-8 pb-3 pt-8">
        {selectedDocType}
      </div>
      <div className="relative my-6 flex min-h-[60vh] flex-col items-center px-8 pb-4">
        <FileRenderer
          filePath={selectedDoc?.location}
          contentType={selectedDoc.contentType}
        />
      </div>
    </Modal>
  )
}

export default DocumentDetails
