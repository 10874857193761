import Button from "components/button"
import React from "react"
import DisputeMessage from "./DisputeMessage"
import { Dispute } from "types/Dispute"
type Props = {
  showTransactionDetails: () => void
  disputeDetails: Dispute
}
const DisputeHistory: React.FC<Props> = ({
  showTransactionDetails,
  disputeDetails,
}) => {
  return (
    <div className="w-full lg:w-[40rem]">
      <div className="flex items-center justify-between p-2">
        <h5 className="text-base font-medium">Dispute Notes</h5>{" "}
        <Button
          onClick={() => showTransactionDetails()}
          variant="outlinePrimary"
        >
          View Transaction
        </Button>
      </div>
      <DisputeMessage
        message={disputeDetails.notes}
        sender=""
        dateTime={disputeDetails.createdDate}
      />
      {/* 
      <div className="relative w-full bg-white p-4">
        <textarea
          className="w-full border-0 outline-none focus:ring-0"
          name=""
          id=""
          cols={30}
          rows={5}
          placeholder="Type here..."
        ></textarea>
        <Button className="absolute bottom-4 right-4">Send Response</Button>
      </div> */}
    </div>
  )
}

export default DisputeHistory
