import React, { useState } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import { WorkflowT } from "types/workflow"
import WorkflowEdit from "../WorkflowEdit"
import ActionDropdown from "./ActionDropdown"
import WorkflowConfiguration from "../Configuration/WorkflowConfiguration"

const columnHelper = createColumnHelper<WorkflowT>()

export const workflowListColumns = [
  columnHelper.accessor("id", {
    cell: (info) => info.getValue(),
    header: () => <span>Workflow ID</span>,
  }),
  columnHelper.accessor("name", {
    header: () => "Name",
    cell: (info) => <span>{info.getValue()}</span>,
  }),
  columnHelper.accessor("numberOfLevel", {
    header: () => "Steps",
    cell: (info) => <span>{info.renderValue()}</span>,
  }),
  columnHelper.accessor("action", {
    header: "Action",
    cell: (info) => {
      const {
        row: { original: originalData },
      } = info.cell

      const [showActions, setShowActions] = useState(false)
      const [showEditDialog, setShowEditDialog] = useState(false)
      const [showSetupDialog, setShowSetupDialog] = useState(false)

      return (
        <React.Fragment>
          <ActionDropdown
            isOpen={showActions}
            toggleOpen={() => {
              setShowActions(!showActions)
            }}
            workflow={originalData}
            setShowEditDialog={setShowEditDialog}
            setShowSetupDialog={setShowSetupDialog}
          />
          {showEditDialog ? (
            <WorkflowEdit
              handleClose={() => setShowEditDialog(false)}
              isOpen={showEditDialog}
              workflow={originalData}
            />
          ) : null}

          {showSetupDialog ? (
            <WorkflowConfiguration
              handleClose={() => setShowSetupDialog(false)}
              isOpen={showSetupDialog}
              workflow={originalData}
            />
          ) : null}
        </React.Fragment>
      )
    },
  }),
]
