import React, { useState } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import Status from "components/Status/Status"
import ActionDropdown from "./ActionDropdown"
import EditUserDialog from "../EditUserDialog"
import MerchantStatusUpdater from "./UserStatusUpdater"
import { STATUS } from "types/statuses"

const columnHelper = createColumnHelper<User>()

export const userColumns = [
  columnHelper.accessor("firstName", {
    cell: (info) => info.getValue(),
    header: () => <span>First Name</span>,
  }),
  columnHelper.accessor((row) => row.lastName, {
    id: "lastName",
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <span>Last Name</span>,
  }),
  columnHelper.accessor("email", {
    header: () => "Email",
    cell: (info) => <span>{info.renderValue()}</span>,
  }),
  columnHelper.accessor("role", {
    header: () => <span>Role</span>,
  }),
  columnHelper.accessor("createdDate", {
    header: "Date Created",
  }),
  columnHelper.accessor("activated", {
    header: () => <span>Status</span>,
    cell: (info) => (
      <Status variant={info.getValue() ? STATUS.SUCCESS : STATUS.ERROR}>
        {info.getValue() ? "Active" : "Inactive"}
      </Status>
    ),
  }),
  columnHelper.accessor("action", {
    header: "Action",
    cell: (info) => {
      const {
        row: { original: originalData },
      } = info.cell

      const [showEditDialog, setShowEditDialog] = useState(false)
      const [showConfirm, setShowConfirm] = useState(false)
      const [showActions, setShowActions] = useState(false)

      return (
        <React.Fragment>
          <ActionDropdown
            setShowConfirm={setShowConfirm}
            setShowEditDialog={setShowEditDialog}
            isOpen={showActions}
            toggleOpen={() => {
              setShowEditDialog(false)
              setShowConfirm(false)
              setShowActions(!showActions)
            }}
            user={originalData}
          />
          {showEditDialog ? (
            <EditUserDialog
              isOpen={showEditDialog}
              handleClose={() => setShowEditDialog(false)}
              selectedUser={originalData}
            />
          ) : null}

          {showConfirm ? (
            <MerchantStatusUpdater
              isOpen={showConfirm}
              closeStatusDialog={() => setShowConfirm(false)}
              user={originalData}
            />
          ) : null}
        </React.Fragment>
      )
    },
  }),
]
