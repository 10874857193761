import React from "react"
import TopBar from "layout/protected/ContentArea/TopBar"
import PermissionGate from "components/PermissionGate/PermissionGate"
import { PERMISSIONS } from "constants/permissions"
import ListAccessDenied from "components/PermissionGate/ListAccessDenied"
import Table from "components/Table"
import useDispute from "./hooks/useDispute"
import DisputeFilter from "./Filter/DisputeFilter"
import PaginationInfo from "components/Pagination/PaginationInfo"
import { Pagination } from "components/Pagination/PaginationComponent"
import { queryKeys } from "constants/queryKeys"
import ExportModal from "components/ExportModal/ExportModal"
import { disputeColumns } from "./Table/columns"
import { baseUrl } from "config/baseUrl"
import { disputeEndpoints } from "constants/endpoints"

const Disputes: React.FC = () => {
  const { dispute, search, filter, exportManager, pagination } = useDispute(
    queryKeys.dispute.fetchDispute
  )
  return (
    <PermissionGate
      allowedPermissions={[PERMISSIONS.VIEW_DISPUTES]}
      fallback={<ListAccessDenied />}
    >
      <div>
        <TopBar
          pageTitle="Disputes"
          subTitle="Manage raised transaction disputes."
        />

        <div className="flex items-center justify-end gap-6 pt-2">
          <DisputeFilter filter={filter} search={search} />
        </div>
        <div className="mt-4 border border-[#D3D3D3]">
          <Table
            columns={disputeColumns}
            data={dispute.disputeList}
            isLoading={dispute.isLoading}
            loadingSkeletonCount={10}
          />
          {dispute.disputeList?.length > 0 ? (
            <div className="flex h-[4.3125rem] items-center justify-between bg-white px-5">
              <PaginationInfo pagination={pagination} />

              <div>
                <Pagination pagination={pagination} />
              </div>
            </div>
          ) : null}
        </div>

        {/* {dispute.disputeList?.length > 0 ? (
          <Button
            className="mt-9 w-[6rem]"
            onClick={() => exportManager.setShowExportModal(true)}
          >
            Export
          </Button>
        ) : null} */}

        {exportManager.showExportModal ? (
          <ExportModal
            isOpen={exportManager.showExportModal}
            closeModal={() => exportManager.setShowExportModal(false)}
            exportDataName="Disputes"
            downloadUrl={`${baseUrl.adminUserService}${disputeEndpoints.fetchDispute}`}
            downloadParams={{}}
          />
        ) : null}
      </div>
    </PermissionGate>
  )
}

export default Disputes
