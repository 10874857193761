import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "components/button"
import React, { FC } from "react"
import useConfiguration from "../../hooks/useConfiguration"
import { queryKeys } from "constants/queryKeys"
import EmailTable from "./Table/Table"
import { useNavigate } from "react-router-dom"
import { configPages } from "../../constants/config-pages"
type Props = {
  tabIndex: number
}
const EmailSettings: FC<Props> = ({ tabIndex }) => {
  const navigate = useNavigate()
  const { data, loading } = useConfiguration(
    queryKeys.configuration.fetchEmailSettings
  )

  return (
    <div>
      <div className="flex justify-end gap-6">
        <Button
          onClick={() =>
            navigate(
              `/configuration?tabIndex=${tabIndex}&page=${configPages.ADD_EMAIL}`
            )
          }
        >
          <span>
            <FontAwesomeIcon icon={faPlus} />
          </span>
          <span className="ml-2">Add New Email</span>
        </Button>
      </div>

      <div>
        <EmailTable
          emailData={data.emailData}
          loading={loading.loadingEmailSettings}
        />
      </div>
    </div>
  )
}

export default EmailSettings
