import { queryKeys } from "constants/queryKeys"
import useBreadcrumb from "hooks/useBreadcrumb"
import React, { useState } from "react"
import { useQuery } from "react-query"
import { encodeSearchParams } from "utils/encodeSearchParams"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { handleError } from "utils/getAxiosErrorMessage"
import { useForm } from "react-hook-form"
import { Dispute, DisputeDetails } from "types/Dispute"
import { fetchDispute, fetchDisputeDetails } from "services/api/dispute"

const useDispute = (queryKey: string, disputeId?: string | null) => {
  const [showExportModal, setShowExportModal] = React.useState(false)
  const [searchParams, setSearchParams] = useState<{
    resolution?: string
    startDate?: string
    endDate?: string
    search?: string | undefined
    paymentChannelId?: number
  }>({
    resolution: "",
    startDate: "",
    endDate: "",
  })

  useBreadcrumb([])

  const [pageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)

  const { data: disputeResponse, isLoading } = useQuery(
    [queryKeys.dispute.fetchDispute, currentPage, pageSize, searchParams],
    () =>
      fetchDispute({
        pageSize,
        currentPage,
        search: encodeSearchParams({
          resolution: searchParams.resolution,
          startDate: searchParams.startDate,
          endDate: searchParams.endDate,
          disputeReference: searchParams?.search?.trim(),
        }),
      }),
    {
      enabled: queryKey === queryKeys.dispute.fetchDispute,
    }
  )

  const { data: detailsResponse, isLoading: loadingDetails } = useQuery(
    [queryKeys.dispute.fetchDisputeDetails, currentPage, disputeId],
    () => fetchDisputeDetails({ disputeId }),
    {
      enabled:
        !!disputeId && queryKey === queryKeys.dispute.fetchDisputeDetails,
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    }
  )

  const disputeList: Dispute[] = disputeResponse?.data?.data?.content

  const totalElements = disputeResponse?.data?.data?.totalElements
  const totalPages = disputeResponse?.data?.data?.totalPages

  const filterMethods = useForm()
  const searchMethods = useForm()

  const { handleSubmit: handleFilter } = filterMethods
  const { handleSubmit: handleSearch } = searchMethods

  const updateSearchParams = (
    payload: React.SetStateAction<{
      startDate: string
      endDate: string
      search?: string | undefined
    }>
  ) => {
    setSearchParams({
      ...searchParams,
      ...payload,
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFilter = (data: any) => {
    updateSearchParams({
      ...data,
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSearch = (data: any) => {
    setSearchParams({
      ...searchParams,
      ...data,
    })
  }

  const handlePageChange = (page: { selected: number }) => {
    setCurrentPage(page.selected)
  }

  const handleresetFilter = () => {
    setSearchParams({
      resolution: "",
      startDate: "",
      endDate: "",
    })
  }

  const pagination = {
    pageSize,
    currentPage,
    totalElements,
    totalPages,
    handlePageChange,
  }

  const search = {
    searchMethods,
    handleSearch,
    onSearch,
    updateSearchParams,
    searchParams,
  }

  const filter = {
    filterMethods,
    handleFilter,
    onFilter,
    resetFilter: handleresetFilter,
  }

  const dispute = { disputeList, isLoading }
  const disputeDetails: {
    data: DisputeDetails
    loadingDetails: boolean
  } = {
    data: detailsResponse?.data.data,
    loadingDetails,
  }

  const exportManager = { showExportModal, setShowExportModal }

  return { filter, search, dispute, disputeDetails, exportManager, pagination }
}

export default useDispute
