import { AuthAxiosInstance } from "services/axios/authApi"
import { userEndpoints } from "../../constants/endpoints"

export const fetchUserData = async (payload: {
  currentPage: number
  pageSize: number
}) => {
  const response = await AuthAxiosInstance.get(
    `${userEndpoints.fetchUserData}?page=${payload.currentPage}&size=${payload.pageSize}`
  )
  return response
}

export const updateUserStatus = async (payload: {
  activate: boolean
  userId: number
}) => {
  const { activate, userId } = payload
  const response = await AuthAxiosInstance.put(
    `${userEndpoints.updateUserStatus}?userId=${userId}&activate=${activate}`
  )
  return response
}

export const fetchAdminPermissions = async () => {
  const response = await AuthAxiosInstance.get(
    userEndpoints.fetchAdminPermissions
  )
  return response
}

export const fetchMerchantPermissions = async () => {
  const response = await AuthAxiosInstance.get(
    userEndpoints.fetchMerchantPermissions
  )
  return response
}

export const createAdminRole = async (payload: {
  businessId: number | null | undefined
  name: string
  description: string
  permissions: Permission[]
}) => {
  const response = await AuthAxiosInstance.post(
    userEndpoints.createAdminRole,
    payload
  )
  return response
}

export const createMerchantRole = async (payload: {
  id: number
  businessId: number | null | undefined
  name: string
  description: string
  permissions: Permission[]
}) => {
  const response = await AuthAxiosInstance.post(
    userEndpoints.createMerchantRole,
    payload
  )
  return response
}

export const editAdminRole = async (payload: {
  id: number
  businessId: number | null | undefined
  name: string
  description: string
  permissions: Permission[]
}) => {
  const response = await AuthAxiosInstance.put(
    userEndpoints.editAdminRole,
    payload
  )
  return response
}

export const editMerchantRole = async (payload: {
  id: number
  businessId: number | null | undefined
  name: string
  description: string
  permissions: Permission[]
}) => {
  const response = await AuthAxiosInstance.put(
    userEndpoints.editMerchantRole,
    payload
  )
  return response
}

export const fetchAdminRoles = async () => {
  const response = await AuthAxiosInstance.get(userEndpoints.fetchAdminRoles)
  return response
}

export const fetchMerchantRoles = async () => {
  const response = await AuthAxiosInstance.get(userEndpoints.fetchMerchantRoles)
  return response
}

export const createUser = async (payload: {
  firstName: string
  lastName: string
  email: string
  roleId: number
  role: string
  defaultRole: boolean
}) => {
  const response = await AuthAxiosInstance.post(userEndpoints.createUser, {
    ...payload,
  })
  return response
}

export const editUser = async (payload: {
  firstName: string
  lastName: string
  email: string
  roleId: number
  role: string
  defaultRole: boolean
}) => {
  const response = await AuthAxiosInstance.put(userEndpoints.editUser, {
    ...payload,
  })
  return response
}
