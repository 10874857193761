import React from "react"
import Modal from "components/modal/Modal"
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg"
import { UserAxiosInstance } from "services/axios/userApi"
import usePDFDownload from "hooks/usePDFDownload"
import Spinner from "components/spinner/Spinner"

type ExportModalProps = {
  isOpen: boolean
  closeModal: () => void
  exportDataName: string
  downloadUrl: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  downloadParams?: Record<string, any>
  isLoading?: boolean
}

enum DownloadType {
  Excel = "excel",
  PDF = "pdf",
  CSV = "csv",
}

const useExportModal = (exportProps: ExportModalProps) => {
  const {
    closeModal,
    downloadUrl,
    exportDataName,
    isOpen,
    downloadParams,
    isLoading,
  } = exportProps

  const { exportPDFWithMethod, pdfContainer } = usePDFDownload({
    fileName: exportDataName,
  })

  const ExportModal: React.FC = () => {
    const handleDownload = async (downloadType: DownloadType) => {
      const url = new URL(downloadUrl)
      const queryParams: Record<string, string> = {
        ...downloadParams,
        type: downloadType,
      }

      Object.keys(queryParams).forEach((key) => {
        const value = queryParams[key]
        if (value !== undefined && value !== null && value !== "") {
          url.searchParams.append(key, value)
        }
      })

      try {
        const response = await UserAxiosInstance.get(url.toString(), {
          responseType: "blob",
        })

        // Create a download link for the blob and click it
        const link = document.createElement("a")
        link.href = window.URL.createObjectURL(response.data)
        link.download = `${exportDataName}.${
          downloadType === DownloadType.Excel ? "xlsx" : downloadType
        }`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (error) {
        console.error(error)
      }

      closeModal()
    }

    return (
      <Modal isOpen={isOpen} closeModal={closeModal}>
        <div className="p-6">
          <h5 className="text-xl font-semibold">Export {exportDataName}</h5>
          <span className="text-base font-normal">Select preferred Format</span>

          <div className="mt-6 flex flex-col gap-6">
            <>
              {isLoading ? (
                <Spinner className="self-center text-primary-default" />
              ) : (
                <>
                  <div
                    className={`flex cursor-pointer`}
                    onClick={() => handleDownload(DownloadType.Excel)}
                  >
                    <span className="w-12">Excel</span>
                    <DownloadIcon />
                  </div>
                  <div
                    className={`flex cursor-pointer `}
                    onClick={() => exportPDFWithMethod()}
                  >
                    <span className="w-12">PDF</span>
                    <DownloadIcon />
                  </div>
                  <div
                    className={`flex cursor-pointer `}
                    onClick={() => handleDownload(DownloadType.CSV)}
                  >
                    <span className="w-12">CSV</span>
                    <DownloadIcon />
                  </div>
                </>
              )}
            </>
          </div>
        </div>
      </Modal>
    )
  }
  return {
    ExportModal,
    pdfContainer,
  }
}

export default useExportModal
