export const queryKeys = {
  merchant: {
    fetchAllMerchants: "fetchAllMerchants",
    fetchMerchantDetails: "fetchMerchantDetails",
    fetchMerchantUserCount: "fetchMerchantUserCount",
    fetchSettlementAccount: "fetchSettlementAccount",
    fetchMerchantAdmin: "fetchMerchantAdmin",
    pendingKYCRequests: "pendingKYCRequests",
    fetchMerchantSettlements: "fetchMerchantSettlements",
    fetchMerchantTxn: "fetchMerchantTxn",
    transactionDetails: "transactionDetails",
    fetchSuccessfulTxCount: "fetchSuccessfulTxCount",
    fetchTotalTxValue: "fetchTotalTxValue",
    fetchTotalPayout: "fetchTotalPayout",
    fetchBvnDetails: "fetchBvnDetails",
    fetchMerchantApprovals: "fetchMerchantApprovals",
  },
  kyc: {
    fetchPendingKYC: "fetchPendingKYC",
    fetchKYCDocuments: "fetchKYCDocuments",
    fetchDocumentTypes: "fetchDocumentTypes",
    fetchKYCByStatus: "fetchKYCByStatus",
    fetchKYCFromMakerChecker: "fetchKYCFromMakerChecker",
  },
  settlement: {
    fetchSettlements: "fetchSettlements",
    fetchSettlementDetails: "fetchSettlementDetails",
    fetchPayouts: "fetchPayouts",
    fetchSettlementApprovals: "fetchSettlementApprovals",
  },
  transactions: {
    fetchTransactions: "fetchTransactions",
    fetchTransactionsPDF: "fetchTransactionsPDF",
    fetchTransactionDetails: "fetchTransactionDetails",
  },
  users: {
    fetchUsers: "fetchUsers",
    fetchUserDetails: "fetchUserDetails",
    fetchAdminPermissions: "fetchAdminPermissions",
    fetchMerchantPermissions: "fetchMerchantPermissions",
    fetchAdminRoles: "fetchAdminRoles",
    fetchMerchantRoles: "fetchMerchantRoles",
  },
  payment: {
    fetchTotalValueByDate: "fetchTotalValueByDate",
    fetchAllTxByDateCount: "fetchAllTxByDateCount",
    fetchSuccessfulValuePoints: "fetchSuccessfulValuePoints",
    fetchSuccessfulByDateCount: "fetchSuccessfulByDateCount",
    fetchPendingByDateCount: "fetchPendingByDateCount",
    fetchFailedByDateCount: "fetchFailedByDateCount",
    fetchActiveMerchantsByDate: "fetchActiveMerchantsByDate",
    metricCards: "metricCards",
  },
  dashboard: {
    allTxValue: "allTxValue",
    allSuccessfulByValuePoints: "allSuccessfulByValuePoints",
    successfulByDateCount: "successfulByDateCount",
    pendingByDateCount: "pendingByDateCount",
    fetchPendingSettlementCount: "fetchPendingSettlementCount",
    failedByDateCount: "failedByDateCount",
    activeMerchantsByDateCount: "activeMerchantsByDateCount",
    totalValueByDate: "totalValueByDate",
    totalPayout: "totalPayout",
    totalIncome: "dashboardTotalIncome",
  },
  merchantTx: {
    totalTxValue: "totalTxValue",
    totalIncome: "totalIncome",
    totalTransactions: "totalTransactions",
    activeMerchants: "activeMerchants",
  },
  configuration: {
    //channels
    fetchChannels: "fetchChannels",
    editChannel: "EditChannelDialog",
    //fees
    fetchFeeSettings: "fetchFeeSettings",
    //settlement
    fetchSettlement: "fetchSettlement",
    //email
    fetchEmailSettings: "fetchEmailSettings",
  },
  audit: {
    fetchAudit: "fetchAudit",
    fetchAuditPDF: "fetchAuditPDF",
    downloadAudit: "downloadAudit",
  },
  dispute: {
    fetchDispute: "fetchDispute",
    fetchDisputeDetails: "fetchDisputeDetails",
  },
  workflow: {
    fetchWorkflow: "fetchWorkflow",
    createWorkflow: "createWorkflow",
    fetchWorkflowConfig: "fetchWorkflowConfig",
  },
  otp: {
    validateUserOTP: "validateUserOTP",
    generateUserOTP: "generateUserOTP",
  },
}
