import { ReactComponent as DownloadIcon } from "assets/icons/download.svg"
import Button from "components/button"
import BackArrow from "components/button/BackArrow"
import { DataRow } from "components/DataRow/DataRow"
import Status from "components/Status/Status"
import useBreadcrumb from "hooks/useBreadcrumb"
import TopBar from "layout/protected/ContentArea/TopBar"
import React, { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import PayoutTransactions from "./PayoutTransactions"
import { parseJSON } from "utils/parseJSON"
import SettlementStatusUpdater from "../Table/SettlementMarkoff"
import { savePDF } from "@progress/kendo-react-pdf"
import SettlementDetailsPDF from "./DetailsPDF"
import { STATUS } from "types/statuses"
import { numberUtils } from "utils/numberUtils"

const SettlementDetails: React.FC = () => {
  const [searchParams] = useSearchParams()
  const settlementInfo = searchParams.get("settlementInfo")
  const settlementDetails: SettlementDetailsType = parseJSON(settlementInfo)

  const [showPayoutTx, setShowPayoutTx] = React.useState(false)
  const [showConfirmMarkoff, setShowConfirmMarkoff] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (!settlementInfo) navigate("/settlement")
  }, [settlementInfo])

  useBreadcrumb([])

  const personalData = {
    "Transaction Reference": settlementDetails?.reference,
    "Bank Name": settlementDetails?.bankName,
    "Merchant ID": settlementDetails?.merchantId,
    "Business Name": settlementDetails?.merchantName,
    "Bank Account Number": settlementDetails?.accountNumber,
    "Bank Account Name": settlementDetails?.bankName,
    "Settlement Date": settlementDetails?.settlementDate,
    "Payout Amount (NGN)": numberUtils.formatNumber(
      settlementDetails?.totalPayoutAmount
    ),
    "Settlement Amount (NGN)": settlementDetails?.totalTransactionAmount,
    "Transaction Fee(NGN)":
      settlementDetails.totalTransactionAmount -
      settlementDetails?.totalPayoutAmount,
    "Transactions in Settlement Batch": settlementDetails?.numberOfTransactions,
    "Payout Status": (
      <Status variant={settlementDetails?.status}>
        {settlementDetails?.status}
      </Status>
    ),
  }

  const container = React.useRef(null)

  const exportPDFWithMethod = () => {
    const element = container.current || document.body
    savePDF(element, {
      paperSize: "auto",
      margin: 40,
      fileName: `Settlement Details`,
    })
  }

  return (
    <>
      <TopBar pageTitle="" />

      <div className="max-w-4xl bg-white">
        <div className="flex justify-between border-b border-b-gray-10/20 p-6">
          <div className="">
            <div className="text-base font-medium">Settlement Details</div>
            <div className="text-sm text-gray-10/60">
              View details of payout settelement{" "}
            </div>
          </div>
          <div>
            <Button
              onClick={exportPDFWithMethod}
              variant="linkPrimary"
              className="flex items-center gap-2"
            >
              <span>Download PDF </span>
              <DownloadIcon />
            </Button>
          </div>
        </div>
        <div className="flex min-h-352 max-w-4xl flex-col gap-8 p-6">
          {Object.entries(personalData).map(([label, value]) => (
            <DataRow key={label} label={label} value={value} />
          ))}

          <div className="flex justify-between">
            <Button
              onClick={() => setShowPayoutTx(true)}
              className="h-8 w-[15rem] text-sm"
              variant="outlinePrimary"
            >
              View Payout Transactions
            </Button>

            {settlementDetails.status !== STATUS.SETTLEMENT.SUCCESSFUL && (
              <Button
                onClick={() => setShowConfirmMarkoff(true)}
                className="h-8 text-xs"
                variant="primary"
              >
                Mark Off
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="mt-4 p-2">
        <BackArrow onClick={() => navigate("/settlement")} label="Back" />
      </div>
      {showPayoutTx ? (
        <PayoutTransactions
          showPayoutTx={showPayoutTx}
          closePayoutTx={() => setShowPayoutTx(false)}
          settlementId={Number(settlementDetails?.id)}
        />
      ) : null}

      {showConfirmMarkoff ? (
        <SettlementStatusUpdater
          isOpen={showConfirmMarkoff}
          closeStatusDialog={() => setShowConfirmMarkoff(false)}
          settlement={settlementDetails}
        />
      ) : null}
      <SettlementDetailsPDF forwardedRef={container} />
    </>
  )
}

export default SettlementDetails
