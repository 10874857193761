const extractSelectedAuthorizers = (authorizerSelections: any[]) =>
  authorizerSelections
    .flatMap((item) =>
      Array.isArray(item.authorizers) ? item.authorizers : [item.authorizers]
    )
    .filter(Boolean)

const filterUsers = (userList: any[] | undefined, selectedEmails: string[]) =>
  userList
    ? userList
        .filter((user) => !selectedEmails.includes(user.email))
        .map((user) => ({
          label: `${user.firstName} ${user.lastName}`,
          value: user.email,
        }))
    : []

const createAuthorizerRequestList = (numberOfLevels: number) =>
  Array.from({ length: numberOfLevels }, (_, i) => ({
    authorizers: [],
    authorizationLevel: i + 1,
  }))

const validateAuthorizers = (
  data: {
    authorizationLevel: number
    authorizers: string[]
  }[]
): boolean => {
  return data.every((item) => item.authorizers.length > 0)
}

const getUserNameByEmail = (email: string, users: User[] = []): string => {
  if (!email || !Array.isArray(users)) return email

  const user = users.find((user) => user?.email === email)

  return user && user.firstName && user.lastName
    ? `${user.firstName} ${user.lastName}`
    : email
}

export {
  extractSelectedAuthorizers,
  filterUsers,
  createAuthorizerRequestList,
  validateAuthorizers,
  getUserNameByEmail,
}
