import React, { FC } from "react"
import { FieldValues, FormProvider, useForm } from "react-hook-form"
import Input from "components/input"
import Modal from "components/modal/Modal"
import { yupResolver } from "@hookform/resolvers/yup"
import { createWorkflowSchema } from "../Teams/validation-schemas/workflow"

import { createWorkflow } from "services/api/workflow"
import CustomSelect from "components/Select/CustomSelect"
import Button from "components/button"
import { useWorkflow } from "./hooks/useWorkflow"
import { WorkflowT } from "types/workflow"

type Props = {
  isOpen: boolean
  handleClose: () => void
  workflow: WorkflowT
}
const WorkflowEdit: FC<Props> = ({
  isOpen,
  handleClose,
  workflow: existingWorflow,
}) => {
  const methods = useForm({
    resolver: yupResolver(createWorkflowSchema),
    defaultValues: {
      name: existingWorflow?.name,
      numberOfLevel: {
        value: existingWorflow?.numberOfLevel.toString(),
        label: existingWorflow?.numberOfLevel.toString(),
      },
    },
  })

  const errors = methods.formState.errors

  const { workflow } = useWorkflow()

  const onCreate = (data: FieldValues) => {
    const payload = {
      name: data.name,
      clientAppCode: data.clientAppCode,
      numberOfLevel: data.numberOfLevel.value,
    }
    createWorkflow(payload)
  }

  return (
    <Modal
      contentContainerClass="p-6 max-w-sm"
      isOpen={isOpen}
      closeModal={handleClose}
    >
      <div className="mb-6">
        <h5 className="text-lg font-medium">Edit Workflow</h5>
        <div className="text-xs font-normal">
          Provide details of the update.
        </div>
      </div>

      <FormProvider {...methods}>
        <form
          className="flex flex-col gap-4"
          onSubmit={methods.handleSubmit(onCreate)}
        >
          <Input label="Workflow Name" name="name" />
          <CustomSelect
            label="Number of Levels"
            options={[
              { label: "1", value: "1" },
              { label: "2", value: "2" },
              { label: "3", value: "3" },
              { label: "4", value: "4" },
              { label: "5", value: "5" },
            ]}
            name="numberOfLevel"
            error={errors.numberOfLevel?.value?.message}
          />
          <Button type="submit" loading={workflow.create.isCreating}>
            Create Workflow
          </Button>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default WorkflowEdit
