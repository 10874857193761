import { queryKeys } from "constants/queryKeys"
import useBreadcrumb from "hooks/useBreadcrumb"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useQuery } from "react-query"
import {
  fetchMerchantApprovals,
  fetchMerchantData,
} from "services/api/merchants"
import { Merchant } from "types/merchants"
import { encodeSearchParams } from "utils/encodeSearchParams"

type Search = {
  state?: { label: string; value: string | undefined }
  country?: { label: string; value: string | undefined }
  dateCreated?: string
  search?: string | undefined
}

const useMerchants = () => {
  const [showExportModal, setShowExportModal] = React.useState(false)
  const [searchParams, setSearchParams] = useState<Search>({
    state: { label: "", value: undefined },
    country: { label: "", value: undefined },
    dateCreated: "",
  })
  useBreadcrumb([])

  const [pageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)

  const { data: merchantResponse, isLoading } = useQuery(
    [queryKeys.merchant.fetchAllMerchants, currentPage, pageSize, searchParams],
    () =>
      fetchMerchantData({
        pageSize,
        currentPage,
        search: encodeSearchParams({
          state: searchParams.state?.label,
          country: searchParams.country?.label,
          dateCreated: searchParams.dateCreated,
          businessName: searchParams?.search?.trim(),
        }),
      })
  )

  const { data: merchantApprovalsResponse, isLoading: isLoadingApprovals } =
    useQuery([queryKeys.merchant.fetchMerchantApprovals], () =>
      fetchMerchantApprovals()
    )

  const updateSearchParams = (
    payload: React.SetStateAction<{
      startDate: string
      dateCreated: string
      search?: string | undefined
    }>
  ) => {
    setSearchParams({
      ...searchParams,
      ...payload,
    })
  }

  const merchantList: Merchant[] = merchantResponse?.data?.data?.content
  const totalElements = merchantResponse?.data?.data?.totalElements
  const totalPages = merchantResponse?.data?.data?.totalPages

  const merchantApprovalRequests = merchantApprovalsResponse?.data?.data

  const filterMethods = useForm()
  const searchMethods = useForm()

  const { handleSubmit: handleFilter } = filterMethods
  const { handleSubmit: handleSearch } = searchMethods

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFilter = (data: any) => {
    updateSearchParams({
      ...data,
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSearch = (data: any) => {
    setSearchParams({
      ...searchParams,
      ...data,
    })
  }

  const handlePageChange = (page: { selected: number }) => {
    setCurrentPage(page.selected)
  }

  const handleResetFilter = () => {
    filterMethods.reset()
    updateSearchParams({
      startDate: "",
      dateCreated: "",
      search: "",
    })
  }

  const pagination = {
    pageSize,
    currentPage,
    totalElements,
    totalPages,
    handlePageChange,
  }

  const search = { searchMethods, handleSearch, onSearch, searchParams }

  const filter = {
    filterMethods,
    handleFilter,
    onFilter,
    resetFilter: handleResetFilter,
  }

  const merchant = {
    merchantList,
    isLoading,
    merchantApprovalRequests,
    isLoadingApprovals,
  }

  const exportManager = { showExportModal, setShowExportModal }

  return { merchant, search, filter, pagination, exportManager }
}

export default useMerchants
