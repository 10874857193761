import { DateFormat } from "constants/date"

export function formatTimestampToDateTime(
  timestamp: number | undefined | string
) {
  if (!timestamp) {
    return ""
  }
  const date = new Date(timestamp)
  const formattedDate = date?.toLocaleString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  })
  return formattedDate
}

export function formatDate(date: Date) {
  const day = date.getDate()
  const suffix = getNumberSuffix(day)
  const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
    date
  )
  const year = date.getFullYear()
  const hour = date.getHours()
  const minute = date.getMinutes()
  return {
    dateTime: `${day}${suffix} ${month} ${year}, ${hour}:${minute}`,
    date: `${day}${suffix} ${month} ${year}`,
  }
}

function getNumberSuffix(day: number) {
  const suffixes = ["st", "nd", "rd"]
  const lastDigit = day % 10
  return suffixes[lastDigit - 1] || "th"
}

type DateFormatType = (typeof DateFormat)[keyof typeof DateFormat]

export function changeDateFormat(
  dateString: string,
  fromFormat: DateFormatType,
  toFormat: DateFormatType
): string | null {
  const formatParts: { [key: string]: string } = {
    DD: "([0-2][0-9]|3[0-1])",
    MM: "(0[1-9]|1[0-2])",
    YYYY: "(\\d{4})",
  }

  // Create the regex to match the fromFormat
  const fromFormatRegex = new RegExp(
    `^${fromFormat.replace(/DD|MM|YYYY/g, (match) => formatParts[match])}$`
  )

  // Use RegExp.exec() instead of String.match()
  const match = fromFormatRegex.exec(dateString)

  if (!match) {
    console.log("Invalid date format")
    return null
  }

  const dateParts = fromFormat.match(/DD|MM|YYYY/g) || []
  const dateMap: { [key: string]: string } = {}

  // Extract date values based on the parts (skip the full match at index 0)
  dateParts.forEach((part, index) => {
    dateMap[part] = match[index + 1]
  })

  // Replace the parts in toFormat with the extracted values
  return toFormat.replace(/DD|MM|YYYY/g, (match) => dateMap[match])
}
