import React, { useState } from "react"
import { ReactComponent as TransactionsIcon } from "assets/icons/merchants/successful-transactions.svg"
import { ReactComponent as TotalTransactionsIcon } from "assets/icons/merchants/total-transactions.svg"
import { ReactComponent as TotalPayout } from "assets/icons/merchants/total-payout.svg"
import { ReactComponent as SettlementIcon } from "assets/icons/merchants/settlement.svg"
import CustomTab from "components/Tab/Tab"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser, faSackDollar } from "@fortawesome/free-solid-svg-icons"
import { ReactComponent as MerchantProfileIcon } from "assets/icons/merchants/merchant-profile.svg"
import PersonalProfile from "./PersonalProfile/PersonalProfile"
import BusinessProfile from "./BusinessProfile/BusinessProfile"
import SettlementAccoount from "./SettlementAccount/SettlementAccount"
import Transactions from "./Transactions/Transactions"
import BackArrow from "components/button/BackArrow"
import { useNavigate, useSearchParams } from "react-router-dom"
import Button from "components/button"
import ActivateDialog from "./Actions/ActivateDialog"
import DeactivateDialog from "./Actions/DeactivateDialog"
import TopBar from "layout/protected/ContentArea/TopBar"
import useBreadcrumb from "hooks/useBreadcrumb"
import { useMerchantDetails } from "../hooks/useMerchantDetails"
import { formatTimestampToDateTime } from "utils/date"
import { queryKeys } from "constants/queryKeys"
import useMerchantTransactions from "../hooks/useMerchantTransactions"
import { numberUtils } from "utils/numberUtils"
import PermissionGate from "components/PermissionGate/PermissionGate"
import { PERMISSIONS } from "constants/permissions"

const MerchantDetails = () => {
  const navigate = useNavigate()

  const [showActivate, setShowActivate] = useState(false)
  const [showDeactivate, setShowDeactivate] = useState(false)

  const [searchParams] = useSearchParams()
  const merchantId = searchParams.get("merchantId")

  const { merchantData, isLoading } = useMerchantDetails(
    queryKeys.merchant.fetchMerchantDetails,
    Number(merchantId)
  )

  const { userCount } = useMerchantDetails(
    queryKeys.merchant.fetchMerchantUserCount,
    Number(merchantId)
  )

  const { successfulTxCount, totalTransactionValue } = useMerchantTransactions({
    queryKey: queryKeys.merchant.fetchSuccessfulTxCount,
    merchantId: Number(merchantId),
  })

  useBreadcrumb(["Merchants", `/${merchantData?.name ?? ""}`])

  const onActivate = () => {
    setShowActivate(true)
  }

  const onDeactivate = () => {
    setShowDeactivate(true)
  }

  const CardItem: React.FC<{
    label: string
    value: string
    icon: JSX.Element
  }> = ({ value, label, icon }) => {
    return (
      <div className="flex w-full min-w-max items-center gap-[7px] py-4 pl-8 pr-4 last:border-r-0 sm:w-1/2 md:w-1/3 lg:w-1/4  lg:border-r lg:border-r-gray-10/20">
        <div className="flex h-[2rem] w-[1.875rem] items-center justify-center rounded-[5px] bg-secondary-default/20">
          {icon}
        </div>
        <div className="flex flex-col gap-1">
          <div className="text-sm font-semibold">{value}</div>
          <div className="text-xs text-gray-10/80">{label}</div>
        </div>
      </div>
    )
  }

  return (
    <PermissionGate allowedPermissions={[PERMISSIONS.VIEW_BUSINESS_PROFILE]}>
      <div>
        <TopBar
          pageTitle={merchantData?.name ?? "Merchant Details"}
          subTitle={`Date Onboarded:  ${formatTimestampToDateTime(
            merchantData?.createdOn
          )}`}
        />
        <div className="justify-left mt-8 flex max-w-[65.25rem] flex-wrap rounded-lg bg-white py-4">
          <CardItem
            icon={<TransactionsIcon />}
            value={successfulTxCount}
            label="Successful Transactions"
          />
          <CardItem
            icon={<TotalTransactionsIcon />}
            value={numberUtils.formatNumber(
              totalTransactionValue | 0,
              "en-ng",
              {
                currency: "NGN",
                currencyDisplay: "symbol",
                style: "currency",
              }
            )}
            label="Total Transaction Value"
          />
          <CardItem icon={<TotalPayout />} value="0" label="Total Payout" />
          <CardItem
            icon={<FontAwesomeIcon icon={faUser} />}
            value={userCount}
            label="Users"
          />
        </div>
        <div className="mt-8 max-w-[65.25rem] rounded-lg bg-white p-7 2xl:max-w-full">
          <CustomTab
            defaultIndex={0}
            tabs={[
              {
                label: "Personal Profile",
                icon: <FontAwesomeIcon icon={faUser} />,
              },
              {
                label: "Merchant Profile",
                icon: <MerchantProfileIcon />,
              },
              {
                label: "Settlement Account",
                icon: <SettlementIcon />,
              },
              {
                label: "Transactions",
                icon: <FontAwesomeIcon icon={faSackDollar} />,
              },
            ]}
            panels={[
              {
                content: (
                  <PersonalProfile
                    merchantId={Number(merchantId)}
                    merchantUserId={merchantData?.merchantUserId}
                  />
                ),
              },
              {
                content: (
                  <BusinessProfile
                    merchantBusinessInfo={{
                      businessName: merchantData?.name,
                      businessAddress: merchantData?.address,
                      businessCategory: merchantData?.category,
                      businessType: merchantData?.type,
                      country: merchantData?.country,
                      state: merchantData?.state,
                      description: merchantData?.description,
                      website: merchantData?.website,
                      supportMail: merchantData?.supportEmail,
                      businessLogoUrl: merchantData?.businessLogoUrl,
                    }}
                    isLoading={isLoading}
                  />
                ),
              },
              {
                content: <SettlementAccoount merchantId={Number(merchantId)} />,
              },
              { content: <Transactions merchantId={Number(merchantId)} /> },
            ]}
          />
        </div>
        <div className="mt-9 flex max-w-[65.25rem] items-center justify-between 2xl:max-w-full">
          <BackArrow onClick={() => navigate(-1)} label="Return to Merchants" />
          <PermissionGate allowedPermissions={[PERMISSIONS.MANAGE_MERCHANTS]}>
            <Button
              variant={merchantData?.enabled ? "danger" : "primary"}
              onClick={merchantData?.enabled ? onDeactivate : onActivate}
            >
              {merchantData?.enabled ? "Disable" : "Enable"} Merchant
            </Button>
          </PermissionGate>
        </div>
        {showActivate ? (
          <ActivateDialog
            isOpen={showActivate}
            closeModal={() => setShowActivate(false)}
            merchantId={merchantId}
            merchantName={merchantData?.name}
          />
        ) : null}
        {showDeactivate ? (
          <DeactivateDialog
            isOpen={showDeactivate}
            closeModal={() => setShowDeactivate(false)}
            merchantId={merchantId}
            merchantName={merchantData?.name}
          />
        ) : null}
      </div>
    </PermissionGate>
  )
}

export default MerchantDetails
