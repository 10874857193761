import { queryKeys } from "constants/queryKeys"
import useBreadcrumb from "hooks/useBreadcrumb"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useQuery } from "react-query"
import {
  fetchPayoutTransactions,
  fetchSettlementdetails,
  fetchSettlementMarkoffRequests,
  fetchSettlements,
} from "services/api/settlement"
import { encodeSearchParams } from "utils/encodeSearchParams"

const useSettlement = ({ queryKey, settlementId, castedStatus }: { queryKey: string, settlementId?: number, castedStatus?: string }) => {
  const [showExportModal, setShowExportModal] = React.useState(false)
  const [searchParams, setSearchParams] = useState<{
    status?: { label: string; value: string | undefined }
    startDate?: string
    payoutAmount?: number
    transactionAmount?: number
    endDate?: string
    search?: string | undefined
    paymentChannelId?: number
  }>({
    status: castedStatus ? { label: `${castedStatus}`, value: `${castedStatus || undefined}` } : { label: "All", value: undefined },
    startDate: "",
    endDate: "",
  })
  useBreadcrumb([])

  const [pageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)

  const filterMethods = useForm()
  const searchMethods = useForm()

  const {
    data: settlementResponse,
    isLoading,
    isFetching: refetchingSettlement,
  } = useQuery(
    [
      queryKeys.merchant.fetchMerchantSettlements,
      currentPage,
      pageSize,
      searchParams,
    ],
    () =>
      fetchSettlements({
        pageSize,
        currentPage,
        search: encodeSearchParams({
          status: searchParams.status?.value,
          transactionAmount: searchParams.transactionAmount,
          payoutAmount: searchParams.payoutAmount,
          startDate: searchParams.startDate,
          endDate: searchParams.endDate,
          settlementReference: searchParams?.search?.trim(),
        }),
      }),
    { enabled: queryKey === queryKeys.settlement.fetchSettlements }
  )

  const {
    data: settlementMarkoffResponse,
    isLoading: isLoadingMarkoff,
    isFetching: refetchingMarkoff,
  } = useQuery(
    [queryKeys.settlement.fetchSettlementApprovals],
    () => fetchSettlementMarkoffRequests(),
    {
      enabled: queryKey === queryKeys.settlement.fetchSettlementApprovals,
    }
  )

  const { data: detailsResponse, isLoading: loadingDetails } = useQuery(
    [
      queryKeys.settlement.fetchSettlementDetails,
      currentPage,
      pageSize,
      searchParams,
    ],
    () => fetchSettlementdetails({ settlementId }),
    {
      enabled:
        !!settlementId &&
        queryKey === queryKeys.settlement.fetchSettlementDetails,
    }
  )

  const {
    data: settlementTxResponse,
    isLoading: isLoadingPayout,
    isFetching: refetchingPayout,
  } = useQuery(
    [queryKeys.settlement.fetchPayouts, currentPage, pageSize, searchParams],
    () =>
      fetchPayoutTransactions({
        pageSize,
        currentPage,
        search: encodeSearchParams({
          status: searchParams.status?.value,
          startDate: searchParams.startDate,
          endDate: searchParams.endDate,
          transactionReference: searchParams?.search?.trim(),
          settlementId,
        }),
      }),
    {
      enabled: queryKey === queryKeys.settlement.fetchPayouts,
      staleTime: 0,
    }
  )

  const updateSearchParams = (
    payload: React.SetStateAction<{
      startDate: string
      endDate: string
      search?: string | undefined
    }>
  ) => {
    setSearchParams({
      ...searchParams,
      ...payload,
    })
  }

  //settlement
  const settlementList = settlementResponse?.data?.data.content
  const totalElements = settlementResponse?.data?.data?.totalElements
  const totalPages = settlementResponse?.data?.data?.totalPages

  //markoff settlement
  const settlementMarkoffList = settlementMarkoffResponse?.data?.data

  //payout
  const settlementTxList: PayoutTransaction[] =
    settlementTxResponse?.data?.data.content
  const totalTxElements = settlementTxResponse?.data?.data?.totalElements
  const totalTxPages = settlementTxResponse?.data?.data?.totalPages

  const { handleSubmit: handleFilter } = filterMethods
  const { handleSubmit: handleSearch } = searchMethods

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFilter = (data: any) => {
    updateSearchParams({
      ...data,
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSearch = (data: any) => {
    setSearchParams({
      ...searchParams,
      ...data,
    })
  }

  const handlePageChange = (page: { selected: number }) => {
    setCurrentPage(page.selected)
  }

  //pagination for settlement
  const pagination = {
    pageSize,
    currentPage,
    totalElements,
    totalPages,
    handlePageChange,
  }

  //pagination for payout
  const txPagination = {
    pageSize,
    currentPage,
    totalElements: totalTxElements,
    totalPages: totalTxPages,
    handlePageChange,
  }

  const search = {
    searchMethods,
    handleSearch,
    onSearch,
    updateSearchParams,
    searchParams,
  }
  const filter = {
    filterMethods,
    handleFilter,
    onFilter,
    resetFilter: filterMethods.reset,
  }

  const settlement = {
    settlementList,
    isLoading,
    refetchingSettlement,
    settlementMarkoffList,
    isLoadingMarkoff,
    refetchingMarkoff,
  }
  const settlementTx = { settlementTxList, isLoadingPayout, refetchingPayout }

  const exportManager = { showExportModal, setShowExportModal }

  return {
    settlement,
    settlementTx,
    search,
    filter,
    pagination,
    txPagination,
    exportManager,
    detailsData: detailsResponse?.data?.data,
    loadingDetails,
  }
}

export default useSettlement
