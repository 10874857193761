import React, { useState } from "react"
import useBreadcrumb from "hooks/useBreadcrumb"
import { fetchKYCByStatus, fetchKYCFromMakerChecker } from "services/api/KYC"
import { useQuery } from "react-query"
import { useForm } from "react-hook-form"
import { queryKeys } from "constants/queryKeys"
import { queryClient } from "index"
import { KYCListEntry, KYCStatus } from "../types/kyc"
import { encodeSearchParams } from "utils/encodeSearchParams"

const useKYC = (kycHookData: { kycStatus?: KYCStatus; queryKey?: string }) => {
  const [showExportModal, setShowExportModal] = React.useState(false)
  const [showFilter, setShowFilter] = useState(false)

  const [searchParams, setSearchParams] = useState<{
    state?: string
    country?: string
    dateCreated?: string
    search?: string | undefined
  }>({
    state: "",
    country: "",
    dateCreated: "",
  })

  const [pageSize] = React.useState(10)
  const [currentPage, setCurrentPage] = React.useState(0)

  const filterMethods = useForm()
  const searchMethods = useForm()

  useBreadcrumb([])
  const {
    data: KYCResponse,
    isLoading,
    isFetching,
  } = useQuery(
    [
      `${queryKeys.kyc.fetchKYCByStatus}${kycHookData.kycStatus}`,
      kycHookData.kycStatus,
      currentPage,
      pageSize,
      searchParams,
    ],
    () =>
      fetchKYCByStatus({
        pageSize,
        currentPage,
        kycRequestQueryType: kycHookData.kycStatus,
        search: encodeSearchParams({
          kycRequestQueryType: kycHookData.kycStatus,
          state: searchParams?.state,
          country: searchParams?.country,
          dateCreated: searchParams?.dateCreated,
        }),
      })
  )

  const {
    data: MakerCheckerKYCResponse,
    isLoading: isLoadingMakerChecker,
    isFetching: isFetchingMakerChecker,
  } = useQuery(
    [`${queryKeys.kyc.fetchKYCFromMakerChecker}`],
    () => fetchKYCFromMakerChecker(),
    {
      enabled: kycHookData.queryKey === queryKeys.kyc.fetchKYCFromMakerChecker,
    }
  )

  const updateSearchParams = (
    payload: React.SetStateAction<{
      startDate: string
      endDate: string
      search?: string | undefined
      state?: string
      country?: string
      dateCreated?: string
    }>
  ) => {
    setSearchParams({
      ...searchParams,
      ...payload,
    })
  }

  const kycList: KYCListEntry[] = KYCResponse?.data?.data.content
  const totalElements = KYCResponse?.data?.data?.totalElements
  const totalPages = KYCResponse?.data?.data?.totalPages

  const { handleSubmit: handleFilter } = filterMethods
  const { handleSubmit: handleSearch } = searchMethods

  const toggleFilter = () => {
    setShowFilter(!showFilter)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFilter = (data: any) => {
    toggleFilter()
    updateSearchParams({
      ...data,
      state: data?.state?.value,
      country: data?.country?.value,
    })
  }

  const onSearch = () => {
    queryClient.invalidateQueries([
      queryKeys.kyc.fetchPendingKYC,
      currentPage,
      pageSize,
    ])
  }

  const handlePageChange = (page: { selected: number }) => {
    setCurrentPage(page.selected)
  }

  const pagination = {
    pageSize,
    currentPage,
    totalElements,
    totalPages,
    handlePageChange,
  }

  const search = { searchMethods, handleSearch, onSearch }

  const filter = {
    filterMethods,
    handleFilter,
    onFilter,
    resetFilter: filterMethods.reset,
    toggleFilter,
    showFilter,
  }

  const kyc = { kycList, isLoading: isLoading || isFetching }

  const makerCheckerKYC = {
    makerCheckerKycList: MakerCheckerKYCResponse?.data?.data,
    isLoading: isLoadingMakerChecker || isFetchingMakerChecker,
  }

  const exportManager = { showExportModal, setShowExportModal }

  return {
    kyc,
    makerCheckerKYC,
    exportManager,
    pagination,
    search,
    filter,
  }
}

export default useKYC
