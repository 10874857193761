import React from "react"
import TGIPLogo from "../../assets/images/TGIPrimaryLogo.svg"

interface Props {
  children?: React.ReactNode
  mainTitle: string
  subTitle?: string
  contentArea?: React.ReactNode
}
const PublicLayout: React.FC<Props> = ({
  children,
  mainTitle,
  subTitle,
  contentArea,
}) => {
  return (
    <div className="flex min-h-screen justify-center bg-[#F9FAFB]">
      <div className="mt-[4rem] flex w-full flex-col items-center xl:mt-16">
        <img src={TGIPLogo} alt="logo" className="h-9 w-[8.3rem]" />
        <h3
          className={`
                ${subTitle ? "text-2xl" : "text-3xl"}
                mt-[2.5rem] text-center font-bold text-gray-10 xl:mt-[3rem]`}
        >
          {mainTitle}
        </h3>
        <div className="mt-4 w-[24rem] text-center">{subTitle}</div>
        <div className="mt-[3.5rem] flex w-[40rem] max-w-full flex-col rounded-[0.5rem] bg-white  p-8 xl:mt-[3.5rem]">
          {contentArea}
        </div>
        {children}
      </div>
    </div>
  )
}

export default PublicLayout
