import TopBar from "layout/protected/ContentArea/TopBar"
import React, { useState } from "react"
import WorkflowActions from "./WorkflowActions"
import Table from "components/Table"
import { workflowListColumns } from "./WorkflowList/WorkflowListColumns"
import { useWorkflow } from "./hooks/useWorkflow"
import { queryKeys } from "constants/queryKeys"
import PaginationInfo from "components/Pagination/PaginationInfo"
import { Pagination } from "components/Pagination/PaginationComponent"
import WorkflowAdd from "./WorkflowAdd"

const Workflow = () => {
  const [showCreateDialog, setShowCreateDialog] = useState(false)

  const { workflow, pagination } = useWorkflow(queryKeys.workflow.fetchWorkflow)

  return (
    <>
      <TopBar
        pageTitle="Manage Approval Workflow"
        subTitle="Add and manage approval workflows."
        TopBarActions={
          <WorkflowActions setShowCreateDialog={setShowCreateDialog} />
        }
      />

      <div className="mt-4 border border-[#D3D3D3]">
        <Table
          columns={workflowListColumns}
          data={workflow.fetch.workflowList}
          isLoading={workflow.fetch.isLoading}
          loadingSkeletonCount={10}
        />
        {workflow.fetch.workflowList?.length > 0 ? (
          <div className="flex h-[4.3125rem] items-center justify-between bg-white px-5">
            <PaginationInfo pagination={pagination} />

            <div>
              <Pagination pagination={pagination} />
            </div>
          </div>
        ) : null}

        {showCreateDialog ? (
          <WorkflowAdd
            isOpen={showCreateDialog}
            handleClose={() => setShowCreateDialog(false)}
          />
        ) : null}
      </div>
    </>
  )
}

export default Workflow
