import { AxiosError } from "axios"
import Button from "components/button"
import Input from "components/input"
import Modal from "components/modal/Modal"
import { queryKeys } from "constants/queryKeys"
import { queryClient } from "index"
import React, { useEffect } from "react"
import { FieldValues, FormProvider, useForm } from "react-hook-form"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { editEmail } from "services/api/configuration"
import { handleError } from "utils/getAxiosErrorMessage"

const EditEmailDialog: React.FC<{
  isOpen: boolean
  handleClose: () => void
  selectedEmail: EmailSettingsType
}> = ({ isOpen, handleClose, selectedEmail }) => {
  const methods = useForm()

  useEffect(() => {
    if (selectedEmail) {
      methods.reset({
        email: selectedEmail.email,
        description: selectedEmail.description,
      })
    }
  }, [selectedEmail])

  const { mutate: handleEditEmail, isLoading: editingChannel } = useMutation(
    editEmail,
    {
      onSuccess: (response) => {
        toast.success(response?.data?.message)
        queryClient.invalidateQueries(
          queryKeys.configuration.fetchEmailSettings
        )
        handleClose()
      },
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    }
  )

  const onSubmit = (data: FieldValues) => {
    const payload = {
      id: selectedEmail.id,
      email: data.email,
      description: data.description,
    }
    handleEditEmail(payload)
  }

  return (
    <Modal
      contentContainerClass="p-6 max-w-xl"
      isOpen={isOpen}
      closeModal={handleClose}
    >
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="mb-6">
          <h5 className="text-lg font-medium">Edit Email</h5>
          <div className="text-xs font-normal">
            Set emails for existing merchants.
          </div>
        </div>

        <FormProvider {...methods}>
          <div className="flex flex-col gap-4">
            <Input label="Email" name="email" type="email" />
            <Input label="Purpose" name="description" />
          </div>
        </FormProvider>
        <div className="flex justify-end gap-6 ">
          <Button
            onClick={handleClose}
            className="ml-4 mt-6"
            variant="outlinePrimary"
          >
            Cancel
          </Button>
          <Button className="mt-6" loading={editingChannel}>
            Edit Email
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default EditEmailDialog
