import { useForm } from "react-hook-form"
import { useQuery } from "react-query"
import { queryKeys } from "constants/queryKeys"
import {
  fetchAllTxByDateCount,
  fetchFailedByDateCount,
  fetchPendingKYCCount,
  fetchSuccessfulByDateCount,
  fetchSuccessfulValuePoints,
  fetchTotalValueByDate,
  fetchActiveMerchantsByDate,
  fetchPendingSettlementCount,
} from "services/api/payment"
import { fetchTotalIncome, fetchTotalPayout } from "services/api/settlement"
import { DATE_GROUPINGS, DateGrouping } from "constants/dashboard"

const useDashboard = ({
  startDate,
  endDate,
  targetQuerykeys,
  dateGrouping,
}: {
  startDate: string
  endDate: string
  targetQuerykeys: string[]
  dateGrouping?: DateGrouping
}) => {
  const filterMethods = useForm()
  const searchMethods = useForm()
  const { handleSubmit: handleSearch } = filterMethods
  const { handleSubmit: handleFilter } = searchMethods

  const { data: numberOfTxResponse, isLoading: loadingAllTxValueByDate } =
    useQuery(
      [queryKeys.dashboard.allTxValue, startDate, endDate, dateGrouping],
      () => fetchAllTxByDateCount({ startDate, endDate }),
      {
        enabled: targetQuerykeys.includes(queryKeys.payment.metricCards),
      }
    )

  const {
    data: allSuccessfulValuePointsResponse,
    isLoading: loadingSuccessfulValuePoints,
  } = useQuery(
    [
      queryKeys.dashboard.allSuccessfulByValuePoints,
      startDate,
      endDate,
      dateGrouping,
    ],
    () =>
      fetchSuccessfulValuePoints({
        startDate,
        endDate,
        dateGrouping: dateGrouping || DATE_GROUPINGS.day,
      }),
    {
      enabled: targetQuerykeys.includes(
        queryKeys.dashboard.allSuccessfulByValuePoints
      ),
    }
  )

  const {
    data: successfulByDateCountResponse,
    isLoading: loadingSuccessfulByDateCount,
  } = useQuery(
    [queryKeys.dashboard.successfulByDateCount, startDate, endDate],
    () => fetchSuccessfulByDateCount({ startDate, endDate }),
    {
      enabled: targetQuerykeys.includes(queryKeys.payment.metricCards),
    }
  )

  const {
    data: pendingByDateCountResponse,
    isLoading: loadingPendingByDateCount,
  } = useQuery(
    [queryKeys.dashboard.pendingByDateCount, startDate, endDate],
    () => fetchPendingKYCCount(),
    {
      enabled: targetQuerykeys.includes(queryKeys.payment.metricCards),
    }
  )

  const {
    data: failedByDateCountResponse,
    isLoading: loadingFailedByDateCount,
  } = useQuery(
    [queryKeys.dashboard.failedByDateCount, startDate, endDate],
    () => fetchFailedByDateCount({ startDate, endDate }),
    {
      enabled: targetQuerykeys.includes(queryKeys.dashboard.failedByDateCount),
    }
  )

  const {
    data: activeMerchantsByDateCounResponse,
    isLoading: loadingActiveMerchantsByDateCount,
  } = useQuery(
    [queryKeys.dashboard.activeMerchantsByDateCount, startDate, endDate],
    () => fetchActiveMerchantsByDate({ startDate, endDate }),
    {
      enabled: targetQuerykeys.includes(queryKeys.payment.metricCards),
    }
  )

  const {
    data: pendingSettlementCountResponse,
    isLoading: loadingPendingSettlementCount,
  } = useQuery(
    [queryKeys.dashboard.fetchPendingSettlementCount, startDate, endDate],
    () => fetchPendingSettlementCount({ startDate, endDate }),
    {
      enabled: targetQuerykeys.includes(queryKeys.payment.metricCards),
    }
  )

  const { data: totalValueByDateResponse, isLoading: loadingTotalValueByDate } =
    useQuery(
      [queryKeys.dashboard.totalValueByDate, startDate, endDate],
      () => fetchTotalValueByDate({ startDate, endDate }),
      {
        enabled: targetQuerykeys.includes(queryKeys.payment.metricCards),
      }
    )

  const { data: totalPayoutResponse, isLoading: loadingTotalPayout } = useQuery(
    [queryKeys.dashboard.totalPayout, startDate, endDate],
    () => fetchTotalPayout({ startDate, endDate }),
    {
      enabled: targetQuerykeys.includes(queryKeys.payment.metricCards),
    }
  )

  const { data: totalIncomeResponse, isLoading: loadingTotalIncome } = useQuery(
    [queryKeys.dashboard.totalIncome, startDate, endDate],
    () => fetchTotalIncome({ startDate, endDate }),
    {
      enabled: targetQuerykeys.includes(queryKeys.payment.metricCards),
    }
  )

  const onSearch = () => {
    // console.log(data)
  }

  const onFilter = () => {
    // console.log(data)
  }

  const filter = {
    handleFilter,
    filterMethods,
    onFilter,
    resetFilter: filterMethods.reset,
  }

  const loading = {
    loadingTotalValueByDate,
    loadingAllTxValueByDate,
    loadingSuccessfulValuePoints,
    loadingSuccessfulByDateCount,
    loadingPendingByDateCount,
    loadingFailedByDateCount,
    loadingActiveMerchantsByDateCount,
    loadingPendingSettlementCount,
    loadingTotalPayout,
    loadingTotalIncome,
  }

  const data = {
    totalValueByDate: totalValueByDateResponse?.data?.data,
    allTxValueByDate: numberOfTxResponse?.data?.data,
    allSuccessfulValuePoints: allSuccessfulValuePointsResponse?.data?.data,
    successfulByDateCount: successfulByDateCountResponse?.data?.data,
    pendingByDateCount: pendingByDateCountResponse?.data?.data,
    failedByDateCount: failedByDateCountResponse?.data?.data,
    activeMerchantsByDateCount: activeMerchantsByDateCounResponse?.data?.data,
    pendingSettlementCount: pendingSettlementCountResponse?.data?.data,
    transactionsByStatusData: [
      { value: failedByDateCountResponse?.data?.data, name: "Failed" },
      { value: successfulByDateCountResponse?.data?.data, name: "Successful" },
    ],
    activityData: [],
    totalPayout: totalPayoutResponse?.data?.data,
    totalIncome: totalIncomeResponse?.data?.data,
  }

  const search = { handleSearch, searchMethods, onSearch }
  return {
    filter,
    search,
    data,
    loading,
  }
}

export default useDashboard
