import TopBar from "layout/protected/ContentArea/TopBar"
import React, { useState } from "react"
import { faUser, faUsers } from "@fortawesome/free-solid-svg-icons"
import { Team } from "./Team"
import AdminUserRoles from "./AdminUserRoles/ManageRoles/AdminUserRoles"
import ManageMerchantRoles from "./MerchantRoles/MerchantRoles"
import PermissionGate from "components/PermissionGate/PermissionGate"
import { PERMISSIONS } from "constants/permissions"

const Teams: React.FC = () => {
  const [showAdminUserRoles, setShowAdminUserRoles] = useState(false)
  const [showMerchantRoles, setShowMerchantRoles] = useState(false)

  if (showAdminUserRoles)
    return <AdminUserRoles handleBack={() => setShowAdminUserRoles(false)} />

  if (showMerchantRoles)
    return <ManageMerchantRoles setShowMerchantRoles={setShowMerchantRoles} />

  return (
    <div className="relative">
      <TopBar
        pageTitle="Teams"
        subTitle="Manage roles and users on your account."
      />
      <div className="mt-8 flex gap-8">
        <PermissionGate allowedPermissions={[PERMISSIONS.VIEW_ADMIN_ROLES]}>
          <Team
            icon={faUser}
            title="Admin"
            description="Manage TGI Pay admin roles and users."
            handleOpen={() => setShowAdminUserRoles(true)}
          />
        </PermissionGate>
        <PermissionGate allowedPermissions={[PERMISSIONS.VIEW_MERCHANT_ROLES]}>
          <Team
            icon={faUsers}
            title="Merchants"
            description="Manage TGI Pay merchant roles."
            handleOpen={() => setShowMerchantRoles(true)}
          />
        </PermissionGate>
      </div>
    </div>
  )
}

export default Teams
