import * as yup from "yup"

export const configureWorkflowSchema = yup.object().shape({
  workFlowCode: yup.string().required("Workflow code is required"),
  workflowAuthorizerRequestDtoList: yup.array().of(
    yup.object().shape({
      authorizers: yup
        .array()
        .of(
          yup.object().shape({
            value: yup.string().required("Authorizer is required"),
          })
        )
        .required("At least one authorizer is required"),
      authorizationLevel: yup
        .number()
        .required("Authorization level is required"),
    })
  ),
})
