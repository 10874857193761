import { faAngleDown, faFilter } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "components/button"
import { Dropdown } from "components/Dropdown/Dropdown"
import Input from "components/input"
import CustomSelect from "components/Select/CustomSelect"
import React, { useEffect, useState } from "react"
import {
  FieldValues,
  FormProvider,
  UseFormHandleSubmit,
  UseFormReturn,
} from "react-hook-form"
import { ReactComponent as ReloadIcon } from "../../../../assets/icons/reload.svg"
import { allCountries, getStatesByCountry } from "constants/country-states"

type StateOptions = {
  name: string
  code: string
}

type Props = {
  search: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    searchMethods: UseFormReturn<FieldValues, any>
    handleSearch: UseFormHandleSubmit<FieldValues>
    onSearch: () => void
  }
  filter: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filterMethods: UseFormReturn<FieldValues, any>
    handleFilter: UseFormHandleSubmit<FieldValues>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resetFilter: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onFilter: (data: any) => void
    toggleFilter: () => void
    showFilter: boolean
  }
}

const KYCFilter: React.FC<Props> = ({ filter }) => {
  // const { searchMethods, handleSearch, onSearch } = search
  const { filterMethods, handleFilter, onFilter } = filter
  const [countries] = useState([...allCountries])
  const [states, setStates] = useState<StateOptions[]>([])

  const values = filterMethods.watch()

  useEffect(() => {
    const statesInSelectedCountry = getStatesByCountry(values.country?.value)
    setStates(statesInSelectedCountry)
    filterMethods.setValue("state", { value: "", label: "" })
  }, [values.country?.value])

  return (
    <>
      <Dropdown
        isOpen={filter.showFilter}
        toggleOpen={filter.toggleFilter}
        showBackdrop={false}
        contentContainerClass="w-min"
        trigger={
          <Button
            variant="link"
            className="relative flex h-10 min-w-[7.5rem] items-center justify-evenly gap-[15px] rounded-md px-4 text-base font-medium text-primary-default md:min-w-[12.875rem] md:bg-white md:shadow-lg"
          >
            <FontAwesomeIcon className="ml-auto" icon={faFilter} />
            <span className="hidden md:inline-block">Filter by</span>
            <span className="hidden text-gray-10/50 md:inline-block">
              <FontAwesomeIcon icon={faAngleDown} />
            </span>
          </Button>
        }
        content={
          <FormProvider {...filterMethods}>
            <form onSubmit={handleFilter(onFilter)} autoComplete="off">
              <div className="flex flex-col gap-2">
                {/* <CustomSelect
                  label="Business Type"
                  options={[
                    { value: "All", label: "All" },
                    { value: "One", label: "One" },
                  ]}
                  labelprops={{ className: "text-xs" }}
                  controlProps={{ className: "h-8 text-xs" }}
                  name="businessType"
                /> */}
                <CustomSelect
                  label="Country"
                  options={countries}
                  labelprops={{ className: "text-xs" }}
                  controlProps={{ className: "h-8 text-xs" }}
                  name="country"
                />
                <CustomSelect
                  label="State"
                  options={states?.map((state) => {
                    return {
                      label: state.name,
                      value: state.code,
                    }
                  })}
                  labelprops={{ className: "text-xs" }}
                  controlProps={{ className: "h-8 text-xs" }}
                  name="state"
                />
                <Input
                  labelprops={{ className: "text-xs" }}
                  type="date"
                  label="Date Created"
                  name="dateCreated"
                  className="h-8 text-xs"
                />

                <div className="mt-2 flex justify-between gap-6">
                  <Button
                    className="flex h-8 items-center gap-2 text-sm"
                    variant="outlineDefault"
                    onClick={() => filter.resetFilter()}
                  >
                    <ReloadIcon className="w-4" /> Reset
                  </Button>
                  <Button className="h-8 w-28">Apply</Button>
                </div>
              </div>
            </form>
          </FormProvider>
        }
      />
      {/* <FormProvider {...searchMethods}>
        <form onSubmit={handleSearch(onSearch)} autoComplete="off">
          <Input
            name="search"
            type="search"
            inputContainerProps={{ className: "md:w-[15rem]" }}
            placeholder="Search kyc"
          />
        </form>
      </FormProvider> */}
    </>
  )
}

export default KYCFilter
