import Table from "components/Table"
import React from "react"
import { EmailColumns } from "./Columns"
import useConfiguration from "pages/protected/Configuration/hooks/useConfiguration"
import { queryKeys } from "constants/queryKeys"
type Props = {
  emailData: EmailSettingsType[]
  loading: boolean
}
const EmailSettingsTable: React.FC<Props> = () => {
  const { data, loading } = useConfiguration(
    queryKeys.configuration.fetchEmailSettings
  )

  return (
    <div className="py-6">
      <Table
        columns={EmailColumns}
        data={data.emailData}
        isLoading={loading.loadingEmailSettings}
      />
    </div>
  )
}

export default EmailSettingsTable
