import { savePDF } from "@progress/kendo-react-pdf"
import React from "react"
type DownloadProps = {
  fileName: string
}
const usePDFDownload = (downloadProps: DownloadProps) => {
  const { fileName } = downloadProps
  const pdfContainer = React.useRef(null)

  const exportPDFWithMethod = () => {
    const element = pdfContainer.current || document.body
    savePDF(element, {
      paperSize: "A2",
      margin: 40,
      fileName: fileName,
    })
  }
  return { exportPDFWithMethod, pdfContainer }
}

export default usePDFDownload
