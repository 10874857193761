import TopBar from "layout/protected/ContentArea/TopBar"
import React from "react"
import PermissionGate from "components/PermissionGate/PermissionGate"
import { PERMISSIONS } from "constants/permissions"
import ListAccessDenied from "components/PermissionGate/ListAccessDenied"
import CustomTab from "components/Tab/Tab"
import { ReactComponent as SettlementIcon } from "assets/icons/merchants/settlement.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser } from "@fortawesome/free-solid-svg-icons"
import MerchantList from "./Merchants"
import MerchantApprovalRequests from "./MerchantApprovalRequests"

const Merchants: React.FC = () => {
  return (
    <PermissionGate
      allowedPermissions={[PERMISSIONS.VIEW_MERCHANTS]}
      fallback={<ListAccessDenied />}
    >
      <div className="relative">
        <TopBar
          pageTitle="Merchants"
          subTitle="View all merchants on the platform."
        />

        <div className="mt-8 rounded-lg bg-white p-7 2xl:max-w-full">
          <CustomTab
            tabs={[
              {
                label: "Merchants",
                icon: <FontAwesomeIcon icon={faUser} />,
              },
              {
                label: "Merchant Approval Requests",
                icon: <SettlementIcon />,
              },
            ]}
            panels={[
              {
                content: <MerchantList />,
              },
              {
                content: <MerchantApprovalRequests />,
              },
            ]}
            defaultIndex={Number()}
          />
        </div>
      </div>
    </PermissionGate>
  )
}

export default Merchants
