import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "components/button"
import { Dropdown } from "components/Dropdown/Dropdown"
import Input from "components/input"
import CustomSelect from "components/Select/CustomSelect"
import React, { useEffect, useState } from "react"
import {
  FieldValues,
  FormProvider,
  UseFormHandleSubmit,
  UseFormReset,
  UseFormReturn,
} from "react-hook-form"
import { faAngleDown, faFilter } from "@fortawesome/free-solid-svg-icons"
import { ReactComponent as ReloadIcon } from "assets/icons/reload.svg"
import { format } from "date-fns"
import { allCountries, getStatesByCountry } from "constants/country-states"
import DatePicker from "react-datepicker"

type StateOptions = {
  name: string
  code: string
}

type Props = {
  filter: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filterMethods: UseFormReturn<FieldValues, any>
    handleFilter: UseFormHandleSubmit<FieldValues>
    onFilter: (data: FieldValues) => void
    resetFilter: UseFormReset<FieldValues>
  }
  search: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    searchMethods: UseFormReturn<FieldValues, any>
    handleSearch: UseFormHandleSubmit<FieldValues>
    onSearch: (data: FieldValues) => void
  }
}

const MerchantsFilter: React.FC<Props> = ({ search, filter }) => {
  const [showFilter, setShowFilter] = React.useState(false)
  const [countries] = useState([...allCountries])
  const [states, setStates] = useState<StateOptions[]>([])

  const { handleSubmit: handleSearch } = search.searchMethods

  const [dateCreated, setDateCreated] = useState<Date | null>()

  const values = filter.filterMethods.watch()

  useEffect(() => {
    const statesInSelectedCountry = getStatesByCountry(values.country?.value)
    setStates(statesInSelectedCountry)
    filter.filterMethods.setValue("state", { value: "", label: "" })
  }, [values.country?.value])

  const toggleFilter = () => {
    setShowFilter(!showFilter)
  }

  const handleReset = () => {
    setDateCreated(null)
    filter.resetFilter()
    filter.filterMethods.setValue("country", { value: "", label: "" })
    filter.filterMethods.setValue("state", { value: "", label: "" })
  }

  return (
    <>
      <Dropdown
        isOpen={showFilter}
        toggleOpen={toggleFilter}
        showBackdrop={false}
        trigger={
          <Button
            variant="link"
            className="relative flex h-10 min-w-[7.5rem] items-center justify-center gap-6 rounded-md px-4 text-base font-medium text-primary-default md:min-w-[12.875rem] md:bg-white md:shadow-lg"
          >
            <FontAwesomeIcon icon={faFilter} />
            <span className="hidden text-xs font-medium text-gray-10 md:inline-block">
              Filter by
            </span>
            <span className="hidden text-gray-10/50 md:inline-block">
              <FontAwesomeIcon icon={faAngleDown} />
            </span>
          </Button>
        }
        content={
          <FormProvider {...filter.filterMethods}>
            <form
              onSubmit={filter.handleFilter((data) => {
                return filter.onFilter({
                  ...data,
                  dateCreated: dateCreated && format(dateCreated, "dd-MM-yyyy"),
                })
              })}
              autoComplete="off"
            >
              <div className="flex flex-col gap-3">
                <CustomSelect
                  label="Country"
                  options={countries}
                  labelprops={{ className: "text-xs" }}
                  controlProps={{ className: "h-8 text-xs" }}
                  name="country"
                />
                <CustomSelect
                  label="State"
                  options={states?.map((state) => {
                    return {
                      label: state.name,
                      value: state.code,
                    }
                  })}
                  labelprops={{ className: "text-xs" }}
                  controlProps={{ className: "h-8 text-xs" }}
                  name="state"
                />

                <div>
                  <label htmlFor="startDate">Date Created</label>
                  <DatePicker
                    selected={dateCreated}
                    onChange={(date) => setDateCreated(date)}
                    startDate={dateCreated}
                    className="w-full border-gray-20"
                    maxDate={new Date()}
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                <div className="mt-2 flex justify-between gap-6">
                  <Button
                    className="flex items-center gap-2"
                    variant="outlineDefault"
                    onClick={handleReset}
                  >
                    <ReloadIcon /> Reset
                  </Button>
                  <Button className="w-28">Apply</Button>
                </div>
              </div>
            </form>
          </FormProvider>
        }
      />
      <FormProvider {...search.searchMethods}>
        <form onSubmit={handleSearch(search.onSearch)} autoComplete="off">
          <Input
            name="search"
            type="search"
            inputContainerProps={{ className: "md:w-[15rem] " }}
            placeholder="Search by business name"
          />
        </form>
      </FormProvider>
    </>
  )
}

export default MerchantsFilter
