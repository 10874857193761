import { requestKYCReview } from "services/api/KYC"
import { AxiosError } from "axios"
import Button from "components/button"
import TextArea from "components/input/TextArea"
import Modal from "components/modal/Modal"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { KYCDetailsType } from "types/KYC"
import { handleError } from "utils/getAxiosErrorMessage"
import { queryKeys } from "constants/queryKeys"
import { queryClient } from "index"

type AskForReviewProps = {
  isOpen: boolean
  closeAskForReview: () => void
  kycDetails: KYCDetailsType
}
const AskForReviewModal: React.FC<AskForReviewProps> = ({
  isOpen,
  closeAskForReview,
  kycDetails,
}) => {
  const methods = useForm()
  const values = methods.watch()

  const { mutate: handleRequestForReview, isLoading } = useMutation(
    requestKYCReview,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(queryKeys.kyc.fetchPendingKYC)
        toast.success(response.data.message)
        closeAskForReview()
      },
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    }
  )

  const handleClose = () => {
    methods.reset()
    closeAskForReview()
  }

  return (
    <Modal isOpen={isOpen} closeModal={handleClose}>
      <FormProvider {...methods}>
        <div className="flex flex-col items-center p-6">
          <h5 className="text-xl font-semibold">Ask To Review</h5>

          <div className="mt-6 text-center">
            Please state reason why you are asking{" "}
            <span className="font-bold">{kycDetails.businessName}</span> to
            review their KYC request.
          </div>
          <TextArea
            inputContainerProps={{ className: "w-full" }}
            name="reasonForDecline"
            rows={3}
            placeholder="Description..."
          />
          <div className="mt-7 flex gap-4">
            <Button onClick={handleClose} className="h-8" variant="linkPrimary">
              Cancel
            </Button>
            <Button
              loading={isLoading}
              onClick={() =>
                handleRequestForReview({
                  businessId: kycDetails.businessId,
                  comment: values.reasonForDecline,
                })
              }
              className="h-8"
            >
              Ask To Review
            </Button>
          </div>
        </div>
      </FormProvider>
    </Modal>
  )
}

export default AskForReviewModal
