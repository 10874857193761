import ExportModal from "components/ExportModal/ExportModal"
import { Pagination } from "components/Pagination/PaginationComponent"
import PaginationInfo from "components/Pagination/PaginationInfo"
import Table from "components/Table"
import React from "react"
import KYCFilter from "./Filter/KYCFilter"
import useKYC from "./hooks/useKYC"
import { KYCColumns } from "./Table/columns"
import { KYCListEntry, KYCStatus } from "./types/kyc"
import { kycEndpoints } from "constants/endpoints"
import { baseUrl } from "config/baseUrl"

export type Props = {
  status: KYCStatus
  tabIndex: number
}

const KYCListByStatus: React.FC<Props> = ({ status }) => {
  const { kyc, pagination, exportManager, search, filter } = useKYC({
    kycStatus: status,
  })
  return (
    <>
      <div className="flex flex-wrap items-center justify-end gap-6 pt-2">
        <KYCFilter search={search} filter={filter} />
      </div>
      <div className="mt-4 border border-[#D3D3D3]">
        <Table
          isLoading={kyc.isLoading}
          columns={KYCColumns}
          data={kyc?.kycList?.map((kyc: KYCListEntry) => {
            return {
              ...kyc,
              status,
            }
          })}
          loadingSkeletonCount={10}
        />
        {kyc.kycList?.length > 0 && (
          <div className="flex h-[4.3125rem] items-center justify-between bg-white px-5">
            <PaginationInfo pagination={pagination} />
            <div>
              <Pagination pagination={pagination} />
            </div>
          </div>
        )}
      </div>

      {/* {kyc.kycList ? (
        <Button
          className="mt-9 w-[6rem]"
          onClick={() => exportManager.setShowExportModal(true)}
        >
          Export
        </Button>
      ) : null} */}

      {exportManager.showExportModal ? (
        <ExportModal
          isOpen={exportManager.showExportModal}
          closeModal={() => exportManager.setShowExportModal(false)}
          exportDataName="KYC"
          downloadUrl={`${baseUrl.adminUserService}${kycEndpoints.pendingKYCRequests}`}
          downloadParams={{}}
        />
      ) : null}
    </>
  )
}

export default KYCListByStatus
