import React from "react"
import Skeleton from "react-loading-skeleton"

export const MetricCard: React.FC<{
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  value: number | string
  label: string
  loading?: boolean
}> = ({ Icon, value, label, loading }) => {
  return (
    <div className="flex min-w-[16.7rem] items-center gap-3 bg-white px-2 py-4 shadow-xl">
      <div>
        <div className="flex h-14 w-14 items-center justify-center rounded-lg bg-secondary-default/20">
          {loading ? <Skeleton /> : <Icon className="w-7" />}
        </div>
      </div>
      <div className="w-full">
        <div className="text-lg font-semibold">
          {loading ? <Skeleton /> : value}
        </div>
        <div className="text-xs text-gray-10/60">
          {loading ? <Skeleton /> : label}
        </div>
      </div>
    </div>
  )
}
