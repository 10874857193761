import React from "react"
import TopBar from "layout/protected/ContentArea/TopBar"
import { ReactComponent as SettlementIcon } from "assets/icons/merchants/settlement.svg"

import { faUser } from "@fortawesome/free-solid-svg-icons"
import PermissionGate from "components/PermissionGate/PermissionGate"
import { PERMISSIONS } from "constants/permissions"
import ListAccessDenied from "components/PermissionGate/ListAccessDenied"
import CustomTab from "components/Tab/Tab"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import SettlementList from "./Settlement"
import SettlementMarkoffList from "./MarkoffList"

const Settlement: React.FC = () => {
  return (
    <PermissionGate
      allowedPermissions={[PERMISSIONS.VIEW_PAYOUTS]}
      fallback={<ListAccessDenied />}
    >
      <div>
        <TopBar pageTitle="Settlement" subTitle="View all merchant payouts." />

        <div className="mt-8 rounded-lg bg-white p-7 2xl:max-w-full">
          <CustomTab
            tabs={[
              {
                label: "Settlements",
                icon: <FontAwesomeIcon icon={faUser} />,
              },

              {
                label: "Successful Settlements",
                icon: <FontAwesomeIcon icon={faUser} />,
              },
              {
                label: "Pending Settlements",
                icon: <FontAwesomeIcon icon={faUser} />,
              },
              {
                label: "Failed Settlements",
                icon: <FontAwesomeIcon icon={faUser} />,
              },
              {
                label: "Markoff Requests",
                icon: <SettlementIcon />,
              },
            ]}
            panels={[
              {
                content: <SettlementList />,
              },

              {
                content: <SettlementList castedStatus="successful" />,
              },
              {
                content: <SettlementList castedStatus="pending" />,
              },
              {
                content: <SettlementList castedStatus="failed" />,
              },
              {
                content: <SettlementMarkoffList />,
              },
            ]}
            defaultIndex={Number()}
          />
        </div>
      </div>
    </PermissionGate>
  )
}
export default Settlement
