import { generateQueryString } from "utils/generateQueryString"
import {
  paymentEndpoints,
  settlementEndpoints,
} from "../../constants/endpoints"
import { UserAxiosInstance } from "services/axios/userApi"

export const fetchSettlements = async (payload: {
  currentPage: number
  pageSize: number
  search: string
}) => {
  const response = await UserAxiosInstance.get(
    `${settlementEndpoints.fetchSettlements}?page=${payload.currentPage}&size=${payload.pageSize}&${payload.search}`
  )
  return response
}

export const fetchSettlementMarkoffRequests = async () => {
  const response = await UserAxiosInstance.get(
    `${settlementEndpoints.fetchSettlementMarkoffRequests}`
  )
  return response
}

export const fetchSettlementdetails = async (payload: {
  settlementId: number | undefined
}) => {
  const response = await UserAxiosInstance.get(
    `${settlementEndpoints.fetchSettlementdetails}/${payload.settlementId}`
  )
  return response
}

export const fetchTotalPayout = async (payload: {
  startDate: string
  endDate: string
}) => {
  const { startDate, endDate } = payload
  const queryParams = generateQueryString({ startDate, endDate })

  const endpoint = `${settlementEndpoints.fetchTotalPayout}${queryParams}`

  const response = await UserAxiosInstance.get(endpoint)
  return response
}

export const fetchTotalIncome = async (payload: {
  startDate: string
  endDate: string
}) => {
  const { startDate, endDate } = payload
  const queryParams = generateQueryString({ startDate, endDate })

  const endpoint = `${paymentEndpoints.fetchIncomeByDate}${queryParams}`

  const response = await UserAxiosInstance.get(endpoint)
  return response
}

export const fetchPayoutTransactions = async (payload: {
  currentPage: number
  pageSize: number
  search: string
}) => {
  const response = await UserAxiosInstance.get(
    `${settlementEndpoints.fetchPayoutTransactions}?page=${payload.currentPage}&size=${payload.pageSize}&${payload.search}`
  )
  return response
}
export const markoffSettlement = async (data: FormData) => {
  const response = await UserAxiosInstance.put(
    settlementEndpoints.markoffSettlement,
    data,
    {
      headers: {
        "Content-Type": "muiltipart/form-data",
        "Access-Control-Allow-Headers": "Accept",
      },
    }
  )
  return response
}
