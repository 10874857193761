import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg"
import Button from "components/button"
import { Dropdown } from "components/Dropdown/Dropdown"
import DatePicker from "react-datepicker"
import React, { useEffect, useState } from "react"
import {
  FieldValues,
  FormProvider,
  useForm,
  UseFormHandleSubmit,
  UseFormReset,
  UseFormReturn,
} from "react-hook-form"
import { customDateRanges } from "../constants/customDateRanges"
import { getDateRangeByRangeText } from "../util/getDateRangeByRangeText"
import {
  DATE_GROUPINGS,
  DateGrouping,
  dayGroupingToDateRangeType,
} from "constants/dashboard"

type Props = {
  setRangeType: React.Dispatch<React.SetStateAction<string>>
  startDate: Date | null
  endDate: Date | null
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>
  rangeType: string
  filter: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filterMethods: UseFormReturn<FieldValues, any>
    handleFilter: UseFormHandleSubmit<FieldValues>
    onFilter: (data: FieldValues) => void
    resetFilter: UseFormReset<FieldValues>
  }
  search: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    searchMethods: UseFormReturn<FieldValues, any>
    handleSearch: UseFormHandleSubmit<FieldValues>
    onSearch: (data: FieldValues) => void
  }
  updateDate: (
    startDate: Date | null,
    endDate: Date | null,
    dateGrouping: DateGrouping
  ) => void
}

const DashboardFilter: React.FC<Props> = ({
  updateDate,
  startDate,
  endDate,
  rangeType,
  setRangeType,
  setStartDate,
  setEndDate,
}) => {
  const methods = useForm()
  const [showFilter, setShowFilter] = React.useState(false)
  const today = new Date()
  const [internalStartDate, setInternalStartDate] = useState<Date | null>(
    startDate
  )
  const [internalEndDate, setInternalEndDate] = useState<Date | null>(endDate)

  const [dateGrouping, setDateGrouping] = useState<DateGrouping>(
    DATE_GROUPINGS.day
  )
  const onChange = (dates: [Date, Date | null]) => {
    const [start, end] = dates

    setRangeType("custom")
    setInternalStartDate(start)
    setInternalEndDate(end)
  }

  const toggleFilter = () => {
    setShowFilter(!showFilter)
  }

  const handleCustomDate = (selectedDateRange: DateGrouping) => {
    setRangeType(selectedDateRange)

    if (
      selectedDateRange === customDateRanges.custom ||
      selectedDateRange === "allTime"
    ) {
      setStartDate(null)
      setEndDate(null)
      setInternalStartDate(null)
      setInternalEndDate(null)
      return
    }

    const { newEndDate, newStartDate } = getDateRangeByRangeText(
      customDateRanges[selectedDateRange]
    )

    setInternalStartDate(newStartDate)
    setInternalEndDate(newEndDate)

    setDateGrouping(
      dayGroupingToDateRangeType(customDateRanges[selectedDateRange])
    )
  }

  const handleReset = () => {
    setRangeType("")
    setStartDate(new Date())
    setEndDate(new Date())
    setShowFilter(false)
  }

  const handleApply = () => {
    if (internalStartDate && internalEndDate) {
      updateDate(internalStartDate, internalEndDate, dateGrouping)
      setShowFilter(false)
    }
    if (rangeType === "allTime") {
      updateDate(null, null, DATE_GROUPINGS.year)
      setShowFilter(false)
    }
  }

  return (
    <>
      <Dropdown
        isOpen={showFilter}
        toggleOpen={toggleFilter}
        showCloseButton={false}
        showBackdrop={false}
        trigger={
          <Button
            variant="link"
            className="relative flex h-10 min-w-fit items-center justify-center gap-4 rounded-md bg-white px-3 text-base font-medium text-primary-default md:shadow-lg"
          >
            <EyeIcon className="h-6" />
            <span className=" w-max">
              {customDateRanges[rangeType] || "Filter"}
            </span>
            <span className=" w-5 text-gray-10/50">
              <FontAwesomeIcon icon={faAngleDown} />
            </span>
          </Button>
        }
        content={
          <>
            <div className="mb-2 px-2 text-center">
              {startDate?.toDateString() ?? ""}
              {startDate && endDate && "-"}
              {endDate?.toDateString() ?? ""}
            </div>
            <div className="flex gap-4">
              <div className="flex w-[7rem] flex-col justify-start">
                {Object.keys(customDateRanges).map((range, index) => (
                  <div
                    key={`custom-date-range-${index}`}
                    onClick={() => handleCustomDate(range)}
                    className={`cursor-pointer px-2 py-2 text-xs hover:bg-gray-20/90 ${
                      range === rangeType
                        ? "rounded bg-primary-default text-white"
                        : ""
                    }`}
                  >
                    {customDateRanges[range]}
                  </div>
                ))}
              </div>
              <div>
                <FormProvider {...methods}>
                  <DatePicker
                    selected={startDate}
                    onChange={onChange}
                    startDate={internalStartDate}
                    endDate={internalEndDate}
                    selectsRange
                    inline
                    maxDate={today}
                  />
                </FormProvider>
              </div>
            </div>
            <div className="mt-2 flex justify-between px-2">
              <Button
                onClick={handleReset}
                variant="outlineDefault"
                className="h-8 text-xs"
              >
                Cancel
              </Button>
              <Button
                // disabled={!internalStartDate || !internalEndDate}
                onClick={handleApply}
                className="h-8 text-xs"
              >
                Apply
              </Button>
            </div>
          </>
        }
      />
      {/* <FormProvider {...searchMethods}>
        <form onSubmit={handleSearch(onSearch)} autoComplete="off">
          <Input
            name="search"
            type="search"
            inputContainerProps={{ className: "md:w-[15rem]" }}
            placeholder="Search dashboard"
          />
        </form>
      </FormProvider> */}
    </>
  )
}

export default DashboardFilter
