const authEndpoints = {
  login: "admin-auth/api/v1/auth/login",
  validateOTP: "admin-auth/api/v1/auth/validate-otp",
  forgotPassword: "admin-auth/api/v1/auth/initiate-password-reset",
  resetPassword: "admin-auth/api/v1/auth/reset-password",
  regerateOtp: "admin-auth/api/v1/auth/regenerate-otp",
  refreshToken: "admin-auth/api/v1/security/refresh-token",
  validateAccessCode: "admin-auth/api/v1/auth/validate-access-code",
}

const kycEndpoints = {
  submittedKYCRequests: "admin-user/api/v1/merchant/submitted-kyc-requests",
  approvedKYCRequests: "admin-user/api/v1/merchant/approved-kyc-requests",
  rejectedKYCRequests: "admin-user/api/v1/merchant/rejected-kyc-requests",
  pendingKYCRequests: "admin-user/api/v1/merchant/pending-kyc-requests",
  requestKYCReview: "admin-user/api/v1/merchant/review-kyc",
  fetchKYCDocuments: "admin-user/api/v1/documents/all-document-data",
  fetchDocumentTypes: "admin-user/api/v1/documents/document-types",
  kycRequests: "admin-user/api/v1/merchant/kyc-requests",
  makerCheckerKYCRequests:
    "admin-user/api/v1/merchant/get-pending-kyc-approval-requests",
  pendingKYCCount: "admin-user/api/v1/merchant/pending-kyc-count",
}

const merchantEndpoints = {
  fetchMerchantData: "admin-user/api/v1/merchant/get-all-merchants",
  fetchMerchantDetails: "admin-user/api/v1/merchant/get-merchant",
  fetchMerchantUserCount: "admin-user/api/v1/merchant/user-count",
  fetchMerchantAdmin: "admin-user/api/v1/merchant/get-merchant-admin",
  fetchSettlementAccount: "admin-user/api/v1/merchant-bank/settlement-account",
  toggleMerchantStatus: "admin-user/api/v1/merchant/enable-disable-merchant",
  fetchMerchantTransactions: "admin-user/api/v1/transaction/all",
  fetchMerchantTransactionDetails: "admin-user/api/v1/transaction",
  fetchTotalTxValue: "admin-user/api/v1/transaction/all/total-value",
  fetchTotalSuccessfulTx:
    "admin-user/api/v1/transaction/all/successful-transactions/count",
  exportMerchantData: "admin-user/api/v1/merchant/get-all-merchants",
  fetchMerchantBvnDetails: "admin-user/api/v1/merchant/merchant-bvn",
  fetchMerchantApprovals:
    "admin-user/api/v1/merchant/get-pending-enable-or-disable-requests",
}

const paymentEndpoints = {
  fetchTransactions: "admin-user/api/v1/transactions/all",
  fetchTransactiondetails: "admin-user/api/v1/merchant/transaction",
  fetchTotalValueByDate:
    "admin-user/api/v1/transaction/all/total-value-by-date",
  fetchAllTxByDateCount: "admin-user/api/v1/transaction/all/count-by-date",
  fetchSuccessfulValuePoints:
    "admin-user/api/v1/transaction/all/successful-value-points-by-date",
  fetchSuccessfulByDateCount:
    "admin-user/api/v1/transaction/all/count-successful-by-date",
  fetchPendingByDateCount:
    "admin-user/api/v1/transaction/all/count-pending-by-date",
  fetchFailedByDateCount:
    "admin-user/api/v1/transaction/all/count-failed-by-date",
  fetchActiveMerchantsByDate:
    "admin-user/api/v1/transaction/all/active-merchants-by-date",
  fetchIncomeByDate:
    "admin-user/api/v1/transaction/all/total-income-value-by-date",
}

const settlementEndpoints = {
  fetchSettlements: "admin-user/api/v1/settlement/all",
  fetchSettlementMarkoffRequests:
    "admin-user/api/v1/merchant/get-pending-settlement-mark-off-requests",
  fetchSettlementdetails: "admin-user/api/v1/merchant/settlement",
  fetchPayoutTransactions: "admin-user/api/v1/settlement/transactions",
  fetchPendingByDateCount:
    "admin-user/api/v1/settlement/all/count-pending-by-date",
  exportSettlements: "admin-user/api/v1/settlement/all/export",
  markoffSettlement: "admin-user/api/v1/settlement/mark-off",
  fetchTotalPayout: "admin-user/api/v1/settlement/all/total-payout",
}

const transactionEndpoints = {
  fetchTransactions: "admin-user/api/v1/transaction/all",
  fetchTransactiondetails: "admin-user/api/v1/transaction",
  exportTransactions: "admin-user/api/v1/transaction/all/export",
}

const auditEndpoints = {
  fetchAudit: "admin-user/api/v1/audit-log/all",
  downloadAudit: "admin-user/api/v1/audit-log/all/export",
}

const disputeEndpoints = {
  fetchDispute: "admin-user/api/v1/dispute/all",
  fetchDisputeDetails: "admin-user/api/v1/dispute",
  resolveDispute: "admin-user/api/v1/dispute/resolve",
}

const userEndpoints = {
  fetchUserData: "admin-auth/api/v1/user-management/user-accounts",
  updateUserStatus: "admin-auth/api/v1/user-management/activate",
  fetchAdminPermissions: "admin-auth/api/v1/roles/admin-permissions",
  fetchMerchantPermissions: "admin-auth/api/v1/roles/merchant-permissions",
  createAdminRole: "admin-auth/api/v1/roles/admin-role",
  createMerchantRole: "admin-auth/api/v1/roles/merchant-role",
  editAdminRole: "admin-auth/api/v1/roles/admin-role",
  editMerchantRole: "admin-auth/api/v1/roles/merchant-role",
  fetchAdminRoles: "admin-auth/api/v1/roles/admin-roles",
  fetchMerchantRoles: "admin-auth/api/v1/roles/merchant-roles",
  createUser: "admin-auth/api/v1/user-management/user-account",
  editUser: "admin-auth/api/v1/user-management/user-account",
}

const configEndpoints = {
  fetchChannels: "admin-user/api/v1/configuration/payment-channels",
  createChannel: "admin-user/api/v1/configuration/payment-channel",
  updateChannelStatus:
    "admin-user/api/v1/configuration/payment-channel/activate",
  fetchFeeSettings: "admin-user/api/v1/configuration/payment-channel/fee-types",
  fetchSettlement: "admin-user/api/v1/configuration/settlement",
  createSettlement: "admin-user/api/v1/configuration/settlement",
  updateSettlement: "admin-user/api/v1/configuration/settlement",
  fetchEmailSettings: "admin-user/api/v1/configuration/email",
  createEmailSettings: "admin-user/api/v1/configuration/email",
  updateEmailSettings: "admin-user/api/v1/configuration/email",
}

const workflowEndpoints = {
  createWorkflow: "maker-checker/api/v1/mc/create-workflow",
  fetchWorkflow: "maker-checker/api/v1/mc/get-client-workflows",
  createWorkflowLevels:
    "maker-checker/api/v1/mc/create-workflow-authorization-level",
  editWorkflow: "maker-checker/api/v1/mc/edit-workflow",
  fetchWorkflowConfig:
    "maker-checker/api/v1/mc/get-workflow-authorization-levels",
  approveRequest: "maker-checker/api/v1/mc/approve-request",
  declineRequest: "maker-checker/api/v1/mc/decline-request",
  updateWorkflowAuthorizers:
    "maker-checker/api/v1/mc/update-workflow-authorizers",
}

const OTPEndpoints = {
  generateOTP: "admin-user/api/v1/otp",
  validateOTP: "admin-user/api/v1/otp/verify",
}

export {
  authEndpoints,
  kycEndpoints,
  merchantEndpoints,
  paymentEndpoints,
  settlementEndpoints,
  transactionEndpoints,
  userEndpoints,
  configEndpoints,
  auditEndpoints,
  disputeEndpoints,
  workflowEndpoints,
  OTPEndpoints,
}
