import { ReactComponent as DownloadIcon } from "assets/icons/download.svg"
import Button from "components/button"
import BackArrow from "components/button/BackArrow"
import { DataRow } from "components/DataRow/DataRow"
import Status from "components/Status/Status"
import { queryKeys } from "constants/queryKeys"
import TopBar from "layout/protected/ContentArea/TopBar"
import React from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import useTransaction from "../hooks/useTransaction"
import { numberUtils } from "utils/numberUtils"
import { savePDF } from "@progress/kendo-react-pdf"
import DetailsPDF from "./DetailsPDF"

const TransactionDetails: React.FC = () => {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const transactionId = searchParams.get("transactionId")
  const merchantId = searchParams.get("merchantId")

  const { transactionDetails } = useTransaction({
    queryKey: queryKeys.transactions.fetchTransactionDetails,
    transactionId,
    merchantId,
  })
  const { data, loadingDetails } = transactionDetails

  const personalData = {
    "Transaction Reference": data?.tgipayReference,
    "Payment Channel": data?.paymentChannel,
    "Payment Processor": data?.processor,
    "Merchant ID ": data?.merchantId,
    "Merchant Details": data?.merchantName,
    "Customer Details":
      data && data?.customerFirstName + " " + data?.customerLastName,
    Amount: numberUtils.formatCustomAmount(data?.amount, "NGN"),
    "TGI Pay Fee": numberUtils.formatCustomAmount(data?.fee, "NGN"),
    "Transaction Date": data?.transactionDate,
    Status: <Status variant={data?.status}>{data?.status}</Status>,
  }

  const container = React.useRef(null)

  const exportPDFWithMethod = () => {
    const element = container.current || document.body
    savePDF(element, {
      paperSize: "auto",
      margin: 40,
      fileName: `Transaction Details`,
    })
  }

  return (
    <>
      <TopBar pageTitle="" />

      <div className="  bg-white">
        <div className="flex justify-between border-b border-b-gray-10/20 p-6">
          <div className="">
            <div className="text-base font-medium">Transaction Details</div>
            <div className="text-sm text-gray-10/60">
              Details for transaction {data?.tgipayReference ?? " "}
            </div>
          </div>
          <div>
            <Button
              onClick={exportPDFWithMethod}
              variant="linkPrimary"
              className="flex items-center gap-2"
            >
              <span>Download PDF </span>
              <DownloadIcon />
            </Button>
          </div>
        </div>
        <div className="flex min-h-352 max-w-4xl flex-col gap-8 p-6">
          {Object.entries(personalData).map(([label, value]) => (
            <DataRow
              key={label}
              isLoading={loadingDetails}
              label={label}
              value={value}
            />
          ))}
          <BackArrow
            onClick={() => navigate(-1)}
            label="Return to Transactions"
          />
        </div>
        <DetailsPDF forwardedRef={container} />
      </div>
    </>
  )
}
export default TransactionDetails
