import React, { useState } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import ActionDropdown from "./ActionDropdown"
import EditEmailDialog from "../EditEmail"
const columnHelper = createColumnHelper<EmailSettingsType>()

export const EmailColumns = [
  columnHelper.accessor("description", {
    cell: (info) => info.getValue(),
    header: () => <span>Purpose</span>,
  }),
  columnHelper.accessor((row) => row.email, {
    id: "emailAddress",
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <span>Email Address</span>,
  }),
  columnHelper.accessor((row) => row.lastUpdated, {
    id: "lastUpdated",
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <span>Last Updated</span>,
  }),
  columnHelper.accessor("action", {
    header: "Action",
    cell: (info) => {
      const {
        row: { original: originalData },
      } = info.cell

      const [showEditDialog, setShowEditDialog] = useState(false)
      const [showActions, setShowActions] = useState(false)

      return (
        <React.Fragment>
          <ActionDropdown
            isOpen={showActions}
            toggleOpen={() => {
              setShowActions(!showActions)
            }}
            emailSetting={originalData}
            setShowEditDialog={setShowEditDialog}
          />

          {showEditDialog ? (
            <EditEmailDialog
              handleClose={() => setShowEditDialog(false)}
              isOpen={showEditDialog}
              selectedEmail={originalData}
            />
          ) : null}
        </React.Fragment>
      )
    },
  }),
]
