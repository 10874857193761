import { yupResolver } from "@hookform/resolvers/yup"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useLocation, useNavigate } from "react-router-dom"
import Button from "components/button"
import Input from "components/input"
import { PublicLayout } from "layout"
import { loginSchema } from "../login/validation-schemas"
import { useLogin } from "hooks/useLogin"

const ExpiredSession = () => {
  const navigate = useNavigate()

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const redirectUrl = queryParams.get("redirectUrl")

  const { methods, handleSubmit, onSubmit, isLoading } = useLogin(redirectUrl)

  return (
    <PublicLayout
      mainTitle="Your session has expired"
      subTitle="Your session has expired. Please log in again."
      contentArea={
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Input label="Username" name="email" />

            <Input
              label="Password"
              type="password"
              name="tgip_secret"
              inputContainerProps={{ className: "mt-8" }}
            />
            <div className="mt-8 flex items-center justify-end">
              <span
                className="cursor-pointer text-[0.875rem] font-medium text-primary-default"
                onClick={() => navigate("/forgot-password")}
              >
                Forgot Password?
              </span>
            </div>
            <Button
              loading={isLoading}
              className="mt-8 w-full"
              variant="primary"
            >
              Log in
            </Button>
          </form>
        </FormProvider>
      }
    />
  )
}

export default ExpiredSession
