import React from "react"
import { DataRow } from "../../../../../components/DataRow/DataRow"
import { ReactComponent as DownloadIcon } from "assets/icons/download.svg"
import useBreadcrumb from "hooks/useBreadcrumb"
import { numberUtils } from "utils/numberUtils"
import { savePDF } from "@progress/kendo-react-pdf"
import Button from "components/button"
import TransactionDetailsPDF from "./TransactionDetailsPDF"

type Props = {
  merchantId: number | undefined
  transactionDetails: TransactionDetails
}
const TransactionDetails: React.FC<Props> = ({ transactionDetails }) => {
  useBreadcrumb([
    "Merchants",
    transactionDetails.customerName,
    transactionDetails.tgipayReference,
  ])

  const container = React.useRef(null)

  const exportPDFWithMethod = () => {
    const element = container.current || document.body
    savePDF(element, {
      paperSize: "auto",
      margin: 40,
      fileName: `Transaction Details`,
    })
  }

  return (
    <>
      <div className="mt-12 flex flex-col gap-8">
        <div className="flex justify-between">
          <div>
            <div className="text-base font-medium">Transaction Details</div>
            <div className="text-sm font-normal text-gray-10/60">
              Details for transaction {transactionDetails?.tgipayReference}
            </div>
          </div>
          <Button
            onClick={exportPDFWithMethod}
            className="flex items-center gap-2"
            variant="linkPrimary"
          >
            <span>Download PDF</span>
            <DownloadIcon />
          </Button>
        </div>

        <DataRow
          label="Transaction Reference"
          value={transactionDetails?.tgipayReference}
        />
        <DataRow
          label="Payment Channel"
          value={transactionDetails.paymentChannel}
        />
        <DataRow
          label="Payment Processor"
          value={transactionDetails.processor}
        />
        <DataRow
          label="Amount"
          value={numberUtils.formatNumber(transactionDetails.amount)}
        />
        <DataRow
          label="Transaction Date"
          value={transactionDetails.transactionDate}
        />
        <DataRow label="Status" value={transactionDetails.status} />
        <DataRow
          label="TGI PAY Fee"
          value={numberUtils.formatNumber(transactionDetails.fee)}
        />
        <DataRow
          label="Merchant Income"
          value={numberUtils.formatNumber(transactionDetails.income)}
        />
        <DataRow label="Transaction Narration" value="n/a" />
      </div>
      <TransactionDetailsPDF
        forwardedRef={container}
        transactionDetails={transactionDetails}
      />
    </>
  )
}

export default TransactionDetails
