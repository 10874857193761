import React, { useState } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import Modal from "components/modal/Modal"
import Status from "components/Status/Status"
import { MerchantTransaction } from "types/merchants"
import { numberUtils } from "utils/numberUtils"

const columnHelper = createColumnHelper<MerchantTransaction>()

export const merchantTransCols = [
  columnHelper.accessor("merchantId", {
    cell: (info) => info.getValue(),
    header: () => <span>Merchant ID</span>,
  }),
  columnHelper.accessor("merchantName", {
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <span>Business Name</span>,
  }),
  columnHelper.accessor("income", {
    header: () => "Payout (NGN)",
    cell: (info) => <span>{numberUtils.formatNumber(info.getValue())}</span>,
  }),
  columnHelper.accessor("processor", {
    header: () => <span>Bank</span>,
  }),
  columnHelper.accessor("transactionDate", {
    header: "Date",
  }),
  columnHelper.accessor("status", {
    header: "Settlement Status",
    cell: (info) => (
      <Status variant={info.getValue()}>{info.getValue()}</Status>
    ),
  }),
  columnHelper.accessor("action", {
    header: "Action",
    cell: () => {
      const [showModal, setShowModal] = useState(false)
      function closeModal() {
        setShowModal(false)
      }
      return (
        <>
          <span
            onClick={() => {
              // console.log("clicked", cell.row.original)
            }}
            className="cursor-pointer font-semibold text-primary-default"
          >
            View
          </span>
          <Modal isOpen={showModal} closeModal={closeModal}>
            <div className="p-6">What do you want to put here?</div>
          </Modal>
        </>
      )
    },
  }),
]
