import { createSlice } from "@reduxjs/toolkit"

interface BreadcrumbState {
  breadcrumbData: string[]
  isSidebarOpen: boolean
}

const initialState: BreadcrumbState = {
  breadcrumbData: [],
  isSidebarOpen: false,
}

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setBreadcrumbData: (state, action) => {
      state.breadcrumbData = action.payload
    },
    toggleMobileSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen
    },
  },
})

export const { setBreadcrumbData, toggleMobileSidebar } = uiSlice.actions

export default uiSlice.reducer
