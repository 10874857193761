import { AxiosError } from "axios"
import Button from "components/button"
import Input from "components/input"
import TextArea from "components/input/TextArea"
import Modal from "components/modal/Modal"
import { queryKeys } from "constants/queryKeys"
import { queryClient } from "index"
import React from "react"
import { FieldValues, FormProvider, useForm } from "react-hook-form"
import Skeleton from "react-loading-skeleton"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { createMerchantRole } from "services/api/users"
import { handleError } from "utils/getAxiosErrorMessage"
import usePermissions from "../hooks/usePermissions"
import { yupResolver } from "@hookform/resolvers/yup"
import { createRoleSchema } from "../validation-schemas/role"

const CreateRole: React.FC<{
  isOpen: boolean
  handleClose: () => void
}> = ({ isOpen, handleClose }) => {
  const methods = useForm({
    resolver: yupResolver(createRoleSchema),
  })
  const [selectedPermissions, setSelectedPermissions] = React.useState<
    Permission[]
  >([])
  const { merchantPermissions, loadingMerchantPermissions } = usePermissions({
    queryKey: queryKeys.users.fetchMerchantPermissions,
  })

  const { mutate: handleCreateMerchantRole, isLoading: creatingMerchantRole } =
    useMutation(createMerchantRole, {
      onSuccess: (response) => {
        toast.success(response?.data?.message)
        queryClient.invalidateQueries(queryKeys.users.fetchMerchantRoles)
        handleClose()
      },
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    })

  const onCreate = (data: FieldValues) => {
    const payload = {
      id: 0,
      businessId: 0,
      name: data.roleName,
      description: data.roleDescription,
      permissions: selectedPermissions,
    }
    if (selectedPermissions?.length > 0) {
      handleCreateMerchantRole(payload)
    } else {
      toast.error("Please select at least one permission")
    }
  }

  return (
    <Modal
      contentContainerClass="p-6 max-w-sm"
      isOpen={isOpen}
      closeModal={handleClose}
    >
      <div className="mb-6">
        <h5 className="text-lg font-medium">Create Merchant Role</h5>
        <div className="text-xs font-normal">
          Provide details of the new role.
        </div>
      </div>

      <FormProvider {...methods}>
        <form
          className="flex flex-col gap-4"
          onSubmit={methods.handleSubmit(onCreate)}
        >
          <Input label="Role Name" name="roleName" />
          <TextArea label="Role Description" name="roleDescription" />

          <div className="mt-2">
            Role Permissions ({selectedPermissions?.length} of{" "}
            {merchantPermissions?.length})
          </div>

          {loadingMerchantPermissions ? <Skeleton /> : null}

          {merchantPermissions?.map((permission) => (
            <div
              className="flex gap-4 pt-0"
              key={`permission-${permission.id}`}
            >
              <Input
                type="checkbox"
                name="role"
                checkBoxProps={{ className: "bg-gray-20/50 border-none" }}
                checkMarkProps={{ fill: "#292727" }}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedPermissions((prev) => [...prev, permission])
                  } else {
                    setSelectedPermissions((prev) =>
                      prev.filter((p) => p.id !== permission.id)
                    )
                  }
                }}
                checked={selectedPermissions.some(
                  (p) => p.id === permission.id
                )}
              />
              <span>{permission.name}</span>
            </div>
          ))}

          <div className="mt-6 flex justify-between">
            <Button onClick={handleClose} variant="outlinePrimary">
              Cancel
            </Button>
            <Button loading={creatingMerchantRole}>Create New Role</Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default CreateRole
