import { AxiosError } from "axios"
import { toast } from "react-toastify"
import Button from "components/button"
import BackArrow from "components/button/BackArrow"
import Input from "components/input"
import { queryKeys } from "constants/queryKeys"
import { queryClient } from "index"
import TopBar from "layout/protected/ContentArea/TopBar"
import React from "react"
import { FieldValues, FormProvider, useForm } from "react-hook-form"
import { useMutation } from "react-query"
import { useNavigate, useSearchParams } from "react-router-dom"
import { createEmail } from "services/api/configuration"
import { handleError } from "utils/getAxiosErrorMessage"

const CreateEmail: React.FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const tabIndex = searchParams.get("tabIndex")
  const methods = useForm()

  const { mutate: handleCreateEmail, isLoading: editingChannel } = useMutation(
    createEmail,
    {
      onSuccess: (response) => {
        toast.success(response?.data?.message)
        queryClient.invalidateQueries(
          queryKeys.configuration.fetchEmailSettings
        )
      },
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    }
  )

  const onSubmit = (data: FieldValues) => {
    const payload = {
      email: data.email,
      description: data.description,
    }
    handleCreateEmail(payload)
  }

  return (
    <div>
      <TopBar
        pageTitle="Create Email"
        subTitle="Manage settings of merchant emails."
      />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="mt-8 w-full max-w-[65.25rem] rounded-lg bg-white p-7 lg:w-[60%] 2xl:max-w-full">
          <FormProvider {...methods}>
            <div className="flex flex-col gap-4">
              <Input label="Email" name="email" type="email" />
              <Input label="Purpose" name="description" />
            </div>
          </FormProvider>
        </div>
        <div className="flex w-full justify-end gap-6 lg:w-[60%]">
          <Button className="ml-4 mt-6" variant="outlinePrimary">
            Cancel
          </Button>
          <Button className="mt-6" loading={editingChannel}>
            Create Email
          </Button>
        </div>
        <BackArrow
          onClick={() => navigate(`/configuration?tabIndex=${tabIndex}`)}
          label="Return to Configuration Page"
          className="mt-6"
        />
      </form>
    </div>
  )
}

export default CreateEmail
