import React from "react"
import Modal from "components/modal/Modal"
import { ReactComponent as ExclamationWarning } from "assets/icons/merchants/exclamation-warning.svg"
import Button from "components/button"
import { useMutation } from "react-query"
import { toggleMerchantStatus } from "services/api/merchants"
import { AxiosError } from "axios"
import { toast } from "react-toastify"
import { handleError } from "utils/getAxiosErrorMessage"
import { queryClient } from "index"
import { queryKeys } from "constants/queryKeys"

const ActivateDialog: React.FC<{
  isOpen: boolean
  closeModal: () => void
  merchantId: string | null
  merchantName: string | undefined
}> = ({ isOpen, closeModal, merchantId, merchantName }) => {
  const { mutate: enableMerchant, isLoading } = useMutation(
    toggleMerchantStatus,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(queryKeys.merchant.fetchMerchantDetails)
        toast.success(response.data.message)
        closeModal()
      },
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    }
  )

  return (
    <Modal closeModal={closeModal} isOpen={isOpen}>
      <div className="flex flex-col items-center p-6">
        <div className="flex max-w-xs flex-col items-center">
          <ExclamationWarning className="mb-5" />
          <h5 className="text-xl font-semibold ">Enable Merchant?</h5>
          <span className="mt-4 text-sm font-normal">
            Are you sure you want to enable{" "}
            <span className="font-medium">{merchantName}</span>?
          </span>
          <div className="mt-8 flex w-full max-w-xs justify-between">
            <Button
              onClick={closeModal}
              variant="default"
              className="h-9 w-24 text-sm"
            >
              Cancel
            </Button>
            <Button
              loading={isLoading}
              onClick={() =>
                enableMerchant({ merchantId, enable: true, reason: "" })
              }
              className="h-9 w-24 text-sm"
            >
              Proceed
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ActivateDialog
