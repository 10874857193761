import React from "react"
import ChannelTable from "./Table/Table"
import useConfiguration from "../../hooks/useConfiguration"
import { queryKeys } from "constants/queryKeys"

type Props = {
  tabIndex: number
}
const Channels: React.FC<Props> = () => {
  const { data, loading } = useConfiguration(
    queryKeys.configuration.fetchChannels
  )

  return (
    <div>
      <div>
        <ChannelTable
          channelData={data.channelData}
          loading={loading.loadingChannels}
        />
      </div>
    </div>
  )
}

export default Channels
