import { AxiosError } from "axios"
import CustomSelect from "components/Select/CustomSelect"
import Button from "components/button"
import Input from "components/input"
import TextArea from "components/input/TextArea"
import Modal from "components/modal/Modal"
import { CURRENCIES, getCurrencyByCode } from "constants/currencies"
import { queryKeys } from "constants/queryKeys"
import { queryClient } from "index"
import React, { useEffect } from "react"
import { FieldValues, FormProvider, useForm } from "react-hook-form"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { editChannel } from "services/api/configuration"
import { handleError } from "utils/getAxiosErrorMessage"

const EditChannelDialog: React.FC<{
  isOpen: boolean
  handleClose: () => void
  selectedChannel: ChannelType
}> = ({ isOpen, handleClose, selectedChannel }) => {
  const methods = useForm()

  useEffect(() => {
    if (selectedChannel) {
      methods.reset({
        channelName: selectedChannel?.name,
        feeType: selectedChannel?.feeType,
        flatFee: selectedChannel?.flatFee,
        feePercentage: selectedChannel?.feePercentage,
        feeCap: selectedChannel?.feeCap,
        currency: getCurrencyByCode(selectedChannel?.currency),
      })
    }
  }, [selectedChannel])

  const { mutate: handleEditChannel, isLoading: editingChannel } = useMutation(
    editChannel,
    {
      onSuccess: (response) => {
        toast.success(response?.data?.message)
        queryClient.invalidateQueries(queryKeys.configuration.fetchChannels)
        handleClose()
      },
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    }
  )

  const onSubmit = (data: FieldValues) => {
    const payload = {
      id: selectedChannel?.id,
      name: selectedChannel?.name,
      feeType: selectedChannel?.feeType,
      flatFee: Number(data?.flatFee),
      feePercentage: Number(data?.feePercentage),
      feeCap: Number(data?.feeCap),
      currency: data?.currency.value,
    }
    handleEditChannel(payload)
  }

  return (
    <Modal
      contentContainerClass="p-6 max-w-xl"
      isOpen={isOpen}
      closeModal={handleClose}
    >
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="mb-6">
          <h5 className="text-lg font-medium">Edit Channel</h5>
          <div className="text-xs font-normal">Edit TGIP payment channel.</div>
        </div>

        <FormProvider {...methods}>
          <div className="flex flex-col gap-4">
            <Input label="Channel Name" name="channelName" readOnly disabled />
            <Input label="Flat Fee" name="flatFee" type="number" />
            <Input
              label="Fee Percentage"
              name="feePercentage"
              type="text"
              pattern="[0-9]+(\.[0-9]{1,9})?"
              title="Please enter a valid decimal number (e.g., 1.6)"
            />
            <Input label="Fee Cap" name="feeCap" type="number" />
            <TextArea label="Description(Optional)" name="feeType" rows={5} />
            <CustomSelect
              label="Currency"
              name="currency"
              options={CURRENCIES}
              isDisabled
            />
          </div>
        </FormProvider>
        <div className="flex justify-end gap-6 ">
          <Button
            onClick={handleClose}
            className="ml-4 mt-6"
            variant="outlinePrimary"
          >
            Cancel
          </Button>
          <Button className="mt-6" loading={editingChannel}>
            Edit Channel
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default EditChannelDialog
