import Modal from "components/modal/Modal"
import Button from "components/button"
import OTPField from "components/input/OTPField"

import React, { FC, useState } from "react"
import { useOTP } from "hooks/useOTP"
import { useAuthUser } from "hooks/useAuthUser"
import { useMutation } from "react-query"
import { queryClient } from "index"
import { queryKeys } from "constants/queryKeys"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { handleError } from "utils/getAxiosErrorMessage"
import env from "config/env"
import {
  approveWorkflowRequest,
  declineWorkflowRequest,
} from "services/api/workflow"

type Props = {
  operation: "approve" | "decline"
  isOpen: boolean
  closeTwoFactor: () => void
  settlementData: SettlementApprovalRequestType
}

const ApprovalTwoFactor: FC<Props> = ({
  isOpen,
  closeTwoFactor,
  settlementData,
  operation,
}) => {
  const { otp } = useOTP()
  const { authUser } = useAuthUser()
  const [error, setError] = useState<string>("")

  const OTPFieldValue = (value: string[]) => {
    otp.setInputedOTP(value.join(""))
  }

  const { mutate: handleRequestApproval, isLoading: isApproving } = useMutation(
    approveWorkflowRequest,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(
          queryKeys.settlement.fetchSettlementApprovals
        )
        toast.success(response.data.message)
        closeTwoFactor()
      },
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    }
  )

  const { mutate: handleRequestDecline, isLoading: isDeclining } = useMutation(
    declineWorkflowRequest,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(
          queryKeys.settlement.fetchSettlementApprovals
        )
        toast.success(response.data.message)
        closeTwoFactor()
      },
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    }
  )

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (otp.inputedOTP?.length !== 6) {
      return setError("Please enter complete otp to continue")
    }

    if (authUser?.email) {
      otp.validate.handleOTPValidation(
        {
          email: authUser.email,
          otp: Number(otp.inputedOTP),
        },
        {
          onSuccess: () => {
            if (operation === "approve") {
              handleRequestApproval({
                approvalRequestCode: settlementData.approvalRequestCode,
                workFlowCode: settlementData.workFlowCode,
                clientAppCode: env.WORKFLOW_CLIENT_CODE as string,
              })
            }
            if (operation === "decline") {
              handleRequestDecline({
                approvalRequestCode: settlementData.approvalRequestCode,
                workFlowCode: settlementData.workFlowCode,
                clientAppCode: env.WORKFLOW_CLIENT_CODE as string,
                reason: "Declined by user",
              })
            }
          },
        }
      )
    }
  }

  const handleClose = () => {
    closeTwoFactor()
  }

  return (
    <Modal isOpen={isOpen} closeModal={handleClose}>
      <form onSubmit={handleSubmit} className="px-4 py-10">
        <div className="mb-8 text-center">
          Enter the authentication code sent to your email below to continue.
        </div>
        <OTPField
          fieldCount={6}
          onChange={(otp: string[]) => {
            OTPFieldValue(otp)
            setError("")
          }}
        />
        {error && (
          <div className="mt-2 text-center text-danger-default">{error}</div>
        )}
        <Button loading={isApproving || isDeclining} className="mt-8 w-full">
          Submit
        </Button>
      </form>
    </Modal>
  )
}

export default ApprovalTwoFactor
