import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { PublicLayout } from "layout"
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons"
import Button from "components/button"
import { useNavigate } from "react-router-dom"
const ResetSuccess: React.FC = () => {
  const navigate = useNavigate()
  return (
    <PublicLayout
      mainTitle="Reset Password"
      contentArea={
        <div className="flex flex-col items-center">
          <div className="text-[3.5rem] text-success-default xl:text-6xl">
            <FontAwesomeIcon
              icon={faCircleCheck}
              className="fill-success-default"
            />
          </div>
          <h6 className="mt-6 text-xl font-semibold xl:mt-9">
            Password Reset Successfully
          </h6>
          <p className="mt-3">You can now log in with your new password.</p>

          <Button className="mt-8 w-full" onClick={() => navigate("/")}>
            Go to Login
          </Button>
        </div>
      }
    />
  )
}

export default ResetSuccess
