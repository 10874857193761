import React from "react"
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts"
import { numberUtils } from "utils/numberUtils"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomTick = ({ x, y, payload }: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={2} dx={-16} textAnchor="middle" fill="#000" fontSize={12}>
        {numberUtils.formatNumber(payload.value, "en-ng", {
          notation: "compact",
          compactDisplay: "short",
          maximumFractionDigits: 2,
        })}
      </text>
    </g>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomLineChart: React.FC<{ data: any }> = ({ data }) => {
  if (!data || data.length === 0)
    return (
      <div className="h-[20rem] min-h-[20rem] w-full">
        <div className="flex h-full items-center justify-center">
          <p className="text-gray-20">No data available</p>
        </div>
      </div>
    )
  return (
    <div className="h-[20rem] min-h-[20rem] w-full">
      <ResponsiveContainer>
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 5,
            left: 5,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" strokeWidth={0} />
          <XAxis dataKey="dateTime" tickMargin={10} tick={{ fontSize: 12 }} />
          <YAxis tickMargin={10} tick={<CustomTick />} />
          <Tooltip />
          <Line
            type="monotone"
            dataKey="totalAmount"
            stroke="#204D88"
            activeDot={{ r: 8 }}
            strokeWidth={2}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}
