import { UserAxiosInstance } from "../axios/userApi"
import { transactionEndpoints } from "../../constants/endpoints"

export const fetchTransactions = async (payload: {
  currentPage: number
  pageSize: number
  search: string
}) => {
  const response = await UserAxiosInstance.get(
    `${transactionEndpoints.fetchTransactions}?page=${payload.currentPage}&size=${payload.pageSize}&${payload.search}`
  )
  return response
}

export const fetchTransactiondetails = async (payload: {
  transactionId: string | null | undefined
  merchantId: string | null | undefined
}) => {
  const response = await UserAxiosInstance.get(
    `${transactionEndpoints.fetchTransactiondetails}/${payload.merchantId}/${payload.transactionId}`
  )
  return response
}
