import Modal from "components/modal/Modal"
import Button from "components/button"
import OTPField from "components/input/OTPField"

import React, { FC, useState } from "react"
import { useOTP } from "hooks/useOTP"
import { useAuthUser } from "hooks/useAuthUser"
import { useMutation } from "react-query"
import { queryClient } from "index"
import { queryKeys } from "constants/queryKeys"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { handleError } from "utils/getAxiosErrorMessage"
import { KYC } from "types/KYC"
import env from "config/env"
import { approveWorkflowRequest } from "services/api/workflow"

type Props = {
  isOpen: boolean
  closeTwoFactor: () => void
  kycDetails: KYC
}

const ApprovalTwoFactor: FC<Props> = ({
  isOpen,
  closeTwoFactor,
  kycDetails,
}) => {
  const { otp } = useOTP()
  const { authUser } = useAuthUser()
  const [error, setError] = useState<string>("")

  const navigate = useNavigate()

  const OTPFieldValue = (value: string[]) => {
    otp.setInputedOTP(value.join(""))
  }

  const { mutate: handleKYCApproval, isLoading: isApproving } = useMutation(
    approveWorkflowRequest,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(queryKeys.kyc.fetchPendingKYC)
        toast.success(response.data.message)
        navigate("/kyc")
      },
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    }
  )

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (otp.inputedOTP?.length !== 6) {
      return setError("Please enter complete otp to continue")
    }

    if (authUser?.email) {
      otp.validate.handleOTPValidation(
        {
          email: authUser.email,
          otp: Number(otp.inputedOTP),
        },
        {
          onSuccess: () => {
            handleKYCApproval({
              approvalRequestCode: kycDetails.approvalRequestCode,
              workFlowCode: kycDetails.workFlowCode,
              clientAppCode: env.WORKFLOW_CLIENT_CODE as string,
            })
          },
        }
      )
    }
  }

  const handleClose = () => {
    closeTwoFactor()
  }

  return (
    <Modal isOpen={isOpen} closeModal={handleClose}>
      <form onSubmit={handleSubmit} className="px-4 py-10">
        <div className="mb-8 text-center">
          Enter the authentication code sent to your email below to continue.
        </div>
        <OTPField
          fieldCount={6}
          onChange={(otp: string[]) => {
            OTPFieldValue(otp)
            setError("")
          }}
        />
        {error && (
          <div className="mt-2 text-center text-danger-default">{error}</div>
        )}
        <Button loading={isApproving} className="mt-8 w-full">
          Submit
        </Button>
      </form>
    </Modal>
  )
}

export default ApprovalTwoFactor
