import React, { useState } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import ActionDropdown from "./ActionDropdown"
import EditSettlementDialog from "../EditSettlement"
import { numberUtils } from "utils/numberUtils"
const columnHelper = createColumnHelper<SettlementType>()

export const SettlementColumns = [
  columnHelper.accessor("type", {
    cell: (info) => info.getValue(),
    header: () => <span>Fee Type</span>,
  }),
  columnHelper.accessor((row) => row.name, {
    id: "name",
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <span>Name</span>,
  }),
  columnHelper.accessor("startTime", {
    header: "Start Time",
  }),
  columnHelper.accessor("maximumAmount", {
    header: "Max Per Batch",
    cell: (info) => (
      <span>{numberUtils.formatNumber(Number(info.getValue()))}</span>
    ),
  }),
  columnHelper.accessor("action", {
    header: "Action",
    cell: (info) => {
      const {
        row: { original: originalData },
      } = info.cell

      const [showActions, setShowActions] = useState(false)
      const [showEditDialog, setShowEditDialog] = useState(false)

      return (
        <React.Fragment>
          <ActionDropdown
            isOpen={showActions}
            toggleOpen={() => {
              setShowActions(!showActions)
            }}
            settlement={originalData}
            setShowEditDialog={setShowEditDialog}
          />
          {showEditDialog ? (
            <EditSettlementDialog
              handleClose={() => setShowEditDialog(false)}
              isOpen={showEditDialog}
              selectedSettlement={originalData}
            />
          ) : null}
        </React.Fragment>
      )
    },
  }),
]
