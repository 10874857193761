import React from "react"
import Skeleton from "react-loading-skeleton"

const ActivitySkeleton = () => {
  return (
    <li className="flex gap-6 text-xs">
      <div className="w-12">
        <Skeleton />
      </div>
      <div className="flex-1">
        <Skeleton />
      </div>
    </li>
  )
}

export default ActivitySkeleton
