import { faCircleCheck, faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { PublicLayout } from "layout"
import React, { useEffect } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

const ResetInfo: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const email = searchParams.get("email")

  useEffect(() => {
    if (!email) {
      navigate("/")
    }
  }, [email])

  return (
    <PublicLayout
      mainTitle="Reset Password"
      contentArea={
        <div className="flex flex-col items-center">
          <div className="text-[3.5rem] text-primary-default xl:text-[4.1rem]">
            <FontAwesomeIcon
              icon={faCircleCheck}
              className="fill-primary-default"
            />
          </div>
          <h6 className="mt-[1.6rem] text-xl font-semibold xl:mt-[2.2rem]">
            Email Sent
          </h6>
          <p className="mt-[0.625rem] text-center">
            An email has been sent to <span className="font-bold">{email}</span>
            . Kindly check to see information on how to proceed
          </p>
        </div>
      }
    >
      <div
        className="mt-8 flex cursor-pointer items-center gap-2 font-medium text-primary-default"
        onClick={() => navigate("/")}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
        <span>Return to Log in</span>
      </div>
    </PublicLayout>
  )
}

export default ResetInfo
