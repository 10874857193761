import BackArrow from "components/button/BackArrow"
import React, { useState } from "react"
import ManageAdminRoles from "./ManageAdminRoles"
import ManageUsers from "../ManageUsers/ManageUsers"

type Props = {
  handleBack: () => void
}
const AdminUserRoles: React.FC<Props> = ({ handleBack }) => {
  const [showRoleMgtUI, setshowRoleMgtUI] = useState(false)

  return (
    <div className="flex min-h-[90vh] flex-col">
      {showRoleMgtUI ? (
        <ManageAdminRoles setshowRoleMgtUI={setshowRoleMgtUI} />
      ) : (
        <>
          <ManageUsers setshowRoleMgtUI={setshowRoleMgtUI} />
          <BackArrow onClick={handleBack} label="Return to Teams" />
        </>
      )}
    </div>
  )
}

export default AdminUserRoles
