import { UserDetails } from "hooks/useAuthUser"
import jwt_decode from "jwt-decode"

export function getAuthUser() {
  try {
    const storedAuthUser = sessionStorage.getItem("authUser")
    if (storedAuthUser) {
      const parsedAuthUser = JSON.parse(storedAuthUser)
      const decodedAccessToken = decodeToken(parsedAuthUser.accessToken)
      const decodedRefreshToken = decodeToken(parsedAuthUser.refreshToken)

      return {
        authUser: parsedAuthUser,
        userDetails: decodedAccessToken,
        refreshExp: decodedRefreshToken.exp,
      }
    }
  } catch (e) {
    console.error("Error retrieving authUser from local storage:", e)
  }
  return null
}

function decodeToken(token: string) {
  const decoded = jwt_decode(token)
  return decoded as UserDetails
}
