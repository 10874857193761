import React, { useState } from "react"

interface ImageWithFallbackProps {
  src: string
  fallbackSrc: string
  alt: string
}

export function ImageWithFallback({
  src,
  fallbackSrc,
  alt,
}: ImageWithFallbackProps) {
  const [imgSrc, setImgSrc] = useState<string>(src)

  return (
    <img
      src={imgSrc}
      alt={alt}
      onError={() => {
        setImgSrc(fallbackSrc)
      }}
      className="h-auto w-full object-contain"
    />
  )
}
