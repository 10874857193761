import * as yup from "yup"

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email is required"),
  tgip_secret: yup.string().required("Please enter your password"),
  rememberMe: yup.boolean(),
})
