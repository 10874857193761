import React, { useState } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import { useNavigate } from "react-router-dom"
import Modal from "components/modal/Modal"
import Status from "components/Status/Status"
import { Audit } from "types/audit"
import { STATUS } from "types/statuses"

const columnHelper = createColumnHelper<Audit>()

export const auditColumns = [
  columnHelper.accessor("username", {
    cell: (info) => info.getValue(),
    header: () => <span>User Email</span>,
  }),
  columnHelper.accessor("activity", {
    cell: (info) => info.getValue(),
    header: () => <span>Activity</span>,
  }),

  columnHelper.accessor((row) => row.requestDate, {
    id: "requestDate",
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <span>Request Date</span>,
  }),

  columnHelper.accessor((row) => row.responseDate, {
    id: "Response Date",
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <span>Response Date</span>,
  }),

  columnHelper.accessor("requestProcessed", {
    header: () => <span>Status</span>,
    cell: (info) => (
      <Status variant={info.getValue() ? STATUS.SUCCESS : STATUS.ERROR}>
        {info.getValue() ? STATUS.SUCCESS : STATUS.ERROR}
      </Status>
    ),
  }),

  columnHelper.accessor("action", {
    header: "Action",
    cell: ({ cell }) => {
      const navigate = useNavigate()
      const [showModal, setShowModal] = useState(false)
      function closeModal() {
        setShowModal(false)
      }
      return (
        <>
          <span
            onClick={() => {
              navigate(
                `details?audit=${encodeURIComponent(
                  JSON.stringify(cell.row.original)
                )}`
              )
              setShowModal(true)
            }}
            className="cursor-pointer font-semibold text-primary-default"
          >
            View
          </span>
          <Modal isOpen={showModal} closeModal={closeModal}>
            <div className="p-6">What do you want to put here?</div>
          </Modal>
        </>
      )
    },
  }),
]
