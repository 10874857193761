import React from "react"
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts"
import { TransactionsByStatusType } from "../../types/dashboard"

const COLORS = ["#204D88", "#44C3E3"]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const areBothValuesZero = (data: any[]) => {
  return data.every((entry) => entry.value === 0)
}

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {percent > 0 ? `${(percent * 100).toFixed(0)}%` : ""}
    </text>
  )
}

export const CustomPieChart: React.FC<{
  data: TransactionsByStatusType[]
}> = ({ data }) => {
  return (
    <div className="h-[15rem]">
      {areBothValuesZero(data) && (
        <div className="flex h-full items-center justify-center">
          <p className="text-gray-20">No data available</p>
        </div>
      )}
      <ResponsiveContainer>
        <PieChart
          width={300}
          height={300}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        >
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={"15rem"}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry?.name === "Successful" ? COLORS[0] : COLORS[1]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}
