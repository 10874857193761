import React from "react"
import Modal from "components/modal/Modal"
import { DataRow } from "components/DataRow/DataRow"
import Button from "components/button"
import Status from "components/Status/Status"

interface SettlementApprovalModalProps {
  isOpen: boolean
  closeModal: () => void
  settlementData: SettlementApprovalRequestType
  handleApprove: () => void
  handleDecline: () => void
}

const SettlementApprovalModal: React.FC<SettlementApprovalModalProps> = ({
  isOpen,
  closeModal,
  settlementData,
  handleApprove,
  handleDecline,
}) => {
  const dataToDisplay = {
    merchantId: settlementData.merchantId,
    merchantName: settlementData.merchantName,
    totalTransactionAmount: settlementData.totalTransactionAmount,
    totalPayoutAmount: settlementData.totalPayoutAmount,
    totalIncome: settlementData.totalIncome,
    status: (
      <Status variant={settlementData.status}>{settlementData.status}</Status>
    ),
    settlementDate: settlementData.settlementDate,
    createdDate: settlementData.createdDate,
    updatedDate: settlementData.updatedDate,
    numberOfTransactions: settlementData.numberOfTransactions,
  }

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      contentContainerClass="!max-w-3xl"
    >
      <h3 className="p-6 text-center text-3xl font-bold">
        Approval Request Details
      </h3>
      <div className="flex flex-col gap-8 p-6">
        {Object.entries(dataToDisplay).map(([label, value]) => (
          <DataRow key={label} label={label} value={value} />
        ))}
      </div>
      <div className="flex justify-end gap-8 p-6">
        <Button variant="danger" onClick={handleDecline}>
          Decline
        </Button>
        <Button variant="primary" onClick={handleApprove}>
          Approve
        </Button>
      </div>
    </Modal>
  )
}

export default SettlementApprovalModal
