import { DataRow } from "components/DataRow/DataRow"
import Status from "components/Status/Status"
import React, { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { parseJSON } from "utils/parseJSON"

interface DetailsPDFProps {
  forwardedRef: React.RefObject<HTMLDivElement>
}

const DetailsPDF: React.FC<DetailsPDFProps> = ({ forwardedRef }) => {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const audit = searchParams.get("audit")
  const auditDetails = parseJSON(audit)

  useEffect(() => {
    if (!audit) navigate("/audit-trail")
  }, [audit])

  const auditData = {
    User: auditDetails?.username,
    Role: auditDetails?.role,
    "Request Date": auditDetails?.requestDate,
    "Response Date": auditDetails?.responseDate,
    "IP Address": auditDetails?.ipAddress,
    Activity: auditDetails?.activity,
    "Activity Status": (
      <Status variant={auditDetails?.responseMessage}>
        {auditDetails?.responseMessage}
      </Status>
    ),
  }

  return (
    <div
      style={{
        position: "absolute",
        left: "100%",
        top: 0,
      }}
    >
      <div className="bg-white" ref={forwardedRef}>
        <div className="flex justify-between border-b border-b-gray-10/20 p-6">
          <div className="">
            <div className="text-base font-medium">Audit Details</div>
            <div className="text-sm text-gray-10/60">
              Here’s a more detailed view of actions performed on your account{" "}
            </div>
          </div>
        </div>
        <div className="flex min-h-352 max-w-4xl flex-col gap-8 p-6">
          {Object.entries(auditData).map(([label, value]) => (
            <DataRow key={label} label={label} value={value} />
          ))}
        </div>
      </div>
    </div>
  )
}
export default DetailsPDF
