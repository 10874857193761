import React from "react"
const WorkflowCodeInput = ({ register }: { register: any }) => (
  <div>
    <label className="text-gray-700 block text-sm font-medium">
      Workflow Code
    </label>
    <input
      {...register("workFlowCode", { required: true })}
      readOnly
      type="text"
      className="border-gray-300 mt-1 block w-full rounded-md border p-2 disabled:bg-gray-default"
      placeholder="Enter workflow code"
      disabled
    />
  </div>
)

export default WorkflowCodeInput
