import { AxiosError } from "axios"
import React, { useEffect, useState } from "react"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { useAppDispatch } from "redux/hooks"
import { saveProfileDetails } from "redux/profile/profileSlice"
import { regerateOtp, validateOTP } from "services/api/auth"
import { decodeJWT } from "utils/decodeJWT"
import { handleError } from "utils/getAxiosErrorMessage"
import { useNavigate, useSearchParams } from "react-router-dom"

const useTwoFactor = () => {
  const navigate = useNavigate()
  const [otp, setotp] = useState<string>()
  const [error, setError] = useState<string>("")
  const dispatch = useAppDispatch()
  const [timeLeft, setTimeLeft] = useState(30)

  const [searchParams] = useSearchParams()

  const accessKey = searchParams.get("accessKey")

  const OTPFieldValue = (otp: string[]) => {
    setotp(otp.join(""))
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (otp?.length !== 6) {
      return setError("Please enter complete otp to continue")
    }

    handleOTPValidation({
      otp,
      accessKey,
    })
  }

  const { mutate: handleOTPValidation, isLoading } = useMutation(validateOTP, {
    onSuccess: (response) => {
      sessionStorage.setItem("authUser", JSON.stringify(response?.data?.data))

      const decoded = decodeJWT(response.data.data.accessToken)
      dispatch(saveProfileDetails(decoded))
      navigate("/dashboard")
    },
    onError: (error: AxiosError) => {
      toast.error(handleError(error))
    },
  })

  const { mutate: regenerateUserOtp, isLoading: isRegeneratingOtp } =
    useMutation(regerateOtp, {
      onSuccess: (response) => {
        toast.success("OTP resent successfully!")
        setTimeLeft(30)
      },
      onError: (error: AxiosError) => {
        //@ts-ignore
        toast.error(error.response?.data.message)
      },
    })

  const handleRegenerateOtp = () => {
    regenerateUserOtp({
      accessKey: accessKey,
    })
  }

  useEffect(() => {
    if (timeLeft === 0) {
      return // Don't start a new countdown if the time has run out
    }
    const countdown = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1) // Decrease the time by one second
    }, 1000) // Run the countdown every 1000ms (1 second)
    return () => clearInterval(countdown) // Clear the countdown interval on component unmount
  }, [timeLeft])

  return {
    handleRegenerateOtp,
    handleSubmit,
    OTPFieldValue,
    setError,
    error,
    isLoading,
    isRegeneratingOtp,
    timeLeft,
  }
}

export default useTwoFactor
