import { useState, useEffect } from "react"
import { AuthUser } from "types/auth"
import { getAuthUser } from "utils/auth"

export type UserDetails = {
  sub: string
  userDetails: {
    id: number
    businessId: number
    firstName: string
    lastName: string
    email: string
    mobileNumber: string
    roleId: number
    roleName: string
    authorizations: string[]
    defaultRole: boolean
  }
  username: string
  iat: number
  exp: number
}

export function useAuthUser() {
  const [authUser, setAuthUser] = useState<AuthUser | null>(null)
  const [error, setError] = useState<Error | null>(null)
  const [userDetails, setUserDetails] = useState<UserDetails | null>()

  useEffect(() => {
    try {
      const user = getAuthUser()

      if (user?.authUser) {
        setAuthUser(user?.authUser)
        setUserDetails(user?.userDetails)
      }
    } catch (e) {
      if (e instanceof Error) {
        setError(e)
      }
    }
  }, [])

  return { authUser, error, userDetails }
}
