import { queryKeys } from "constants/queryKeys"
import {
  createWorkflow,
  fetchWorkflow,
  createWorkflowLevels,
  editWorkflow,
  fetchWorkflowConfig,
} from "services/api/workflow"
import { toast } from "react-toastify"
import { useMutation, useQuery } from "react-query"
import { AxiosError } from "axios"
import { handleError } from "utils/getAxiosErrorMessage"
import { queryClient } from "index"
import { useState } from "react"

export const useWorkflow = (queryKey?: string, workflowId?: string) => {
  const [pageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)

  const { mutate: handleCreate, isLoading: isCreating } = useMutation(
    createWorkflow,
    {
      onSuccess: (response) => {
        toast.success(response?.data?.message)
        queryClient.invalidateQueries(queryKeys.workflow.fetchWorkflow)
      },
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    }
  )

  const {
    data: workflowResponse,
    isLoading,
    refetch: refetchWorkflow,
  } = useQuery([queryKeys.workflow.fetchWorkflow], () => fetchWorkflow(), {
    enabled: queryKey === queryKeys.workflow.fetchWorkflow,
    staleTime: 0,
  })

  const { mutate: handleEditWorkflow, isLoading: isEditingWorkflow } =
    useMutation(editWorkflow, {
      onSuccess: (response) => {
        toast.success(response?.data?.message)
        queryClient.invalidateQueries(queryKeys.workflow.fetchWorkflow)
      },
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    })

  const handlePageChange = (page: { selected: number }) => {
    setCurrentPage(page.selected)
  }

  const { mutate: handleCreateAuthLevels, isLoading: isSettingUpWorkLevels } =
    useMutation(createWorkflowLevels, {
      onSuccess: (response) => {
        toast.success(response?.data?.message)
      },
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    })

  //get authorization levels
  const { data: worflowConfigResponse, isLoading: isLoadingWorkflowConfig } =
    useQuery(
      [queryKeys.workflow.fetchWorkflowConfig, workflowId],
      () => fetchWorkflowConfig(workflowId),
      {
        enabled: queryKey === queryKeys.workflow.fetchWorkflowConfig,
        staleTime: 0,
      }
    )

  const workflowList = workflowResponse?.data.data || []
  const totalElements = workflowResponse?.data?.totalElements || 0
  const totalPages = workflowResponse?.data?.totalPages || 0

  const workflowConfig = worflowConfigResponse?.data || []

  const pagination = {
    pageSize,
    currentPage,
    totalElements,
    totalPages,
    handlePageChange,
  }

  const workflow = {
    create: {
      handleCreate,
      isCreating,
      handleCreateAuthLevels,
      isSettingUpWorkLevels,
    },
    edit: {
      handleEditWorkflow,
      isEditingWorkflow,
    },
    fetch: {
      refetch: refetchWorkflow,
      workflowList,
      isLoading,
      workflowConfig,
      isLoadingWorkflowConfig,
    },
  }

  return { workflow, pagination }
}
