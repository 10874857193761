import { DataRow } from "components/DataRow/DataRow"
import Status from "components/Status/Status"
import useBreadcrumb from "hooks/useBreadcrumb"
import React, { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { parseJSON } from "utils/parseJSON"

type Props = {
  forwardedRef: React.RefObject<HTMLDivElement>
}

const SettlementDetailsPDF: React.FC<Props> = ({ forwardedRef }) => {
  const [searchParams] = useSearchParams()
  const settlementInfo = searchParams.get("settlementInfo")
  const settlementDetails: SettlementDetailsType = parseJSON(settlementInfo)

  const navigate = useNavigate()

  useEffect(() => {
    if (!settlementInfo) navigate("/settlement")
  }, [settlementInfo])

  useBreadcrumb([])

  const personalData = {
    "Transaction Reference": settlementDetails?.reference,
    "Bank Name": settlementDetails?.bankName,
    "Merchant ID": settlementDetails?.merchantId,
    "Business Name": settlementDetails?.merchantName,
    "Bank Account Number": settlementDetails?.accountNumber,
    "Bank Account Name": settlementDetails?.bankName,
    "Settlement Date": settlementDetails?.settlementDate,
    "Payout Amount (NGN)": settlementDetails?.totalPayoutAmount,
    "Settlement Amount (NGN)": settlementDetails?.totalPayoutAmount,
    "Transaction Fee(NGN)":
      settlementDetails.totalTransactionAmount -
      settlementDetails?.totalPayoutAmount,
    "Transactions in Settlement Batch": settlementDetails?.numberOfTransactions,
    "Payout Status": (
      <Status variant={settlementDetails?.status}>
        {settlementDetails?.status}
      </Status>
    ),
  }

  return (
    <div
      style={{
        position: "absolute",
        left: "100%",
        top: 0,
      }}
    >
      <div className="max-w-4xl bg-white" ref={forwardedRef}>
        <div className="flex justify-between border-b border-b-gray-10/20 p-6">
          <div className="">
            <div className="text-base font-medium">Settlement Details</div>
            <div className="text-sm text-gray-10/60">
              View details of payout settelement{" "}
            </div>
          </div>
        </div>
        <div className="flex min-h-352 max-w-4xl flex-col gap-8 p-6">
          {Object.entries(personalData).map(([label, value]) => (
            <DataRow key={label} label={label} value={value} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default SettlementDetailsPDF
