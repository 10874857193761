import CustomSelect from "components/Select/CustomSelect"
import React from "react"

const AuthorizationLevels = ({ fields, filteredOptions, control }: any) => (
  <div className="my-3">
    <label className="text-gray-700 mb-4 block text-xl font-medium">
      Authorization Levels
    </label>
    <div className="flex flex-wrap gap-4">
      {fields.map(
        (field: { id: React.Key | null | undefined }, index: number) => (
          <div
            key={field.id}
            className="min-w-[30rem] flex-1 rounded-lg border border-gray-20 p-4"
          >
            <div className="mb-2 flex items-center space-x-2">
              <label className="text-gray-700 text-md block font-medium">
                Authorization Level {index + 1}
              </label>
            </div>
            <CustomSelect
              options={filteredOptions}
              name={`workflowAuthorizerRequestDtoList.${index}.authorizers`}
              label="Authorizer(s)"
              control={control}
              controlProps={{ className: "w-full" }}
              labelprops={{
                className: "text-gray-700 block text-sm font-medium",
              }}
              isMulti
            />
          </div>
        )
      )}
    </div>
  </div>
)
export default AuthorizationLevels
