import React from "react"
import Table from "components/Table"
import { userColumns } from "./columns"
import { Pagination } from "components/Pagination/PaginationComponent"
import Button from "components/button"
import ExportModal from "../../../../../../components/ExportModal/ExportModal"
import useUser from "../hooks/useUser"
import PaginationInfo from "components/Pagination/PaginationInfo"
import { baseUrl } from "config/baseUrl"
import { userEndpoints } from "constants/endpoints"

const UserTable: React.FC = () => {
  const { user, pagination, exportManager } = useUser()

  return (
    <div>
      <div className="mt-4 border border-[#D3D3D3]">
        <Table
          columns={userColumns}
          data={user.userList}
          isLoading={user.isLoading}
          loadingSkeletonCount={10}
        />
        {user.userList?.length > 0 && (
          <div className="flex h-[4.3125rem] items-center justify-between bg-white px-5">
            <PaginationInfo pagination={pagination} />
            <div>
              <Pagination pagination={pagination} />
            </div>
          </div>
        )}
      </div>

      <Button
        className="my-9 w-24"
        onClick={() => exportManager.setShowExportModal(true)}
      >
        Export
      </Button>
      {exportManager.showExportModal ? (
        <ExportModal
          isOpen={exportManager.showExportModal}
          closeModal={() => exportManager.setShowExportModal(false)}
          exportDataName="Users"
          downloadUrl={`${baseUrl.adminUserService}${userEndpoints.fetchUserData}`}
          downloadParams={{}}
        />
      ) : null}
    </div>
  )
}

export default UserTable
