import { yupResolver } from "@hookform/resolvers/yup"
import { AxiosError } from "axios"
import { loginSchema } from "pages/public/login/validation-schemas"
import { FieldValues, useForm } from "react-hook-form"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useAppDispatch } from "redux/hooks"
import { saveProfileDetails } from "redux/profile/profileSlice"
import { login } from "services/api/auth"
import { decodeJWT } from "utils/decodeJWT"
import { handleError } from "utils/getAxiosErrorMessage"

export function useLogin(redirectUrl?: string | null) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const methods = useForm({
    resolver: yupResolver(loginSchema),
  })
  const { handleSubmit } = methods

  const { mutate: authenticateUser, isLoading } = useMutation(login, {
    onSuccess: (response) => {
      sessionStorage.setItem("authUser", JSON.stringify(response?.data?.data))

      if (response.data.useTwoFactorAuth && "accessKey" in response.data.data) {
        navigate(`/two-factor?accessKey=${response?.data?.data.accessKey}`)
      } else {
        const decoded = decodeJWT(response.data.data.accessToken)
        dispatch(saveProfileDetails(decoded))

        if (redirectUrl) {
          navigate(redirectUrl)
        } else {
          navigate("/dashboard")
        }
      }
    },
    onError: (error: AxiosError) => {
      toast.error(handleError(error))
    },
  })

  const onSubmit = (data: FieldValues) => {
    authenticateUser({
      username: data.email,
      password: data.tgip_secret,
    })
  }

  return { onSubmit, handleSubmit, isLoading, methods }
}
