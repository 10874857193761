import React, { useEffect, useState } from "react"
import TopBar from "layout/protected/ContentArea/TopBar"
import DashboardFilter from "./components/Filter"
import useDashboard from "./hooks/useDashboard"
import { useAppSelector } from "redux/hooks"
import Activity from "./Activity/Activity"
import TransactionHistory from "./TransactionPieChart"
import TransactionLineChart from "./TransactionLineChart"
import MetricCards from "./MetricCards"
import { format } from "date-fns"
import { queryKeys } from "constants/queryKeys"
import { DATE_GROUPINGS, DateGrouping } from "constants/dashboard"

const Dashboard: React.FC = () => {
  const [startDate, setStartDate] = useState<Date | null>(new Date())
  const [endDate, setEndDate] = useState<Date | null>(new Date())
  const [dateGrouping, setDateGrouping] = useState<DateGrouping>(
    DATE_GROUPINGS.hour
  )
  const [rangeType, setRangeType] = useState("today")

  const { data, loading, filter, search } = useDashboard({
    startDate: startDate ? format(startDate, "dd-MM-yyyy") : "",
    endDate: endDate ? format(endDate, "dd-MM-yyyy") : "",
    targetQuerykeys: [queryKeys.dashboard.allSuccessfulByValuePoints],
    dateGrouping,
  })
  const {
    user: { profile },
  } = useAppSelector((state) => state)

  const updateDate = (
    startDate: Date | null,
    endDate: Date | null,
    dateGrouping: DateGrouping
  ) => {
    if (startDate) setStartDate(startDate)
    if (endDate) setEndDate(endDate)
    setDateGrouping(dateGrouping)
  }

  useEffect(() => {
    updateDate(new Date(), new Date(), DATE_GROUPINGS.day)
  }, [])

  return (
    <div>
      <TopBar
        pageTitle="Dashboard"
        subTitle={`Good morning, ${profile?.firstName || ""}`}
      />

      <div className="flex items-center justify-end gap-6 pt-2">
        <DashboardFilter
          filter={filter}
          search={search}
          updateDate={updateDate}
          rangeType={rangeType}
          setRangeType={setRangeType}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </div>

      <div className="mt-4 grid grid-cols-10 gap-6">
        <div className="col-span-10 lg:col-span-7">
          <MetricCards
            startDate={startDate ? format(startDate, "dd-MM-yyyy") : ""}
            endDate={endDate ? format(endDate, "dd-MM-yyyy") : ""}
            dateGrouping={dateGrouping}
          />
          <TransactionLineChart
            transactionTimeData={data.allSuccessfulValuePoints}
            loading={loading.loadingAllTxValueByDate}
            rangeType={rangeType}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
        <div className="col-span-10 lg:col-span-3">
          <TransactionHistory
            data={data.transactionsByStatusData}
            loading={loading.loadingFailedByDateCount}
            rangeType={rangeType}
            startDate={startDate}
            endDate={endDate}
          />

          <Activity
            activities={data.activityData}
            loading={loading.loadingFailedByDateCount}
            rangeType={rangeType}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      </div>
    </div>
  )
}

export default Dashboard
