import React, { ReactNode } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { Fragment } from "react"
import CloseIcon from "../../assets/icons/close.svg"

interface ModalProps {
  isOpen: boolean
  closeModal: () => void
  children: ReactNode
  contentContainerClass?: string
}
const Modal: React.FC<ModalProps> = ({
  closeModal,
  isOpen,
  children,
  contentContainerClass,
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-10 bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`w-full max-w-md transform overflow-hidden rounded-[4px] bg-white text-left align-middle shadow-xl transition-all ${contentContainerClass}`}
              >
                <img
                  className="absolute right-[0.8125rem] top-[0.8125rem] w-4 cursor-pointer"
                  src={CloseIcon}
                  alt="close"
                  onClick={closeModal}
                />
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Modal
