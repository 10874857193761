import React from "react"
import { CustomLineChart } from "./components/LineChart"
import { TransactionTime } from "./types/dashboard"
import Skeleton from "react-loading-skeleton"
import { customDateRanges } from "./constants/customDateRanges"

const TransactionLineChart: React.FC<{
  transactionTimeData: TransactionTime[]
  loading: boolean
  rangeType: string
  startDate: Date | null
  endDate: Date | null
}> = ({ transactionTimeData, loading, rangeType, startDate, endDate }) => {
  const renderRangetype = () => {
    if (rangeType !== "custom") {
      return customDateRanges[rangeType]
    }
    return `${startDate?.toDateString() ?? ""} - ${
      endDate?.toDateString() ?? ""
    }`
  }
  return (
    <>
      {loading ? (
        <Skeleton className="h-[25rem]" />
      ) : (
        <div className="bg-white p-6 shadow-lg">
          <h4 className="text-lg font-semibold">
            Statistical Chart - Transaction Value
          </h4>
          <p className="py-6 text-xs">{renderRangetype()}</p>
          <div>
            <CustomLineChart data={transactionTimeData} />
          </div>
        </div>
      )}
    </>
  )
}

export default TransactionLineChart
