import { AxiosError } from "axios"
import Button from "components/button"
import Input from "components/input"
import TextArea from "components/input/TextArea"
import Modal from "components/modal/Modal"
import { queryKeys } from "constants/queryKeys"
import { queryClient } from "index"
import React, { useEffect } from "react"
import { FieldValues, FormProvider, useForm } from "react-hook-form"
import Skeleton from "react-loading-skeleton"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { useAppSelector } from "redux/hooks"
import { editAdminRole } from "services/api/users"
import { Role } from "types/roles"
import { handleError } from "utils/getAxiosErrorMessage"
import usePermissions from "../../hooks/usePermissions"
import { yupResolver } from "@hookform/resolvers/yup"
import { createRoleSchema } from "../../validation-schemas/role"

const EditRole: React.FC<{
  isOpen: boolean
  handleClose: () => Promise<void>
  selectedRole: Role
}> = ({ isOpen, handleClose, selectedRole }) => {
  const methods = useForm({
    resolver: yupResolver(createRoleSchema),
  })

  const [selectedPermissions, setSelectedPermissions] = React.useState<
    Permission[]
  >([])

  const { adminPermissions, loadingAdminPermissions } = usePermissions({
    queryKey: queryKeys.users.fetchAdminPermissions,
  })

  useEffect(() => {
    if (selectedRole.permissions) {
      methods.setValue("roleName", selectedRole.name)
      methods.setValue("roleDescription", selectedRole.description)
      methods.setValue("role", selectedRole.permissions)
      setSelectedPermissions(selectedRole.permissions)
    }
  }, [selectedRole.permissions])

  const profileDetails = useAppSelector((state) => state.user.profile)

  const { mutate: handleEditAdminRole, isLoading: updatingAdminRole } =
    useMutation(editAdminRole, {
      onSuccess: async (response) => {
        toast.success(response?.data?.message)
        await queryClient.invalidateQueries(queryKeys.users.fetchAdminRoles)
        await handleClose()
      },
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    })

  const onUpdate = (data: FieldValues) => {
    const payload = {
      id: selectedRole.id,
      businessId: profileDetails?.id,
      name: data.roleName,
      description: data.roleDescription,
      permissions: selectedPermissions,
    }
    handleEditAdminRole(payload)
  }

  return (
    <Modal
      contentContainerClass="p-6 max-w-sm"
      isOpen={isOpen}
      closeModal={handleClose}
    >
      <div className="mb-6">
        <h5 className="text-lg font-medium">Edit Admin Role</h5>
        <div className="text-xs font-normal">Make changes to custom role.</div>
      </div>

      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onUpdate)}
          className="flex flex-col gap-4"
        >
          <Input label="Role Name" name="roleName" />
          <TextArea label="Role Description" name="roleDescription" />

          <div className="mt-2">
            Role Permissions ({selectedPermissions?.length} of{" "}
            {adminPermissions?.length})
          </div>

          {loadingAdminPermissions ? <Skeleton /> : null}

          <div className="flex flex-col gap-4">
            {selectedPermissions &&
              adminPermissions?.map((permission) => (
                <div
                  className="flex gap-4 pt-4"
                  key={`permission-${permission.id}`}
                >
                  <Input
                    type="checkbox"
                    name="role"
                    checkBoxProps={{ className: "bg-gray-20/50 border-none" }}
                    checkMarkProps={{ fill: "#292727" }}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedPermissions((prev) => [...prev, permission])
                      } else {
                        setSelectedPermissions((prev) =>
                          prev.filter((p) => p.id !== permission.id)
                        )
                      }
                    }}
                    checked={selectedPermissions.some(
                      (p) => p.id === permission.id
                    )}
                  />
                  <span className="w-fit">{permission.name}</span>
                </div>
              ))}
          </div>
          <div className="mt-6 flex justify-between">
            <Button
              type="button"
              onClick={handleClose}
              variant="outlinePrimary"
            >
              Cancel
            </Button>
            <Button loading={updatingAdminRole}>Save Changes</Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default EditRole
