import { queryKeys } from "constants/queryKeys"
import useBreadcrumb from "hooks/useBreadcrumb"
import React, { useState } from "react"
import { useQuery } from "react-query"
import { fetchUserData } from "services/api/users"

const useUser = (customPageSize?: number) => {
  const [showExportModal, setShowExportModal] = React.useState(false)
  useBreadcrumb([])

  const [pageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)

  const { data: userResponse, isLoading } = useQuery(
    [queryKeys.users.fetchUsers, currentPage],
    () => fetchUserData({ pageSize: customPageSize ?? pageSize, currentPage })
  )

  const userList: User[] = userResponse?.data?.data.content
  const totalElements = userResponse?.data?.data?.totalElements
  const totalPages = userResponse?.data?.data?.totalPages

  const handlePageChange = (page: { selected: number }) => {
    setCurrentPage(page.selected)
  }

  const pagination = {
    pageSize,
    currentPage,
    totalElements,
    totalPages,
    handlePageChange,
  }

  const user = { userList, isLoading }

  const exportManager = { showExportModal, setShowExportModal }

  return { user, pagination, exportManager }
}

export default useUser
