import { STATUS } from "types/statuses"

export type KYCStatus =
  | typeof STATUS.KYC.PENDING
  | typeof STATUS.KYC.APPROVED
  | typeof STATUS.KYC.REJECTED

export interface KYCListEntry {
  businessId: number
  merchantUserId: number
  businessAdminEmail: null
  businessAdminFirstName: null
  businessAdminLastName: null
  businessName: string
  businessCategory: string
  businessDescription: null
  businessType: string
  businessTypeId: number
  country: string
  state: null
  address: null
  created: string
  tin: string
  status: KYCStatus
}

export enum BusinesstypeName {
  Registered = "Registered",
  NonRegistered = "Non-Registered",
}

export enum BusinessType {
  Registered = 1,
  NonRegistered = 2,
}

export type BusinessTypeMap = {
  [key: string]: BusinessType
}
export const businessTypeMap: BusinessTypeMap = {
  [BusinesstypeName.Registered]: BusinessType.Registered,
  [BusinesstypeName.NonRegistered]: BusinessType.NonRegistered,
}

export enum DocumentTypeCodes {
  CAC = 1,
  MemoOfUnderstanding = 2,
  UtilityBill = 3,
  IDCard = 4,
}

export const documentTypeCodesMap = {
  [BusinessType.Registered]: [
    DocumentTypeCodes.CAC,
    DocumentTypeCodes.IDCard,
    DocumentTypeCodes.UtilityBill,
    DocumentTypeCodes.MemoOfUnderstanding,
  ],
  [BusinessType.NonRegistered]: [DocumentTypeCodes.IDCard],
}

export type DocumentTypes = {
  id: number
  name: string
  description: string
  valid: boolean
}

export const getSupportedDocumentTypes = (businessType: BusinessType) => {
  return documentTypeCodesMap[businessType]?.map((code) => {
    return code
  })
}
