import { KYCStatus } from "pages/protected/KYC/types/kyc"
import { UserAxiosInstance } from "services/axios/userApi"
import { STATUS } from "types/statuses"
import { kycEndpoints, workflowEndpoints } from "../../constants/endpoints"
import { WorkflowAxiosInstance } from "services/axios/workflow"

interface PaginationPayload {
  currentPage: number
  pageSize: number
  search: string
}

interface ApprovalData {
  businessId: number
  comment: string
}

interface ReviewData {
  merchantId: number | undefined
}

// Check if the status is one of the valid statuses
const validStatuses = [
  STATUS.KYC.SUBMITTED,
  STATUS.KYC.APPROVED,
  STATUS.KYC.REJECTED,
  STATUS.KYC.PENDING,
]

const getKYCRequestUrl = ({
  currentPage,
  pageSize,
  search,
}: PaginationPayload) =>
  `${kycEndpoints.kycRequests}?page=${currentPage}&size=${pageSize}&${search}`

export const fetchKYCByStatus = async (
  payload: PaginationPayload & { kycRequestQueryType?: KYCStatus }
) => {
  const { kycRequestQueryType } = payload

  if (kycRequestQueryType && validStatuses.includes(kycRequestQueryType)) {
    return UserAxiosInstance.get(getKYCRequestUrl(payload))
  }

  return null
}

export const fetchKYCFromMakerChecker = async () => {
  return UserAxiosInstance.get(`${kycEndpoints.makerCheckerKYCRequests}`)
}

export const requestKYCReview = async (reviewData: ApprovalData) => {
  return UserAxiosInstance.post(kycEndpoints.requestKYCReview, reviewData)
}

export const fetchKYCDocuments = async ({ merchantId }: ReviewData) => {
  if (!merchantId)
    throw new Error("Merchant ID is required to fetch documents.")
  return UserAxiosInstance.get(
    `${kycEndpoints.fetchKYCDocuments}/${merchantId}`
  )
}

export const fetchDocumentTypes = async () => {
  return UserAxiosInstance.get(kycEndpoints.fetchDocumentTypes)
}
