import React from "react"
import Table from "components/Table"
import useMerchants from "./hooks/useMerchants"
import PermissionGate from "components/PermissionGate/PermissionGate"
import { PERMISSIONS } from "constants/permissions"
import ListAccessDenied from "components/PermissionGate/ListAccessDenied"
import { approvalRequestColumns } from "./Table/approvalRequestColumns"

const MerchantApprovalRequests: React.FC = () => {
  const { merchant } = useMerchants()

  return (
    <PermissionGate
      allowedPermissions={[PERMISSIONS.VIEW_MERCHANTS]}
      fallback={<ListAccessDenied />}
    >
      <div className="relative">
        <div className="mt-4 border border-[#D3D3D3]">
          <Table
            columns={approvalRequestColumns}
            data={merchant.merchantApprovalRequests}
            isLoading={merchant.isLoading}
            loadingSkeletonCount={10}
          />
        </div>
      </div>
    </PermissionGate>
  )
}

export default MerchantApprovalRequests
