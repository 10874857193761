import React from "react"
import { CustomPieChart } from "./components/PieChart"
import { TransactionsByStatusType } from "./types/dashboard"
import Skeleton from "react-loading-skeleton"
import { customDateRanges } from "./constants/customDateRanges"

const TransactionPieChart: React.FC<{
  data: TransactionsByStatusType[]
  loading: boolean
  rangeType: string
  startDate: Date | null
  endDate: Date | null
}> = ({ loading, data, rangeType, startDate, endDate }) => {
  if (loading) return <Skeleton className="h-[22rem]" />

  const renderRangetype = () => {
    if (rangeType !== "custom") {
      return customDateRanges[rangeType]
    }
    return `${startDate?.toDateString() ?? ""} - ${
      endDate?.toDateString() ?? ""
    }`
  }

  return (
    <div className="mb-7 bg-white p-4 shadow-lg">
      <h6 className="text-lg font-semibold">Transaction History</h6>
      <p className="my-4 text-xs">{renderRangetype()}</p>
      <CustomPieChart data={data} />
      <div className="mb-3 flex items-center gap-3">
        <div className="h-6 w-6 rounded bg-primary-default">&nbsp;</div>
        <div className="text-xs">Successful Transactions</div>
      </div>
      <div className="flex items-center gap-3">
        <div className="h-6 w-6 rounded bg-secondary-default">&nbsp;</div>
        <div className="text-xs">Unsuccessful Transactions</div>
      </div>
    </div>
  )
}

export default TransactionPieChart
