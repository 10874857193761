import React, { FC, useEffect } from "react"
import Modal from "components/modal/Modal"
import { useForm, useFieldArray } from "react-hook-form"
import { WorkflowT } from "types/workflow"
import Button from "components/button"
import useUser from "../../Teams/AdminUserRoles/ManageUsers/hooks/useUser"
import { filterUsers, getUserNameByEmail, validateAuthorizers } from "../util"
import AuthorizationLevels from "./AuthorizationLevels"
import { useWorkflow } from "../hooks/useWorkflow"
import WorkflowCodeInput from "./WorkflowCodeInput"
import { yupResolver } from "@hookform/resolvers/yup"
import { configureWorkflowSchema } from "../validation/configureWorkflowSchema"
import { queryKeys } from "constants/queryKeys"
import { toast } from "react-toastify"
import { updateWorkflowAuthorizers } from "services/api/workflow"

type Props = {
  isOpen: boolean
  handleClose: () => void
  workflow: WorkflowT
}

const WorkflowConfiguration: FC<Props> = ({
  isOpen,
  handleClose,
  workflow,
}) => {
  const { user } = useUser(100)
  const { workflow: workflowHook } = useWorkflow(
    queryKeys.workflow.fetchWorkflowConfig,
    workflow.code
  )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { control, register, handleSubmit, reset, watch } = useForm<any>({
    defaultValues: {
      workFlowCode: workflow.code,
      workflowAuthorizerRequestDtoList: [],
    },
    resolver: yupResolver(configureWorkflowSchema),
  })

  const { fields } = useFieldArray({
    control,
    name: "workflowAuthorizerRequestDtoList",
    rules: { required: "At least one authorizer is required" },
  })

  useEffect(() => {
    if (workflowHook.fetch.workflowConfig?.data) {
      const { data } = workflowHook.fetch.workflowConfig

      const authorizerRequestDtoList = data.map(
        (item: { authorizerLevel: number; authorizers: string }) => ({
          authorizationLevel: item.authorizerLevel,
          authorizers: item.authorizers
            ? item.authorizers
                .split(", ")
                .filter((email: string) => email.trim() !== "")
                .map((email: string) => ({
                  label: getUserNameByEmail(email, user.userList),
                  value: email,
                }))
            : [],
        })
      )

      reset({
        workFlowCode: workflow.code,
        workflowAuthorizerRequestDtoList: authorizerRequestDtoList,
      })
    } else {
      reset({
        workFlowCode: workflow.code,
        workflowAuthorizerRequestDtoList: Array.from(
          { length: workflow.numberOfLevel },
          (_, index) => ({
            authorizationLevel: index + 1,
            authorizers: [],
          })
        ),
      })
    }
  }, [workflowHook.fetch.workflowConfig, reset, workflow])

  const onSubmit = async (data: {
    workFlowCode: string
    workflowAuthorizerRequestDtoList: any[]
  }) => {
    const isValid = validateAuthorizers(data.workflowAuthorizerRequestDtoList)
    const existingData = workflowHook.fetch.workflowConfig.data

    if (isValid) {
      if (existingData === null) {
        //create
        const payload = {
          workFlowCode: data.workFlowCode,
          workflowAuthorizerRequestDtoList: formatAuthorizers(
            data.workflowAuthorizerRequestDtoList
          ),
        }
        workflowHook.create.handleCreateAuthLevels(payload, {
          onSuccess: () => {
            handleClose()
          },
        })
      } else {
        const newData = data.workflowAuthorizerRequestDtoList
        const existingData = workflowHook.fetch.workflowConfig.data

        const updatePromises: Promise<any>[] = []

        newData.forEach((newLevel) => {
          const existingLevel =
            existingData &&
            existingData?.find(
              (level: any) =>
                level.authorizerLevel === newLevel.authorizationLevel
            )

          if (existingLevel) {
            const existingEmails = existingLevel.authorizers?.split(", ") || []
            const newEmails = newLevel.authorizers.map(
              (auth: { value: string }) => auth.value
            )

            const emailsToAdd = newEmails.filter(
              (email: string) =>
                email.trim() !== "" && !existingEmails.includes(email)
            )

            const emailsToRemove = existingEmails.filter(
              (email: string) =>
                email.trim() !== "" && !newEmails.includes(email)
            )

            emailsToAdd.forEach((email: string) => {
              updatePromises.push(
                updateWorkflowAuthorizers({
                  workFlowCode: data.workFlowCode,
                  authorizationLevel: newLevel.authorizationLevel,
                  email,
                  add: true,
                })
              )
            })

            emailsToRemove.forEach((email: string) => {
              updatePromises.push(
                updateWorkflowAuthorizers({
                  workFlowCode: data.workFlowCode,
                  authorizationLevel: newLevel.authorizationLevel,
                  email,
                  add: false,
                })
              )
            })
          } else {
            newLevel.authorizers.forEach((auth: { value: string }) => {
              const email = auth.value.trim()
              if (email) {
                updatePromises.push(
                  updateWorkflowAuthorizers({
                    workFlowCode: data.workFlowCode,
                    authorizationLevel: newLevel.authorizationLevel,
                    email,
                    add: true,
                  })
                )
              }
            })
          }
        })

        try {
          await Promise.all(updatePromises)
          toast.success("Authorizers updated successfully.")
          handleClose()
        } catch (error) {
          toast.error("Failed to update authorizers.")
        }
      }
    } else {
      toast.error("Please select at least one authorizer for each level.")
    }
  }

  const formatAuthorizers = (authorizers: any[]) =>
    authorizers.map((item) => ({
      authorizers: item.authorizers.map((auth: any) => auth.value),
      authorizationLevel: item.authorizationLevel,
    }))

  const selectedAuthorizers = watch(
    "workflowAuthorizerRequestDtoList",
    []
  ).flatMap(
    (authorizer: { authorizers: { value: string }[] }) => authorizer.authorizers
  )
  const selectedAuthorizerEmails = selectedAuthorizers.map(
    (auth: { value: string }) => auth.value
  )

  const filteredOptions = filterUsers(user.userList, selectedAuthorizerEmails)

  return (
    <Modal
      contentContainerClass="p-6 !max-w-[70rem]"
      isOpen={isOpen}
      closeModal={handleClose}
    >
      <h2 className="mb-6 text-center text-2xl font-medium text-primary-default">
        Setup Workflow Levels
      </h2>
      {user.isLoading ? (
        <div>Loading authorizers...</div>
      ) : (
        <form
          name="workflow-form"
          className=""
          onSubmit={handleSubmit(onSubmit)}
        >
          <h3 className="mb-3 text-xl">{workflow.name}</h3>
          <WorkflowCodeInput register={register} />
          <AuthorizationLevels
            fields={fields}
            filteredOptions={filteredOptions}
            control={control}
          />
          <Button
            loading={workflowHook.create.isSettingUpWorkLevels}
            type="submit"
          >
            Submit
          </Button>
        </form>
      )}
    </Modal>
  )
}

export default WorkflowConfiguration
