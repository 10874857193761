import React, { MutableRefObject, useRef } from "react"
import { useNavigate } from "react-router-dom"
import TGIPLogo from "../../../assets/images/TGIPrimaryLogo.svg"
import { ReactComponent as LogoutIcon } from "../../../assets/icons/logout.svg"
import { ReactComponent as CloseIcon } from "../../../assets/icons/mobile-menu-close.svg"

import Menu from "../Menu/Menu"
import style from "../Menu/Menu.module.scss"
import { useAppDispatch } from "redux/hooks"
import { toggleMobileSidebar } from "redux/ui/uiSlice"
import { useOnClickOutside } from "hooks/useOutsideClick"

const MobileSidebar: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const toggleSidebar = () => {
    dispatch(toggleMobileSidebar())
  }
  const ref: MutableRefObject<undefined> = useRef()
  useOnClickOutside(ref, () => toggleSidebar())

  return (
    <div
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      ref={ref}
      className="fixed z-30 flex h-screen w-48 flex-col bg-white pt-20 md:hidden"
    >
      <CloseIcon
        onClick={toggleSidebar}
        className="absolute right-4 top-6 h-6 w-6"
      />
      <div className="mb-12 flex items-center justify-center">
        <img src={TGIPLogo} alt="logo" className="h-[1.375rem] w-[5rem]" />
      </div>
      <div className="flex h-full flex-col">
        <Menu />
        <div
          className={`${style.menu} mt-auto flex h-[2.5rem] w-full cursor-pointer items-center gap-[0.875rem] pl-[0.875rem] text-xs font-normal hover:font-bold hover:text-primary-default`}
          onClick={() => navigate("/")}
        >
          <LogoutIcon />
          Log Out
        </div>
      </div>
    </div>
  )
}

export default MobileSidebar
