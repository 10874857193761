import ProtectedRoute from "components/PrivateRoute/PrivateRoute"
import AuthRouteNotFound from "pages/not-found/AuthRouteNotFound"
import AuditTrail from "pages/protected/AuditTrail/AuditTrail"
import Dashboard from "pages/protected/Dashboard"
import Disputes from "pages/protected/Disputes/Disputes"
import FeeSettings from "pages/protected/Configuration/Configuration"
import KYCDetails from "pages/protected/KYC/Details/Details"
import KYC from "pages/protected/KYC/KYC"
import MerchantDetails from "pages/protected/Merchants/Details/Details"
import Merchants from "pages/protected/Merchants"
import SettlementDetails from "pages/protected/Settlement/Details/Details"
import Settlement from "pages/protected/Settlement"
import Teams from "pages/protected/Teams/Teams"
import TransactionDetails from "pages/protected/Transactions/Details/Details"
import Transactions from "pages/protected/Transactions/Transactions"
import React from "react"
import Notifications from "pages/protected/Notifications/Notifications"
import AuditDetails from "pages/protected/AuditTrail/Details/Details"
import DisputeDetails from "pages/protected/Disputes/Details/Details"
import Workflow from "pages/protected/Workflow/Workflow"

export const protectedRoutes = [
  {
    path: "dashboard",
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: "kyc",
    element: (
      <ProtectedRoute>
        <KYC />
      </ProtectedRoute>
    ),
  },
  {
    path: "kyc/details",
    element: (
      <ProtectedRoute>
        <KYCDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: "transactions",
    element: (
      <ProtectedRoute>
        <Transactions />
      </ProtectedRoute>
    ),
  },
  {
    path: "transactions/details",
    element: (
      <ProtectedRoute>
        <TransactionDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: "settlement",
    element: (
      <ProtectedRoute>
        <Settlement />
      </ProtectedRoute>
    ),
  },
  {
    path: "settlement/details",
    element: (
      <ProtectedRoute>
        <SettlementDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: "merchants",
    element: (
      <ProtectedRoute>
        <Merchants />
      </ProtectedRoute>
    ),
  },
  {
    path: "merchants/details",
    element: (
      <ProtectedRoute>
        <MerchantDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: "merchants/transaction-details",
    element: (
      <ProtectedRoute>
        <Merchants />
      </ProtectedRoute>
    ),
  },
  {
    path: "teams",
    element: (
      <ProtectedRoute>
        <Teams />
      </ProtectedRoute>
    ),
  },
  {
    path: "disputes",
    element: (
      <ProtectedRoute>
        <Disputes />
      </ProtectedRoute>
    ),
  },
  {
    path: "disputes/details",
    element: (
      <ProtectedRoute>
        <DisputeDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: "audit-trail",
    element: (
      <ProtectedRoute>
        <AuditTrail />
      </ProtectedRoute>
    ),
  },
  {
    path: "audit-trail/details",
    element: (
      <ProtectedRoute>
        <AuditDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: "configuration",
    element: (
      <ProtectedRoute>
        <FeeSettings />
      </ProtectedRoute>
    ),
  },
  {
    path: "notifications",
    element: (
      <ProtectedRoute>
        <Notifications />
      </ProtectedRoute>
    ),
  },
  {
    path: "workflow-setup",
    element: (
      <ProtectedRoute>
        <Workflow />
      </ProtectedRoute>
    ),
  },
  {
    path: "*",
    element: <AuthRouteNotFound />,
  },
]
