import BackArrow from "components/button/BackArrow"
import TopBar from "layout/protected/ContentArea/TopBar"
import React, { FC, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { ReactComponent as AttachmentIcon } from "assets/icons/attachment.svg"
import Button from "components/button"
import DocumentDetails from "./DocumentDetails"
import ApproveKYCModal from "./Approve"
import DeclineKYCModal from "./Decline"
import AskForReviewModal from "./AskForReview"
import useBreadcrumb from "hooks/useBreadcrumb"
import MerchantInfo from "./MerchantInfo"
import { parseJSON } from "utils/parseJSON"
import useKYCDocs from "../hooks/useKYCDocs"
import { queryKeys } from "constants/queryKeys"
import { STATUS } from "types/statuses"
import Skeleton from "react-loading-skeleton"
import { getSupportedDocumentTypes } from "../types/kyc"
import PermissionGate from "components/PermissionGate/PermissionGate"
import { PERMISSIONS } from "constants/permissions"
import { formatDate } from "utils/date"
import { useOTP } from "hooks/useOTP"
import { useAuthUser } from "hooks/useAuthUser"
import ApprovalTwoFactor from "./TwoFactor"

type KYCDocProps = {
  docType: string
  onClick: () => void
  documentId: string
  uploadedDocuments: KYCDocument[]
}

export type KYCDocument = {
  businessId: number
  documentsId: number
  fileName: string
  contentType: string
  location: string
  file: null
}
const KYCDetails: React.FC = () => {
  const [selectedDoc, setSelectedDoc] = useState<KYCDocument | null>()
  const [selectedDocType, setSelectedDocType] = useState<string | null>()
  const [showApprovalDialog, setShowApprovalDialog] = useState(false)
  const [showDeclineDialog, setShowDeclineDialog] = useState(false)
  const [showAskForReview, setShowAskForReview] = useState(false)
  const [showOTPDialog, setShowOTPDialog] = useState(false)

  const { otp } = useOTP()
  const { authUser } = useAuthUser()

  const [searchParams] = useSearchParams()
  const kycInfo = searchParams.get("kycInfo")

  const kycDetails = parseJSON(kycInfo)

  const { isLoading, kycDocs, documentTypes, loadingDocTypes } = useKYCDocs({
    queryKey: queryKeys.kyc.fetchDocumentTypes,
    merchantId: kycDetails.businessId ?? "",
  })

  const { loadingBvnDetails, bvnDetails } = useKYCDocs({
    merchantId: kycDetails.businessId ?? "",
  })

  useBreadcrumb(["KYC Management", `/${kycDetails.businessName ?? ""}`])

  useEffect(() => {
    if (!kycInfo) navigate("/kyc")
  }, [kycInfo])

  const getTabIndex = () => {
    if (kycDetails.status === STATUS.KYC.REJECTED) return 2
    if (kycDetails.status === STATUS.KYC.APPROVED) return 1
    return 0
  }

  const getDocument = (
    docTypeId: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    uploadedDocuments: any
  ): KYCDocument => {
    return uploadedDocuments?.find(
      (doc: { documentsId: string }) => doc.documentsId === docTypeId
    )
  }

  const isSupportedForBusinessType = (docTypeId: string) => {
    const isSupported = getSupportedDocumentTypes(
      kycDetails.businessTypeId
    )?.includes(Number(docTypeId))

    if (isSupported) return true
    return false
  }

  const handleApproveDialogClose = () => {
    setShowApprovalDialog(false)
    if (authUser?.email) {
      otp.generate.handleOTPGeneration(authUser.email, {
        onSuccess: () => {
          setShowOTPDialog(true)
        },
      })
    }
  }

  const isApproved = kycDetails.status === STATUS.KYC.APPROVED
  const isDeclined = kycDetails.status === STATUS.KYC.REJECTED
  const reviewRequested = kycDetails.status === STATUS.KYC.REVIEW

  const KYCDoc: FC<KYCDocProps> = ({
    docType,
    onClick,
    documentId,
    uploadedDocuments,
  }) => {
    const uploadedDoc = getDocument(documentId, uploadedDocuments)

    return (
      <div className="flex cursor-pointer items-center justify-between gap-3">
        <div className="flex gap-3">
          <AttachmentIcon />
          <span className="text-sm font-medium text-[#292727]/70">
            {docType}
          </span>
        </div>
        {uploadedDoc ? (
          <button
            onClick={onClick}
            className="cursor-pointer text-primary-default"
          >
            View
          </button>
        ) : (
          <div className="text-danger-default/50">Not Uploaded</div>
        )}
      </div>
    )
  }

  const navigate = useNavigate()
  return (
    <PermissionGate
      allowedPermissions={[PERMISSIONS.VIEW_MERCHANT_KYC_DETAILS]}
      fallback={
        <div className="p-3 text-danger-default">
          You are not allowed to access this module
        </div>
      }
    >
      <div>
        <TopBar pageTitle={kycDetails.businessName ?? ""} />
        <div className="flex flex-col gap-16 md:flex-row">
          <div className="bg-white p-6 md:flex-1">
            <h5 className="bg-white p-6 text-base font-semibold">
              Business Details
            </h5>
            <div className="flex flex-col gap-5 px-6">
              <MerchantInfo kycDetails={kycDetails} isLoading={isLoading} />
            </div>
          </div>
          <div className=" bg-white p-6 text-base font-semibold  xl:w-[35rem]">
            <h5 className="bg-white p-6 text-base font-semibold">
              KYC Documents
            </h5>
            <div className="flex flex-col gap-8 px-6">
              {loadingDocTypes ? (
                <Skeleton className="my-2 h-8" count={4} />
              ) : (
                <>
                  {documentTypes?.map(
                    (doctype: { description: string; id: string }) =>
                      isSupportedForBusinessType(doctype.id) && (
                        <KYCDoc
                          onClick={() => {
                            const document = getDocument(doctype.id, kycDocs)
                            setSelectedDoc(document)
                            setSelectedDocType(doctype.description)
                          }}
                          docType={doctype.description}
                          key={doctype.id}
                          documentId={doctype.id}
                          uploadedDocuments={kycDocs}
                        />
                      )
                  )}
                </>
              )}
            </div>

            <div className="mt-6">
              <h5 className="mb-3 bg-white p-6 text-base font-semibold">
                BVN Details
              </h5>

              <div className="mb-3 flex flex-col gap-2 px-6">
                {loadingBvnDetails ? (
                  <Skeleton className="my-2 h-8" count={4} />
                ) : (
                  <>
                    <div className="flex gap-5">
                      <span className="font-medium">Title:</span>{" "}
                      <span className="font-normal">{bvnDetails?.title}</span>
                    </div>
                    <div className="flex gap-5">
                      <span className="font-medium">First name:</span>{" "}
                      <span className="font-normal">
                        {bvnDetails?.first_name}
                      </span>
                    </div>
                    <div className="flex gap-5">
                      <span className="font-medium">Middle name:</span>{" "}
                      <span className="font-normal">
                        {bvnDetails?.middle_name}
                      </span>
                    </div>
                    <div className="flex gap-5">
                      <span className="font-medium">Last name:</span>{" "}
                      <span className="font-normal">{bvnDetails?.surname}</span>
                    </div>
                    <div className="flex gap-5">
                      <span className="font-medium">Enroll Username:</span>{" "}
                      <span className="font-normal">
                        {bvnDetails?.enroll_user_name}
                      </span>
                    </div>
                    <div className="flex gap-5">
                      <span className="font-medium">BVN:</span>{" "}
                      <span className="font-normal">{bvnDetails?.bvn}</span>
                    </div>
                    <div className="flex gap-5">
                      <span className="font-medium">NIN:</span>{" "}
                      <span className="font-normal">{bvnDetails?.nin}</span>
                    </div>
                    <div className="flex gap-5">
                      <span className="font-medium">Gender:</span>{" "}
                      <span className="font-normal">{bvnDetails?.gender}</span>
                    </div>
                    <div className="flex gap-5">
                      <span className="font-medium">Date of birth:</span>{" "}
                      <span className="font-normal">
                        {bvnDetails?.DateOfBirth &&
                          formatDate(new Date(bvnDetails.DateOfBirth)).date}
                      </span>
                    </div>
                    <div className="flex gap-5">
                      <span className="font-medium">Nationality:</span>{" "}
                      <span className="font-normal">
                        {bvnDetails?.nationality}
                      </span>
                    </div>
                    <div className="flex gap-5">
                      <span className="font-medium">State of Origin:</span>{" "}
                      <span className="font-normal">
                        {bvnDetails?.state_of_origin}
                      </span>
                    </div>
                    <div className="flex gap-5">
                      <span className="font-medium">LGA of Origin:</span>{" "}
                      <span className="font-normal">
                        {bvnDetails?.lga_of_origin}
                      </span>
                    </div>
                    <div className="flex gap-5">
                      <span className="font-medium">Marital Status:</span>{" "}
                      <span className="font-normal">
                        {bvnDetails?.marital_status}
                      </span>
                    </div>
                    <div className="flex gap-5">
                      <span className="font-medium">Watchlisted:</span>{" "}
                      <span className="font-normal">
                        {bvnDetails?.watchlisted === 0 ? "false" : "true"}
                      </span>
                    </div>
                    <div className="flex gap-5">
                      <span className="font-medium">Additional Info:</span>{" "}
                      <span className="font-normal">
                        {bvnDetails?.additional_info_1}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <PermissionGate
          allowedPermissions={[PERMISSIONS.MANAGE_MERCHANT_KYC_DETAILS]}
        >
          <div className="mt-auto flex justify-between pt-8">
            <BackArrow
              onClick={() => navigate(`/kyc?tabIndex=${getTabIndex()}`)}
              label="Return to KYC Management"
            />
            {isApproved || isDeclined || reviewRequested ? null : (
              <div className="flex flex-col gap-2">
                <Button
                  className="h-9"
                  onClick={() => setShowApprovalDialog(true)}
                >
                  Approve
                </Button>
                <Button
                  onClick={() => setShowAskForReview(true)}
                  className="h-9"
                  variant="outlinePrimary"
                >
                  Ask To Review
                </Button>
                <Button
                  onClick={() => setShowDeclineDialog(true)}
                  className="max-w flex h-9 w-full items-center justify-center"
                  variant="linkPrimary"
                >
                  Decline
                </Button>
              </div>
            )}
          </div>
        </PermissionGate>

        {selectedDoc ? (
          <DocumentDetails
            isOpen={!!selectedDoc}
            selectedDoc={selectedDoc}
            selectedDocType={selectedDocType ?? ""}
            closeDocumentDetails={() => setSelectedDoc(null)}
          />
        ) : null}

        {showApprovalDialog ? (
          <ApproveKYCModal
            isOpen={showApprovalDialog}
            closeApprovalDialog={handleApproveDialogClose}
            kycDetails={kycDetails}
          />
        ) : null}

        {showOTPDialog ? (
          <ApprovalTwoFactor
            isOpen={showOTPDialog}
            closeTwoFactor={() => setShowOTPDialog(false)}
            kycDetails={kycDetails}
          />
        ) : null}

        {showDeclineDialog ? (
          <DeclineKYCModal
            isOpen={showDeclineDialog}
            closeDeclineDialog={() => setShowDeclineDialog(false)}
            kycDetails={kycDetails}
          />
        ) : null}

        {showAskForReview ? (
          <AskForReviewModal
            isOpen={showAskForReview}
            closeAskForReview={() => setShowAskForReview(false)}
            kycDetails={kycDetails}
          />
        ) : null}
      </div>
    </PermissionGate>
  )
}

export default KYCDetails
