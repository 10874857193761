import TopBar from "layout/protected/ContentArea/TopBar"
import React, { FC } from "react"
import Person from "assets/images/person.svg"

const Notifications = () => {
  const Notification: FC<{
    merchantName: string
    message: string
    timeSent: string
    module: string
  }> = ({ merchantName, message, timeSent, module }) => {
    return (
      <div className="flex items-center gap-5">
        <img
          className="h-10 w-10 rounded-full border border-gray-20 object-cover"
          src={Person}
          alt=""
        />
        <div>
          <div className="text-sm">
            <span className="font-bold">{merchantName}</span>{" "}
            <span>{message}</span>
          </div>
          <div className="text-xs text-gray-10/80">
            <span>{timeSent}</span> - <span>{module}</span>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div>
      <TopBar
        pageTitle="Notifications"
        subTitle="All your notifications in one place."
      />
      <div className="mt-12 flex flex-col gap-12 bg-white p-8">
        <div>
          <h4 className="text-base font-semibold">Today</h4>

          <div className="mt-6 space-y-6">
            <Notification
              merchantName="Sally Co."
              message="Submitted their business review"
              timeSent="2h ago"
              module="Disputes"
            />
            <Notification
              merchantName="Emerald Cakes."
              message="raised a transaction dispute"
              timeSent="2h ago"
              module="Disputes"
            />
            <Notification
              merchantName="Frissles Nig. Ltd"
              message="submitted their business KYC review."
              timeSent="2h ago"
              module="Disputes"
            />
            <Notification
              merchantName="Sally Co."
              message="Submitted their business review"
              timeSent="2h ago"
              module="Disputes"
            />
            <Notification
              merchantName="Sally Co."
              message="Submitted their business review"
              timeSent="2h ago"
              module="Disputes"
            />
          </div>
        </div>
        <div>
          <h4 className="text-base font-semibold">Yesterday</h4>

          <div className="mt-6 space-y-6">
            <Notification
              merchantName="Sally Co."
              message="Submitted their business review"
              timeSent="2h ago"
              module="Disputes"
            />
            <Notification
              merchantName="Emerald Cakes."
              message="raised a transaction dispute"
              timeSent="2h ago"
              module="Disputes"
            />
            <Notification
              merchantName="Frissles Nig. Ltd"
              message="submitted their business KYC review."
              timeSent="2h ago"
              module="Disputes"
            />
            <Notification
              merchantName="Sally Co."
              message="Submitted their business review"
              timeSent="2h ago"
              module="Disputes"
            />
            <Notification
              merchantName="Sally Co."
              message="Submitted their business review"
              timeSent="2h ago"
              module="Disputes"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Notifications
