import Button from "components/button"
import CustomSelect from "components/Select/CustomSelect"
import Input from "components/input"
import Modal from "components/modal/Modal"
import React from "react"
import { FieldValues, FormProvider, useForm } from "react-hook-form"
import useRoles from "../../hooks/useRoles"
import { queryKeys } from "constants/queryKeys"
import { createUser } from "services/api/users"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { queryClient } from "index"
import { AxiosError } from "axios"
import { handleError } from "utils/getAxiosErrorMessage"
import { yupResolver } from "@hookform/resolvers/yup"
import { createUserSchema } from "../../validation-schemas/user"

const NewUserDialog: React.FC<{
  isOpen: boolean
  handleClose: () => void
}> = ({ isOpen, handleClose }) => {
  const methods = useForm({
    resolver: yupResolver(createUserSchema),
  })
  const errors = methods.formState.errors
  const { adminRoles, loadingAdminRoles } = useRoles({
    queryKey: queryKeys.users.fetchAdminRoles,
  })

  const { mutate: handleCreateUser, isLoading: creatingUser } = useMutation(
    createUser,
    {
      onSuccess: (response) => {
        toast.success(response?.data?.message)
        queryClient.invalidateQueries(queryKeys.users.fetchUsers)
        handleClose()
      },
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    }
  )

  const onSubmit = (data: FieldValues) => {
    const payload = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      defaultRole: true,
      roleId: data.role.value,
      role: data.role.label,
      id: 0,
      businessId: 0,
      mobileNumber: "",
    }
    handleCreateUser(payload)
  }

  const sortedAdminRoles = adminRoles?.sort((a, b) => {
    const nameA = a.name.toLowerCase()
    const nameB = b.name.toLowerCase()

    if (nameA < nameB) {
      return -1 // a should come before b
    } else if (nameA > nameB) {
      return 1 // a should come after b
    } else {
      return 0 // names are equal
    }
  })

  return (
    <Modal contentContainerClass="p-6" isOpen={isOpen} closeModal={handleClose}>
      <div className="mb-6">
        <h5 className="text-lg font-medium">Create New User</h5>
        <div className="text-xs font-normal">
          Create a new TGI Pay admin user.
        </div>
      </div>

      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          autoComplete="off"
          className="flex flex-col gap-4"
        >
          <Input label="First Name" name="firstName" />
          <Input label="Last Name" name="lastName" />
          <Input label="User Email Address" name="email" />
          <CustomSelect
            label="Role"
            options={sortedAdminRoles?.map((role) => {
              return {
                ...role,
                value: role.id.toString(),
                label: role.name,
              }
            })}
            name="role"
            isLoading={loadingAdminRoles}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            error={errors.role?.value?.message}
          />
          <div className="mt-6 flex justify-end">
            <Button loading={creatingUser}>Create New User</Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default NewUserDialog
