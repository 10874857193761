import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import style from "./Menu.module.scss"
import { ReactComponent as DashboardIcon } from "../../../assets/icons/dashboard.svg"
import { ReactComponent as KYCIcon } from "../../../assets/icons/kyc.svg"
import { ReactComponent as TransactionsIcon } from "../../../assets/icons/transactions.svg"
import { ReactComponent as SettlementIcon } from "../../../assets/icons/settlement.svg"
import { ReactComponent as TeamsIcon } from "../../../assets/icons/teams.svg"
import { ReactComponent as DisputesIcon } from "../../../assets/icons/disputes.svg"
import { ReactComponent as AuditTrailIcon } from "../../../assets/icons/audit-trail.svg"
import { ReactComponent as SettingsIcon } from "../../../assets/icons/settings.svg"
import PermissionGate from "components/PermissionGate/PermissionGate"
import { PERMISSIONS } from "constants/permissions"

type MenuT = {
  name: string
  linkUrl: string
  Icon: React.FC<{
    className?: string
  }>
  allowedPermissions?: string[]
}
const menus: MenuT[] = [
  {
    name: "Dashboard",
    linkUrl: "/dashboard",
    Icon: DashboardIcon,
  },
  {
    name: "KYC Management",
    linkUrl: "/kyc",
    Icon: KYCIcon,
    allowedPermissions: [
      PERMISSIONS.VIEW_MERCHANT_KYC_DETAILS,
      PERMISSIONS.MANAGE_MERCHANT_KYC_DETAILS,
    ],
  },
  {
    name: "Transactions",
    linkUrl: "/transactions",
    Icon: TransactionsIcon,
    allowedPermissions: [PERMISSIONS.VIEW_TRANSACTIONS],
  },
  {
    name: "Settlement",
    linkUrl: "/settlement",
    Icon: SettlementIcon,
    allowedPermissions: [PERMISSIONS.MANAGE_SETTLEMENT_DISPUTES],
  },
  {
    name: "Merchants",
    linkUrl: "/merchants",
    Icon: TeamsIcon,
    allowedPermissions: [
      PERMISSIONS.VIEW_MERCHANTS,
      PERMISSIONS.MANAGE_MERCHANTS,
    ],
  },
  {
    name: "Teams",
    linkUrl: "/teams",
    Icon: TeamsIcon,
    allowedPermissions: [
      PERMISSIONS.VIEW_ADMIN_ROLES,
      PERMISSIONS.CREATE_ADMIN_ROLES,
      PERMISSIONS.MANAGE_ADMIN_ROLES,
      PERMISSIONS.VIEW_MERCHANT_ROLES,
      PERMISSIONS.CREATE_MERCHANT_ROLES,
      PERMISSIONS.MANAGE_MERCHANT_ROLES,
    ],
  },
  {
    name: "Disputes",
    linkUrl: "/disputes",
    Icon: DisputesIcon,
    allowedPermissions: [
      PERMISSIONS.VIEW_DISPUTES,
      PERMISSIONS.MANAGE_DISPUTES,
      PERMISSIONS.MANAGE_SETTLEMENT_DISPUTES,
      PERMISSIONS.MANAGE_TRANSACTION_DISPUTES,
    ],
  },
  {
    name: "Audit Trail",
    linkUrl: "/audit-trail",
    Icon: AuditTrailIcon,
    allowedPermissions: [PERMISSIONS.VIEW_AUDIT_TRAIL],
  },
  {
    name: "Configuration",
    linkUrl: "/configuration",
    Icon: SettingsIcon,
  },
  {
    name: "Workflow Setup",
    linkUrl: "/workflow-setup",
    Icon: SettingsIcon,
  },
]
const Menu: React.FC = () => {
  const navigate = useNavigate()

  const MenuItem: React.FC<MenuT> = ({
    name,
    linkUrl,
    Icon,
    allowedPermissions,
  }) => {
    const location = useLocation()
    const isCurrentRoute = location.pathname.includes(linkUrl)

    if (!allowedPermissions)
      return (
        <li
          className={`${style.menu} ${
            isCurrentRoute && style.active
          } relative flex h-11 cursor-pointer items-center gap-4 pl-4 
      text-xs leading-5 hover:font-bold hover:text-primary-default`}
          onClick={() => {
            navigate({ pathname: linkUrl })
          }}
        >
          <Icon className="w-5" />
          {name}
        </li>
      )

    return (
      <PermissionGate allowedPermissions={allowedPermissions}>
        <li
          className={`${style.menu} ${
            isCurrentRoute && style.active
          } relative flex h-11 cursor-pointer items-center gap-4 pl-4
        text-xs leading-5 hover:font-bold hover:text-primary-default`}
          onClick={() => {
            navigate({ pathname: linkUrl })
          }}
        >
          <Icon className="w-5" />
          {name}
        </li>
      </PermissionGate>
    )
  }
  return (
    <ul>
      {menus.map((menu, index) => (
        <MenuItem
          name={menu.name}
          linkUrl={menu.linkUrl}
          Icon={menu.Icon}
          key={`menu-item-${index}`}
          allowedPermissions={menu.allowedPermissions}
        />
      ))}
    </ul>
  )
}

export default Menu
