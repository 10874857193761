import React from "react"
import { ActivityType } from "../types/activity"
import ActivitySkeleton from "./ActivitySkeleton"
import { customDateRanges } from "../constants/customDateRanges"

const Activity: React.FC<{
  activities: ActivityType[]
  loading: boolean
  rangeType: string
  startDate: Date | null
  endDate: Date | null
}> = ({ activities, loading, rangeType, startDate, endDate }) => {
  const renderActivity = (activity: ActivityType, index: number) => {
    return (
      <li className="flex gap-6 text-xs" key={index}>
        <div className="w-12">{activity.time}</div>
        <div className="flex-1">{activity.description}</div>
      </li>
    )
  }

  const renderRangetype = () => {
    if (rangeType !== "custom") {
      return customDateRanges[rangeType]
    }
    return `${startDate?.toDateString() ?? ""} - ${
      endDate?.toDateString() ?? ""
    }`
  }

  return (
    <div className="min-h-[17rem] bg-white p-4 shadow-lg">
      <h6 className="text-lg font-semibold">Activity</h6>
      <p className="my-4 text-xs">{renderRangetype()}</p>
      <ul className="space-y-5 text-gray-10">
        {loading ? (
          [...Array(4)].map((_item, index) => <ActivitySkeleton key={index} />)
        ) : (
          <>
            {activities
              .slice(0, 4)
              .map((activity, index) => renderActivity(activity, index))}
          </>
        )}
      </ul>
    </div>
  )
}

export default Activity
