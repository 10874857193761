import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { default as uiReducer } from "./ui/uiSlice"
import { default as profileReducer } from "./profile/profileSlice"
import { persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"

// const persistConfig = {
//   key: "root",
//   storage,
// }

// export const store = configureStore({
//   reducer: {
//     ui: uiReducer,
//   },
// })

// export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}

const persistConfig = {
  key: "root",
  storage,
  // whitelist: ["profileReducer"],
}

const rootReducer = combineReducers({
  ui: uiReducer,
  user: profileReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
