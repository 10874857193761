import { createSlice } from "@reduxjs/toolkit"

type ProfilePropT = {
  firstName: string
  lastName: string
  email: string
  id: number | null
  businessId: number | null
  profilePicture: string | null
  mobileNumber: string
  roleId: number | null
  roleName: null
  customRoleId: number
  customRoleName: string
  authorizations: string[]
}

interface BreadcrumbState {
  profile?: ProfilePropT
}

const initialState: BreadcrumbState = {
  profile: {
    firstName: "",
    lastName: "",
    email: "",
    id: 0,
    businessId: 0,
    profilePicture: null,
    mobileNumber: "",
    roleId: null,
    roleName: null,
    customRoleId: 0,
    customRoleName: "",
    authorizations: [],
  },
}

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    saveProfileDetails: (state, action) => {
      state.profile = { ...action.payload.userDetails, profilePicture: null }
    },
  },
})

export const { saveProfileDetails } = profileSlice.actions

export default profileSlice.reducer
