import React, { FC } from "react"

interface FullScreenLoaderProps {
  loading: boolean
  message?: string
}

const FullScreenLoader: FC<FullScreenLoaderProps> = ({
  loading,
  message = "Loading...",
}) => {
  if (!loading) return null

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center text-center">
        <div className="bg-white-default absolute inset-0 opacity-75"></div>
        <div className="relative z-50 h-64 w-64">
          <div className="mx-auto h-32 w-32 animate-spin rounded-full border-b-2 border-t-2 border-primary-default"></div>
          <div className="mt-4 text-primary-default">{message}</div>
        </div>
      </div>
    </div>
  )
}

export default FullScreenLoader
