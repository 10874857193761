import { workflowEndpoints } from "../../constants/endpoints"
import { WorkflowAxiosInstance } from "services/axios/workflow"
import env from "config/env"
import { AxiosResponse } from "axios"
import { WorflowResponse } from "types/workflow"

export const createWorkflow = async (payload: {
  name: string
  numberOfLevel: number
}) => {
  const response = await WorkflowAxiosInstance.post(
    workflowEndpoints.createWorkflow,
    { ...payload, clientAppCode: env.WORKFLOW_CLIENT_CODE }
  )
  return response
}

export const editWorkflow = async (payload: {
  id: string
  name: string
  numberOfLevel: number
}) => {
  const response = await WorkflowAxiosInstance.put(
    `${workflowEndpoints.editWorkflow}`,
    payload
  )
  return response
}

export const fetchWorkflow = async (): Promise<
  AxiosResponse<WorflowResponse, undefined>
> => {
  const response = await WorkflowAxiosInstance.get(
    `${workflowEndpoints.fetchWorkflow}/${env.WORKFLOW_CLIENT_CODE}`
  )
  return response
}

export const createWorkflowLevels = async (payload: {
  workFlowCode: string
  workflowAuthorizerRequestDtoList: {
    authorizers: any[]
    authorizationLevel: any
  }[]
}) => {
  const response = await WorkflowAxiosInstance.post(
    `${workflowEndpoints.createWorkflowLevels}`,
    payload
  )
  return response
}

export const updateWorkflowAuthorizers = async (payload: {
  authorizationLevel: number
  email: string
  workFlowCode: string
  add: boolean
}) => {
  const response = await WorkflowAxiosInstance.put(
    `${workflowEndpoints.updateWorkflowAuthorizers}`,
    { ...payload }
  )
  return response
}

export const fetchWorkflowConfig = async (id: string | undefined) => {
  const response = await WorkflowAxiosInstance.get(
    `${workflowEndpoints.fetchWorkflowConfig}/${id}`
  )
  return response
}

export const approveWorkflowRequest = async (approvalPayload: {
  approvalRequestCode: string
  workFlowCode: string
  clientAppCode: string
}) => {
  return WorkflowAxiosInstance.post(
    `${workflowEndpoints.approveRequest}`,
    approvalPayload
  )
}

export const declineWorkflowRequest = async (rejectionPayload: {
  approvalRequestCode: string
  workFlowCode: string
  clientAppCode: string
  reason: string
}) => {
  return WorkflowAxiosInstance.post(
    workflowEndpoints.declineRequest,
    rejectionPayload
  )
}
