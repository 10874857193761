import React from "react"
import TopBar from "layout/protected/ContentArea/TopBar"
import PermissionGate from "components/PermissionGate/PermissionGate"
import { PERMISSIONS } from "constants/permissions"
import ListAccessDenied from "components/PermissionGate/ListAccessDenied"
import AuditFilter from "./Filter/AuditFilter"
import Table from "components/Table"
import { auditColumns } from "./Table/columns"
import useAudit from "./hooks/useAudit"
import { queryKeys } from "constants/queryKeys"
import PaginationInfo from "components/Pagination/PaginationInfo"
import { Pagination } from "components/Pagination/PaginationComponent"
import Button from "components/button"
import { baseUrl } from "config/baseUrl"
import { auditEndpoints } from "constants/endpoints"
import AuditPDF from "./pdf/AuditPDF"
import useExportModal from "hooks/useExportModal"

const AuditTrail = () => {
  const { audit, filter, search, pagination, exportManager } = useAudit(
    queryKeys.audit.fetchAudit
  )

  const { ExportModal, pdfContainer } = useExportModal({
    closeModal: () => exportManager.setShowExportModal(false),
    isLoading: audit.isLoadingPDF,
    isOpen: exportManager.showExportModal,
    downloadUrl: `${baseUrl.adminUserService}${auditEndpoints.downloadAudit}`,
    exportDataName: "Audit Trail",
    downloadParams: {
      status: search.searchParams.status?.value,
      startDate: search.searchParams.startDate,
      endDate: search.searchParams.endDate,
      activity: search.searchParams?.search?.trim(),
      role: search.searchParams.role?.value,
    },
  })

  return (
    <PermissionGate
      allowedPermissions={[PERMISSIONS.VIEW_AUDIT_TRAIL]}
      fallback={<ListAccessDenied />}
    >
      <div>
        <TopBar
          pageTitle="Audit Trail"
          subTitle="View the audit trails of admin user activities."
        />
        <div className="flex items-center justify-end gap-6 pt-2">
          <AuditFilter filter={filter} search={search} />
        </div>
        <div className="mt-4 border border-[#D3D3D3]">
          <Table
            columns={auditColumns}
            data={audit.auditList}
            isLoading={audit.isLoading}
            loadingSkeletonCount={10}
          />
          {audit.auditList?.length > 0 ? (
            <div className="flex h-[4.3125rem] items-center justify-between bg-white px-5">
              <PaginationInfo pagination={pagination} />

              <div>
                <Pagination pagination={pagination} />
              </div>
            </div>
          ) : null}
        </div>

        {audit.auditList?.length > 0 ? (
          <Button
            className="mt-9 w-[6rem]"
            onClick={() => exportManager.setShowExportModal(true)}
          >
            Export
          </Button>
        ) : null}

        {exportManager.showExportModal ? <ExportModal /> : null}
        {exportManager.showExportModal && (
          <AuditPDF pdfContainerRef={pdfContainer} />
        )}
      </div>
    </PermissionGate>
  )
}

export default AuditTrail
