import React, { useState } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import { useNavigate } from "react-router-dom"
import Modal from "components/modal/Modal"
import { KYC } from "types/KYC"

const columnHelper = createColumnHelper<KYC>()

export const KYCColumns = [
  columnHelper.accessor("businessName", {
    cell: (info) => info.getValue(),
    header: () => <span>Business Name</span>,
  }),
  columnHelper.accessor((row) => row.businessDescription, {
    id: "businessDescription",
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <span>Business Description</span>,
  }),
  columnHelper.accessor("businessType", {
    header: () => "Business Type",
    cell: (info) => <span>{info.renderValue()}</span>,
  }),
  columnHelper.accessor("country", {
    header: "Country",
  }),
  columnHelper.accessor("state", {
    header: "State",
  }),
  columnHelper.accessor("action", {
    header: "Action",
    cell: ({ cell }) => {
      const navigate = useNavigate()
      const [showModal, setShowModal] = useState(false)
      function closeModal() {
        setShowModal(false)
      }
      return (
        <>
          <span
            onClick={() => {
              navigate(
                `details?kycInfo=${encodeURIComponent(
                  JSON.stringify(cell.row.original)
                )}`
              )
              setShowModal(true)
            }}
            className="cursor-pointer font-semibold text-primary-default"
          >
            View
          </span>
          <Modal isOpen={showModal} closeModal={closeModal}>
            <div>What do you want to put here?</div>
          </Modal>
        </>
      )
    },
  }),
]
