import React, { useState } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import SettlementApprovalModal from "../Details/ApprovalRequestDetails"
import { numberUtils } from "utils/numberUtils"
import ApprovalTwoFactor from "../Details/TwoFactor"
import { useOTP } from "hooks/useOTP"
import { useAuthUser } from "hooks/useAuthUser"

const columnHelper = createColumnHelper<SettlementApprovalRequestType>()

export const approvalRequestColumns = [
  columnHelper.accessor("merchantId", {
    cell: (info) => info.getValue(),
    header: () => <span>Merchant ID</span>,
  }),
  columnHelper.accessor((row) => row.merchantName, {
    id: "merchantName",
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <span>Merchant Name</span>,
  }),
  columnHelper.accessor("totalTransactionAmount", {
    header: () => <span>Total Transaction Amount</span>,
    cell: (info) => (
      <span>{numberUtils.formatCustomAmount(info.getValue(), "NGN")}</span>
    ),
  }),
  columnHelper.accessor("action", {
    header: "Action",
    cell: ({ cell }) => {
      const {
        row: { original: originalData },
      } = cell
      const { otp } = useOTP()
      const { authUser } = useAuthUser()

      const settlementData = originalData
      const [showModal, setShowModal] = useState(false)
      const [showOTPDialog, setShowOTPDialog] = useState(false)
      const [operation, setOperation] = useState<"approve" | "decline">(
        "approve"
      )

      function closeModal() {
        setShowModal(false)
      }

      const handleApprove = () => {
        closeModal()
        if (authUser?.email) {
          otp.generate.handleOTPGeneration(authUser.email, {
            onSuccess: () => {
              setShowOTPDialog(true)
              setOperation("approve")
            },
          })
        }
      }

      const handleDecline = () => {
        closeModal()
        if (authUser?.email) {
          otp.generate.handleOTPGeneration(authUser.email, {
            onSuccess: () => {
              setShowOTPDialog(true)
              setOperation("decline")
            },
          })
        }
      }

      const close2fa = () => {
        setShowOTPDialog(false)
      }

      return (
        <>
          <button
            onClick={() => {
              setShowModal(true)
            }}
            className="cursor-pointer font-semibold text-primary-default"
          >
            View
          </button>

          <SettlementApprovalModal
            isOpen={showModal}
            closeModal={closeModal}
            settlementData={originalData}
            handleApprove={handleApprove}
            handleDecline={handleDecline}
          />

          <ApprovalTwoFactor
            isOpen={showOTPDialog}
            closeTwoFactor={close2fa}
            settlementData={settlementData}
            operation={operation}
          />
        </>
      )
    },
  }),
]
