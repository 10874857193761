import { UserAxiosInstance } from "services/axios/userApi"
import { configEndpoints } from "../../constants/endpoints"

export const fetchChannels = async () => {
  const response = await UserAxiosInstance.get(
    `${configEndpoints.fetchChannels}`
  )
  return response
}

export const editChannel = async (payload: {
  id: number
  name: string
  feeType: string
  flatFee: number
  feePercentage: number
  feeCap: number
  currency: number | string
}) => {
  const response = await UserAxiosInstance.put(configEndpoints.createChannel, {
    ...payload,
  })
  return response
}

export const updateChannelStatus = async (payload: {
  activate: boolean
  channelId: number
}) => {
  const { activate, channelId } = payload
  const response = await UserAxiosInstance.put(
    `${configEndpoints.updateChannelStatus}/${channelId}/${activate}`
  )
  return response
}

export const fetchFeeSettings = async () => {
  const response = await UserAxiosInstance.get(
    `${configEndpoints.fetchFeeSettings}`
  )
  return response
}

export const fetchSettlement = async () => {
  const response = await UserAxiosInstance.get(
    `${configEndpoints.fetchSettlement}`
  )
  return response
}

export const createSettlement = async (payload: {
  name: string
  type: string
  startTime: string
  maximumAmount: number
}) => {
  const response = await UserAxiosInstance.post(
    configEndpoints.updateSettlement,
    {
      ...payload,
    }
  )
  return response
}

export const editSettlement = async (payload: {
  id: number
  name: string
  type: string
  startTime: string
  maximumAmount: number
}) => {
  const response = await UserAxiosInstance.put(
    configEndpoints.updateSettlement,
    {
      ...payload,
    }
  )
  return response
}

export const fetchEmailSettings = async () => {
  const response = await UserAxiosInstance.get(
    `${configEndpoints.fetchEmailSettings}`
  )
  return response
}

export const createEmail = async (payload: {
  email: string
  description: string
}) => {
  const response = await UserAxiosInstance.post(
    configEndpoints.createEmailSettings,
    {
      ...payload,
    }
  )
  return response
}

export const editEmail = async (payload: {
  id: number
  email: string
  description: string
}) => {
  const response = await UserAxiosInstance.put(
    configEndpoints.updateEmailSettings,
    {
      ...payload,
    }
  )
  return response
}
