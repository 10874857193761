import { AxiosError } from "axios"
import Button from "components/button"
import { ReactComponent as ExclamationWarning } from "assets/icons/merchants/exclamation-warning.svg"
import Modal from "components/modal/Modal"
import React, { useState } from "react"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { queryClient } from "index"
import { queryKeys } from "constants/queryKeys"
import { markoffSettlement } from "services/api/settlement"
import { useNavigate } from "react-router-dom"
import { STATUS } from "types/statuses"
import Dropzone from "components/dropzone"

type SettlementStatusUpdaterProps = {
  isOpen: boolean
  closeStatusDialog: () => void
  settlement: SettlementDetailsType
}
const SettlementStatusUpdater: React.FC<SettlementStatusUpdaterProps> = ({
  isOpen,
  closeStatusDialog,
  settlement,
}) => {
  const navigate = useNavigate()
  const [settlementEvidence, setSettlementEvidence] = useState<File | null>(
    null
  )
  const [fileError, setFileError] = useState<string | null>(null)

  const handleDrop = (files: File[]) => {
    setFileError(null)
    setSettlementEvidence(files[0])
  }

  const { mutate: handleMarkoff, isLoading } = useMutation(markoffSettlement, {
    onSuccess: (response) => {
      toast.success(response.data.message)
      closeStatusDialog()
      queryClient.invalidateQueries(queryKeys.configuration.fetchSettlement)
      navigate(
        `/settlement/details?settlementInfo=${encodeURIComponent(
          JSON.stringify({
            ...settlement,
            status: STATUS.SETTLEMENT.SUCCESSFUL,
          })
        )}`
      )
    },
    onError: (error: AxiosError) => {
      toast.error(error.message)
    },
  })

  const handleSettlementMarkoff = () => {
    if (settlementEvidence === null) {
      setFileError("Upload evidence of settlement")
      return
    }

    const formdata = new FormData()
    formdata.append("document", settlementEvidence)
    formdata.append("settlementId", settlement.id.toString())
    formdata.append("amount", settlement.totalPayoutAmount.toString())

    handleMarkoff(formdata)
  }

  return (
    <Modal isOpen={isOpen} closeModal={closeStatusDialog}>
      <div className="flex flex-col items-center p-6">
        <div className="flex max-w-xs flex-col items-center">
          <ExclamationWarning className="mb-5" />
          <h5 className="text-xl font-semibold ">Markoff Settlement</h5>
          <span className="mt-4 text-center text-sm font-normal text-gray-10/50">
            You are about to mark off this transaction as settled. This action
            cannot be reversed. Are you sure?.
          </span>

          <div className=" mt-6 w-[100%] md:mt-5">
            <div className="mb-3">
              <label className="text-black-default font-medium">
                Upload Document
              </label>
            </div>

            <Dropzone
              acceptedFileTypes={{
                "image/jpeg": [],
                "image/png": [],
                "application/pdf": [],
              }}
              onDrop={(files) => handleDrop(files)}
            />

            {settlementEvidence ? <div>{settlementEvidence.name}</div> : null}

            <span className="text-sm text-danger-default">{fileError}</span>
          </div>
          <div className="mt-8 flex w-full max-w-xs justify-center gap-10">
            <Button
              onClick={closeStatusDialog}
              variant="default"
              className="h-9 w-24 text-sm"
            >
              Cancel
            </Button>
            <Button
              loading={isLoading}
              onClick={handleSettlementMarkoff}
              className="h-9 w-24 text-sm"
              variant="primary"
            >
              Proceed
            </Button>
          </div>
        </div>
      </div>{" "}
    </Modal>
  )
}

export default SettlementStatusUpdater
