import React from "react"
import { ReactComponent as ChannelsIcon } from "assets/icons/configuration/channels.svg"
import { ReactComponent as SettlementIcon } from "assets/icons/configuration/settlement.svg"
import TopBar from "layout/protected/ContentArea/TopBar"
import CustomTab from "components/Tab/Tab"
import Settlement from "./modules/Settlement/Settlement"
import Channels from "./modules/Channels/Channels"
import { useSearchParams } from "react-router-dom"
import { configPages } from "./constants/config-pages"
import CreateChannel from "./modules/Channels/CreateChannel"
import CreateFee from "./modules/FeeSettings/CreateFee"
import CreateSettlement from "./modules/Settlement/CreateSettlement"
import EmailSettings from "./modules/Email/Email"
import CreateEmail from "./modules/Email/CreateEmail"

const Configuration = () => {
  const [searchParams] = useSearchParams()
  const tabIndex = searchParams.get("tabIndex")
  const currentPage = searchParams.get("page")

  if (currentPage === configPages.ADD_CHANNEL) {
    return <CreateChannel />
  }

  if (currentPage === configPages.ADD_FEE) {
    return <CreateFee />
  }

  if (currentPage === configPages.ADD_SETTLEMENT) {
    return <CreateSettlement />
  }
  if (currentPage === configPages.ADD_EMAIL) {
    return <CreateEmail />
  }

  return (
    <div>
      <TopBar
        pageTitle="Configurations"
        subTitle="Manage TGI Pay payment channels."
      />
      <div className="mt-8 max-w-[65.25rem] rounded-lg bg-white p-7 2xl:max-w-full">
        <CustomTab
          defaultIndex={Number(tabIndex)}
          tabs={[
            {
              label: "Channels",
              icon: <ChannelsIcon />,
            },

            {
              label: "Settlement Settings",
              icon: <SettlementIcon />,
            },
            {
              label: "Email Settings",
              icon: <SettlementIcon />,
            },
          ]}
          panels={[
            {
              content: <Channels tabIndex={0} />,
            },

            {
              content: <Settlement tabIndex={1} />,
            },
            {
              content: <EmailSettings tabIndex={2} />,
            },
          ]}
        />
      </div>
    </div>
  )
}

export default Configuration
