import { yupResolver } from "@hookform/resolvers/yup"
import { AxiosError } from "axios"
import Button from "components/button"
import Input from "components/input"
import { PublicLayout } from "layout"
import { resetPasswordSchema } from "pages/public/ForgotPassword/validation-schemas"
import React, { useEffect } from "react"
import { FieldValues, FormProvider, useForm } from "react-hook-form"
import { useMutation } from "react-query"
import { useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import { resetPassword } from "services/api/auth"
import { handleError } from "utils/getAxiosErrorMessage"

const SetPassword = () => {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const code = searchParams.get("code")

  useEffect(() => {
    if (!code) {
      navigate("/invalid-reset-link")
    }
  }, [code])

  const methods = useForm({
    resolver: yupResolver(resetPasswordSchema),
  })
  const { handleSubmit } = methods

  const { mutate: handlePasswordReset, isLoading } = useMutation(
    resetPassword,
    {
      onSuccess: () => {
        toast.success(
          "Account activated successfully. Please login to continue. "
        )
        navigate("/")
      },
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    }
  )

  const onSubmit = (data: FieldValues) => {
    handlePasswordReset({
      accessKey: code,
      password: data.password,
      repeatPassword: data.confirmPassword,
    })
  }

  return (
    <PublicLayout
      mainTitle="Set Password"
      contentArea={
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Input label="New Password" name="password" type="password" />

            <Input
              label="Confirm New Password"
              type="password"
              name="confirmPassword"
              inputContainerProps={{ className: "mt-8" }}
            />

            <Button
              loading={isLoading}
              className="mt-8 w-full"
              variant="primary"
            >
              Confirm
            </Button>
          </form>
        </FormProvider>
      }
    />
  )
}

export default SetPassword
