import { AxiosError } from "axios"
import Button from "components/button"
import Modal from "components/modal/Modal"
import React from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { handleError } from "utils/getAxiosErrorMessage"
import { queryKeys } from "constants/queryKeys"
import { queryClient } from "index"
import { DisputeDetails } from "types/Dispute"
import { ReactComponent as ExclamationWarning } from "assets/icons/merchants/exclamation-warning.svg"
import { resolveDispute } from "services/api/dispute"
import { useNavigate } from "react-router-dom"
import { STATUS } from "types/statuses"

type AskForReviewProps = {
  isOpen: boolean
  closeDisputeApproval: () => void
  disputeDetails: DisputeDetails
}
const ResolveDispute: React.FC<AskForReviewProps> = ({
  isOpen,
  closeDisputeApproval,
  disputeDetails,
}) => {
  const methods = useForm()
  const navigate = useNavigate()

  const { mutate: handleDisputeResolution, isLoading } = useMutation(
    resolveDispute,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(queryKeys.dispute.fetchDispute)
        navigate(
          `/disputes/details?dispute=${encodeURIComponent(
            JSON.stringify({
              ...disputeDetails,
              resolutionStatus: STATUS.DISPUTE.RESOLVED,
            })
          )}`
        )
        toast.success(response.data.message)
        closeDisputeApproval()
      },
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    }
  )

  const handleClose = () => {
    methods.reset()
    closeDisputeApproval()
  }

  return (
    <Modal isOpen={isOpen} closeModal={handleClose}>
      <div className="flex flex-col items-center p-6">
        <div className="flex max-w-xs flex-col items-center">
          <ExclamationWarning className="mb-5" />
          <h5 className="text-xl font-semibold ">Resolve Dispute?</h5>
          <span className="mt-4 text-center text-sm font-normal text-gray-10/50">
            Are you sure you want to resolve this dispute?
          </span>
          <div className="mt-8 flex w-full max-w-xs justify-center gap-10">
            <Button
              onClick={closeDisputeApproval}
              variant="default"
              className="h-9 w-24 text-sm"
            >
              Cancel
            </Button>
            <Button
              loading={isLoading}
              onClick={() =>
                handleDisputeResolution({
                  disputeId: disputeDetails.id,
                })
              }
              className="h-9 w-24 text-sm"
              variant="primary"
            >
              Proceed
            </Button>
          </div>
        </div>
      </div>{" "}
    </Modal>
  )
}

export default ResolveDispute
