import { AxiosResponse } from "axios"
import { UserAxiosInstance } from "../axios/userApi"
import { MerchantDetails } from "types/merchants"
import { merchantEndpoints } from "../../constants/endpoints"

export const fetchMerchantData = async (payload: {
  currentPage: number
  pageSize: number
  search: string
}) => {
  const response = await UserAxiosInstance.get(
    `${merchantEndpoints.fetchMerchantData}?page=${payload.currentPage}&size=${payload.pageSize}&${payload.search}`
  )
  return response
}

export const fetchMerchantApprovals = async () => {
  const response = await UserAxiosInstance.get(
    `${merchantEndpoints.fetchMerchantApprovals}`
  )
  return response
}

export const fetchMerchantDetails = async (
  merchantId: number | null | string | undefined
): Promise<
  AxiosResponse<{
    message: string
    status: true
    data: MerchantDetails
  }>
> => {
  const response = await UserAxiosInstance.get(
    `${merchantEndpoints.fetchMerchantDetails}/${merchantId}`
  )
  return response
}

export const fetchMerchantUserCount = async (payload: {
  merchantId: string | number | undefined | null
}) => {
  const response = await UserAxiosInstance.get(
    `${merchantEndpoints.fetchMerchantUserCount}/${payload.merchantId}`
  )
  return response
}

export const fetchMerchantAdmin = async (payload: {
  merchantId: string | number | undefined | null
  userId: string | number | undefined | null
}) => {
  const response = await UserAxiosInstance.get(
    `${merchantEndpoints.fetchMerchantAdmin}/${payload.merchantId}/${payload.userId}`
  )
  return response
}

export const fetchMerchantBvnDetails = async (payload: {
  merchantId: string | number | undefined | null
}) => {
  const response = await UserAxiosInstance.get(
    `${merchantEndpoints.fetchMerchantBvnDetails}/${payload.merchantId}`
  )
  return response
}

export const fetchSettlementAccount = async (payload: {
  merchantId: string | number | undefined | null
}) => {
  const response = await UserAxiosInstance.get(
    `${merchantEndpoints.fetchSettlementAccount}/${payload.merchantId}`
  )
  return response
}

export const toggleMerchantStatus = async (payload: {
  merchantId: string | null
  enable: boolean
  reason: string
}) => {
  const response = await UserAxiosInstance.put(
    `${merchantEndpoints.toggleMerchantStatus}?merchantId=${payload.merchantId}&enable=${payload.enable}`,
    {
      reason: payload.reason,
    }
  )
  return response
}

export const fetchMerchantTransactions = async (payload: {
  currentPage: number
  pageSize: number
  merchantId: number | undefined | null
}) => {
  const response = await UserAxiosInstance.get(
    `${merchantEndpoints.fetchMerchantTransactions}?merchantId=${payload.merchantId}&page=${payload.currentPage}&size=${payload.pageSize}`
  )
  return response
}

export const fetchMerchantTransactionDetails = async (payload: {
  merchantId: number | undefined | null
  transactionId: string | null | undefined
}) => {
  const response = await UserAxiosInstance.get(
    `${merchantEndpoints.fetchMerchantTransactionDetails}/${payload.merchantId}/${payload.transactionId}`
  )
  return response
}

export const fetchTotalTxValue = async (payload: {
  merchantId: number | undefined | null
}) => {
  const response = await UserAxiosInstance.get(
    `${merchantEndpoints.fetchTotalTxValue}/${payload.merchantId}`
  )
  return response
}

export const fetchTotalSuccessfulTx = async (payload: {
  merchantId: number | undefined | null
}) => {
  const response = await UserAxiosInstance.get(
    `${merchantEndpoints.fetchTotalSuccessfulTx}/${payload.merchantId}`
  )
  return response
}
