import Modal from "components/modal/Modal"
import React from "react"
import { settlementTxColumns } from "../Table/settlementTxColumns"
import useSettlement from "../hooks/useSettlement"
import { queryKeys } from "constants/queryKeys"
import Table from "components/Table"
import PaginationInfo from "components/Pagination/PaginationInfo"
import { Pagination } from "components/Pagination/PaginationComponent"
import PayoutTxFilter from "./PayoutTxFilter"

type Props = {
  showPayoutTx: boolean
  closePayoutTx: () => void
  settlementId: number
}
const PayoutTransactions: React.FC<Props> = ({
  showPayoutTx,
  closePayoutTx,
  settlementId,
}) => {
  const { settlementTx, txPagination, filter, search } = useSettlement({
    queryKey: queryKeys.settlement.fetchPayouts,
    settlementId,
  })

  return (
    <Modal
      isOpen={showPayoutTx}
      closeModal={closePayoutTx}
      contentContainerClass="max-w-screen-xl px-6"
    >
      <div className="min-h-[32rem]">
        <h4 className="py-6 text-xl font-semibold">Payout Transactions</h4>

        <div className="mb-4 flex items-center justify-end gap-6 pt-2">
          <PayoutTxFilter filter={filter} search={search} />
        </div>
        <Table
          columns={settlementTxColumns}
          data={settlementTx.settlementTxList}
          isLoading={
            settlementTx.isLoadingPayout || settlementTx.refetchingPayout
          }
          loadingSkeletonCount={6}
        />
        {settlementTx.settlementTxList?.length > 0 ? (
          <div className="flex h-[4.3125rem]  items-center justify-between bg-white px-5">
            <PaginationInfo pagination={txPagination} />

            <div>
              <Pagination pagination={txPagination} />
            </div>
          </div>
        ) : null}
      </div>
    </Modal>
  )
}

export default PayoutTransactions
