import Button from "components/button"
import BackArrow from "components/button/BackArrow"
import { DataRow } from "components/DataRow/DataRow"
import Status from "components/Status/Status"
import TopBar from "layout/protected/ContentArea/TopBar"
import React, { useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import DisputeHistory from "./DisputeHistory"
import ResolveDispute from "./ResolveDispute"
import TransactionDetails from "./TransactionDetails"
import { Dispute } from "types/Dispute"
import { parseJSON } from "utils/parseJSON"
import { STATUS } from "types/statuses"
import DisputeEvidence from "./DisputeEvidence"

export type DisputeEvidenceType = {
  filePath: string
  contentType: string
  fileName: string
}

const DisputeDetails: React.FC = () => {
  const navigate = useNavigate()
  const [showResolutionApproval, setShowApproval] = useState(false)
  const [showTransactionDetails, setShowTransactionDetails] = useState(false)
  const [disputeEvidence, setDisputeEvidence] =
    useState<DisputeEvidenceType | null>(null)

  const [searchParams] = useSearchParams()
  const dispute = searchParams.get("dispute")

  const disputeData: Dispute = parseJSON(dispute)

  const personalData = {
    "Dispute Reference": disputeData?.disputeReference,
    "Merchant ID": disputeData?.merchantId,
    "Business Name": disputeData?.merchantName,
    "Date Raised": disputeData?.createdDate,
    Reason: disputeData?.reason,
    "Resolved On": disputeData?.resolvedOn,
    "Resolution Status": (
      <Status variant={disputeData?.resolutionStatus}>
        {disputeData?.resolutionStatus}
      </Status>
    ),
  }
  return (
    <>
      <TopBar pageTitle="" />

      {showResolutionApproval ? (
        <ResolveDispute
          isOpen={showResolutionApproval}
          closeDisputeApproval={() => setShowApproval(false)}
          disputeDetails={disputeData}
        />
      ) : null}

      {showTransactionDetails ? (
        <TransactionDetails
          merchantId={disputeData.merchantId}
          transactionId={disputeData.transactionId}
          closeTransactionDetails={() => setShowTransactionDetails(false)}
        />
      ) : (
        <div className="flex gap-16">
          <div className="w-[30rem]">
            <div className=" bg-white">
              <div className="flex justify-between border-b border-b-gray-10/20 p-6">
                <div className="">
                  <div className="text-base font-medium">Dispute Details</div>
                  <div className="text-sm text-gray-10/60">
                    Here’s a more detailed view of actions performed on your
                    account{" "}
                  </div>
                </div>
              </div>
              <div className="flex min-h-352 max-w-4xl flex-col gap-8 p-6">
                {Object.entries(personalData).map(([label, value]) => (
                  <DataRow key={label} label={label} value={value} />
                ))}

                <div className="mt-4 flex">
                  <Button
                    onClick={() =>
                      setDisputeEvidence({
                        contentType: disputeData?.contentType,
                        filePath: disputeData.location,
                        fileName: disputeData.fileName,
                      })
                    }
                    variant="linkPrimary"
                  >
                    View dispute evidence
                  </Button>
                  {disputeData.resolutionStatus ===
                  STATUS.DISPUTE.UNRESOLVED ? (
                    <Button
                      onClick={() => setShowApproval(true)}
                      className="ml-auto h-11"
                      variant="outlinePrimary"
                    >
                      Mark as Resolved
                    </Button>
                  ) : null}
                </div>
              </div>
            </div>
            <BackArrow
              className="mt-6"
              onClick={() => navigate("/disputes")}
              label="Back"
            />
          </div>

          <DisputeHistory
            showTransactionDetails={() => setShowTransactionDetails(true)}
            disputeDetails={disputeData}
          />
        </div>
      )}

      {disputeEvidence ? (
        <DisputeEvidence
          isOpen={!!disputeEvidence}
          disputeEvidence={disputeEvidence}
          closeDisputeEvidence={() => setDisputeEvidence(null)}
        />
      ) : null}
    </>
  )
}
export default DisputeDetails
