export const STATUS = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
  KYC: {
    PENDING: "pending",
    SUBMITTED: "submitted",
    APPROVED: "approved",
    REJECTED: "rejected",
    REVIEW: "review",
  },
  TRANSACTION: {
    PENDING: "pending",
    SUCCESSFUL: "successful",
    FAILED: "failed",
  },
  CHANNEL: {
    ACTIVE: "active",
    DEACTIVATED: "deactivated",
  },
  DISPUTE: {
    RESOLVED: "resolved",
    UNRESOLVED: "unresolved",
  },
  SETTLEMENT: {
    STARTED: "started",
    PENDING: "pending",
    SUCCESSFUL: "successful",
    FAILED: "failed",
  },
}
