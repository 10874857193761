import React from "react"
import SettlementFilter from "./Filter/SettlementFilter"
import { settlementColumns } from "./Table/columns"
import useSettlement from "./hooks/useSettlement"
import Table from "components/Table"
import { Pagination } from "components/Pagination/PaginationComponent"
import PaginationInfo from "components/Pagination/PaginationInfo"
import Button from "components/button"
import ExportModal from "components/ExportModal/ExportModal"
import { queryKeys } from "constants/queryKeys"
import PermissionGate from "components/PermissionGate/PermissionGate"
import { PERMISSIONS } from "constants/permissions"
import ListAccessDenied from "components/PermissionGate/ListAccessDenied"
import { baseUrl } from "config/baseUrl"
import { settlementEndpoints } from "constants/endpoints"

interface SettlementListProps {
  castedStatus?: string
}
const SettlementList: React.FC<SettlementListProps> = ({ castedStatus }) => {
  const { filter, settlement, pagination, exportManager, search } =
    useSettlement({
      queryKey: queryKeys.settlement.fetchSettlements,
      castedStatus: castedStatus,
    })

  return (
    <PermissionGate
      allowedPermissions={[PERMISSIONS.VIEW_PAYOUTS]}
      fallback={<ListAccessDenied />}
    >
      <div>
        <div className="flex items-center justify-end gap-6 pt-2">
          <SettlementFilter filter={filter} search={search} />
        </div>
        <div className="mt-4 border border-[#D3D3D3]">
          <Table
            columns={settlementColumns}
            data={settlement.settlementList}
            isLoading={settlement.isLoading || settlement.refetchingSettlement}
            loadingSkeletonCount={10}
          />
          {settlement.settlementList?.length > 0 ? (
            <div className="flex h-[4.3125rem] items-center justify-between bg-white px-5">
              <PaginationInfo pagination={pagination} />

              <div>
                <Pagination pagination={pagination} />
              </div>
            </div>
          ) : null}
        </div>

        {settlement.settlementList?.length > 0 ? (
          <Button
            className="mt-9 w-[6rem]"
            onClick={() => exportManager.setShowExportModal(true)}
          >
            Export
          </Button>
        ) : null}

        {exportManager.showExportModal ? (
          <ExportModal
            isOpen={exportManager.showExportModal}
            closeModal={() => exportManager.setShowExportModal(false)}
            exportDataName="Settlement"
            downloadUrl={`${baseUrl.adminUserService}${settlementEndpoints.exportSettlements}`}
            downloadParams={{
              status: search.searchParams.status?.value,
              transactionAmount: search.searchParams.transactionAmount,
              payoutAmount: search.searchParams.payoutAmount,
              startDate: search.searchParams.startDate,
              endDate: search.searchParams.endDate,
              settlementReference: search.searchParams?.search?.trim(),
            }}
          />
        ) : null}
      </div>
    </PermissionGate>
  )
}
export default SettlementList
