import { AxiosError } from "axios"
import Button from "components/button"
import Input from "components/input"
import Modal from "components/modal/Modal"
import CustomSelect from "components/Select/CustomSelect"
import { queryKeys } from "constants/queryKeys"
import { queryClient } from "index"
import React, { useEffect } from "react"
import { FieldValues, FormProvider, useForm } from "react-hook-form"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { editUser } from "services/api/users"
import { handleError } from "utils/getAxiosErrorMessage"
import useRoles from "../../hooks/useRoles"
import { yupResolver } from "@hookform/resolvers/yup"
import { createUserSchema } from "../../validation-schemas/user"

const EditUserDialog: React.FC<{
  isOpen: boolean
  handleClose: () => void
  selectedUser: User
}> = ({ isOpen, handleClose, selectedUser }) => {
  const methods = useForm({
    resolver: yupResolver(createUserSchema),
  })
  const { adminRoles, loadingAdminRoles } = useRoles({
    queryKey: queryKeys.users.fetchAdminRoles,
  })

  useEffect(() => {
    if (selectedUser) {
      methods.reset({
        firstName: selectedUser.firstName,
        lastName: selectedUser.lastName,
        email: selectedUser.email,
        role: {
          value: selectedUser.roleId.toString(),
          label: selectedUser.role,
        },
      })
    }
  }, [selectedUser])

  const { mutate: handleCreateUser, isLoading: creatingUser } = useMutation(
    editUser,
    {
      onSuccess: (response) => {
        toast.success(response?.data?.message)
        queryClient.invalidateQueries(queryKeys.users.fetchUsers)
        handleClose()
      },
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    }
  )

  const onSubmit = (data: FieldValues) => {
    const payload = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      defaultRole: true,
      roleId: data.role.value,
      role: data.role.label,
      id: selectedUser.id,
      businessId: 0,
      mobileNumber: "",
    }
    handleCreateUser(payload)
  }

  return (
    <Modal
      contentContainerClass="p-6 max-w-xs"
      isOpen={isOpen}
      closeModal={handleClose}
    >
      <div className="mb-6">
        <h5 className="text-lg font-medium">Edit User</h5>
        <div className="text-xs font-normal">Edit TGI Pay admin user.</div>
      </div>

      <FormProvider {...methods}>
        <form
          className="flex flex-col gap-4"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Input label="First Name" name="firstName" />
          <Input label="Last Name" name="lastName" />
          <Input label="User Email Address" name="email" />
          <CustomSelect
            label="Role"
            options={adminRoles?.map((role) => {
              return {
                ...role,
                value: role.id.toString(),
                label: role.name,
              }
            })}
            name="role"
            isLoading={loadingAdminRoles}
          />
          <div className="mt-6 flex justify-end">
            <Button loading={creatingUser}>Save Changes</Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default EditUserDialog
