import Table from "components/Table"
import React from "react"
import { ChannelColumns } from "./Columns"
import useConfiguration from "pages/protected/Configuration/hooks/useConfiguration"
import { queryKeys } from "constants/queryKeys"
type Props = {
  channelData: ChannelType[]
  loading: boolean
}
const ChannelTable: React.FC<Props> = () => {
  const { data, loading } = useConfiguration(
    queryKeys.configuration.fetchChannels
  )

  return (
    <div className="py-6">
      <Table
        columns={ChannelColumns}
        data={data?.channelData}
        isLoading={loading.loadingChannels}
      />
    </div>
  )
}

export default ChannelTable
