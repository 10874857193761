import { DataRow } from "components/DataRow/DataRow"
import Status from "components/Status/Status"
import { queryKeys } from "constants/queryKeys"
import React from "react"
import { useSearchParams } from "react-router-dom"
import useTransaction from "../hooks/useTransaction"
import { numberUtils } from "utils/numberUtils"

interface DetailsPDFProps {
  forwardedRef: React.RefObject<HTMLDivElement>
}

const DetailsPDF: React.FC<DetailsPDFProps> = ({ forwardedRef }) => {
  const [searchParams] = useSearchParams()
  const transactionId = searchParams.get("transactionId")
  const merchantId = searchParams.get("merchantId")

  const { transactionDetails } = useTransaction({
    queryKey: queryKeys.transactions.fetchTransactionDetails,
    transactionId,
    merchantId,
  })
  const { data, loadingDetails } = transactionDetails

  const personalData = {
    "Transaction Reference": data?.tgipayReference,
    "Payment Channel": data?.paymentChannel,
    "Payment Processor": data?.processor,
    "Merchant ID ": data?.merchantId,
    "Merchant Details": data?.merchantName,
    "Customer Details":
      data && data?.customerFirstName + " " + data?.customerLastName,
    Amount: numberUtils.formatCustomAmount(data?.amount, "NGN"),
    "TGI Pay Fee": numberUtils.formatCustomAmount(data?.fee, "NGN"),
    "Transaction Date": data?.transactionDate,
    Status: <Status variant={data?.status}>{data?.status}</Status>,
  }

  return (
    <div
      style={{
        position: "absolute",
        left: "100%",
        top: 0,
      }}
    >
      <div className="bg-white" ref={forwardedRef}>
        <div className="flex justify-between border-b border-b-gray-10/20 p-6">
          <div className="">
            <div className="text-base font-medium">Transaction Details</div>
            <div className="text-sm text-gray-10/60">
              Details for transaction {data?.tgipayReference ?? " "}
            </div>
          </div>
        </div>
        <div className="flex min-h-352 flex-col gap-8 p-6">
          {Object.entries(personalData).map(([label, value]) => (
            <DataRow
              key={label}
              isLoading={loadingDetails}
              label={label}
              value={value}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
export default DetailsPDF
