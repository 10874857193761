import Button from "components/button"
import Input from "components/input"
import { PublicLayout } from "layout"
import { FormProvider } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { useLogin } from "hooks/useLogin"

const Login = () => {
  const navigate = useNavigate()
  const { methods, handleSubmit, onSubmit, isLoading } = useLogin()

  return (
    <PublicLayout
      mainTitle="Log in to your account"
      contentArea={
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Input label="Username" name="email" />

            <Input
              label="Password"
              type="password"
              name="tgip_secret"
              inputContainerProps={{ className: "mt-8" }}
            />
            {/* <div className="mt-8 flex items-center justify-between">
              <Input type="checkbox" label="Remember me" name="rememberMe" />

              <span
                className="cursor-pointer text-sm font-medium text-primary-default"
                onClick={() => navigate("/forgot-password")}
              >
                Forgot Password?
              </span>
            </div> */}
            <Button
              loading={isLoading}
              className="mt-8 w-full"
              variant="primary"
            >
              Log in
            </Button>
          </form>
        </FormProvider>
      }
    />
  )
}

export default Login
