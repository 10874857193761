import React, { useEffect } from "react"
import { Route, Routes } from "react-router-dom"
import { toast } from "react-toastify"
import { publicRoutes } from "routes/publicRoutes"

const App: React.FC = () => {
  useEffect(() => {
    let offlineToastId: string | number | null = null

    const handleOnlineStatusChange = () => {
      if (!navigator.onLine) {
        offlineToastId = toast.error(
          "You are offline! Please check your internet connection",
          {
            autoClose: false,
          }
        ) as string | number
      } else if (offlineToastId) {
        toast.dismiss(offlineToastId)
        offlineToastId = null
      }
    }

    window.addEventListener("online", handleOnlineStatusChange)
    window.addEventListener("offline", handleOnlineStatusChange)

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange)
      window.removeEventListener("offline", handleOnlineStatusChange)
    }
  }, [])

  return (
    <>
      <Routes>
        {publicRoutes.map(({ path, element, children }) => (
          <Route key={path} path={path} element={element}>
            {children &&
              children.map(({ path, element }) => (
                <Route key={path} path={path} element={element} />
              ))}
          </Route>
        ))}
      </Routes>
    </>
  )
}

export default App
