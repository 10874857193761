import { ReactComponent as DownloadIcon } from "assets/icons/download.svg"
import Button from "components/button"
import BackArrow from "components/button/BackArrow"
import { DataRow } from "components/DataRow/DataRow"
import Status from "components/Status/Status"
import TopBar from "layout/protected/ContentArea/TopBar"
import React, { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { parseJSON } from "utils/parseJSON"
import { savePDF } from "@progress/kendo-react-pdf"
import DetailsPDF from "./DetailsPDF"

const handleNull = (value: unknown) => {
  if (value !== null && value !== undefined) {
    return value
  }
  return ""
}

const AuditDetails: React.FC = () => {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const audit = searchParams.get("audit")
  const auditDetails = parseJSON(audit)

  useEffect(() => {
    if (!audit) navigate("/audit-trail")
  }, [audit])

  const auditData = {
    User:
      handleNull(auditDetails.firstName) +
      " " +
      handleNull(auditDetails.lastName),
    "User Email Address": auditDetails?.username,
    Role: auditDetails?.role,
    "Request Date": auditDetails?.requestDate,
    "Response Date": auditDetails?.responseDate,
    "IP Address": auditDetails?.ipAddress,
    Activity: auditDetails?.activity,
    "Activity Status": (
      <Status variant={auditDetails?.responseMessage}>
        {auditDetails?.responseMessage}
      </Status>
    ),
  }
  const container = React.useRef(null)

  const exportPDFWithMethod = () => {
    const element = container.current || document.body
    savePDF(element, {
      paperSize: "auto",
      margin: 40,
      fileName: `Audit Trail`,
    })
  }
  return (
    <>
      <TopBar pageTitle="" />

      <div className="  bg-white">
        <div className="flex justify-between border-b border-b-gray-10/20 p-6">
          <div className="">
            <div className="text-base font-medium">Audit Details</div>
            <div className="text-sm text-gray-10/60">
              Here’s a more detailed view of actions performed on your account{" "}
            </div>
          </div>
          <div>
            <Button
              onClick={exportPDFWithMethod}
              variant="linkPrimary"
              className="flex items-center gap-2"
            >
              <span>Download PDF </span>
              <DownloadIcon />
            </Button>
          </div>
        </div>
        <div className="flex min-h-352 max-w-4xl flex-col gap-8 p-6">
          {Object.entries(auditData).map(([label, value]) => (
            <DataRow key={label} label={label} value={value} />
          ))}
          <BackArrow onClick={() => navigate(-1)} label="Return to Audit" />
        </div>
      </div>
      <DetailsPDF forwardedRef={container} />
    </>
  )
}
export default AuditDetails
