import { AxiosError } from "axios"
import CustomSelect from "components/Select/CustomSelect"
import Button from "components/button"
import Input from "components/input"
import Modal from "components/modal/Modal"
import { queryKeys } from "constants/queryKeys"
import { queryClient } from "index"
import React, { useEffect } from "react"
import { FieldValues, FormProvider, useForm } from "react-hook-form"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { editSettlement } from "services/api/configuration"
import { handleError } from "utils/getAxiosErrorMessage"
// import useConfiguration from "../../hooks/useConfiguration"

const EditSettlementDialog: React.FC<{
  isOpen: boolean
  handleClose: () => void
  selectedSettlement: SettlementType
}> = ({ isOpen, handleClose, selectedSettlement }) => {
  const methods = useForm()

  useEffect(() => {
    if (selectedSettlement) {
      methods.reset({
        settlementName: selectedSettlement.name,
        settlementType: {
          label: selectedSettlement.type,
          value: selectedSettlement.type,
        },
        startTime: selectedSettlement.startTime,
        maximumAmount: selectedSettlement.maximumAmount,
      })
    }
  }, [selectedSettlement])

  const { mutate: handleEditSettlement, isLoading: editingSettlement } =
    useMutation(editSettlement, {
      onSuccess: (response) => {
        toast.success(response?.data?.message)
        queryClient.invalidateQueries(queryKeys.configuration.fetchSettlement)
        handleClose()
      },
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    })

  // const { data, loading } = useConfiguration(
  //   queryKeys.configuration.fetchFeeSettings
  // )

  const onSubmit = (data: FieldValues) => {
    const payload = {
      id: selectedSettlement.id,
      name: data.settlementName,
      type: data.settlementType.value,
      startTime: data.startTime,
      maximumAmount: data.maximumAmount,
    }
    handleEditSettlement(payload)
  }

  return (
    <Modal
      contentContainerClass="p-6 max-w-xl"
      isOpen={isOpen}
      closeModal={handleClose}
    >
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="mb-6">
          <h5 className="text-lg font-medium">Edit Settlement</h5>
          <div className="text-xs font-normal">
            Edit settlement configuration.
          </div>
        </div>

        <FormProvider {...methods}>
          <div className="flex flex-col gap-4">
            <Input label="Name" name="settlementName" />
            <CustomSelect
              label="Settlement Type"
              options={[
                { label: "Manual", value: "manual" },
                { label: "Automatic", value: "automatic" },
              ]}
              name="settlementType"
            />
            <Input label="Start Time" name="startTime" type="time" />
            <Input label="Max Amount Per Batch" name="maximumAmount" />
          </div>
        </FormProvider>
        <div className="flex justify-end gap-6 ">
          <Button
            onClick={handleClose}
            className="ml-4 mt-6"
            variant="outlinePrimary"
          >
            Cancel
          </Button>
          <Button className="mt-6" loading={editingSettlement}>
            Edit Settlement
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default EditSettlementDialog
