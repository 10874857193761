import React, { FC, useEffect, useRef, useState } from "react"
import { useOTP } from "./hooks/useOTP"

interface Props {
  onChange: (otp: string[]) => void
  fieldCount: number
}

const OTPField: FC<Props> = ({ fieldCount, onChange }): JSX.Element => {
  const [otp, setOtp] = useOTP(fieldCount)
  const [activeOTPIndex, setActiveOTPIndex] = useState<number>(0)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const newOTP = [...otp]
    newOTP[activeOTPIndex] = value.substring(value.length - 1)
    setOtp(newOTP)

    const nextIndex = value ? activeOTPIndex + 1 : activeOTPIndex - 1
    setActiveOTPIndex(Math.max(0, Math.min(nextIndex, fieldCount - 1)))

    onChange(newOTP)
  }

  const handleOnKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace") {
      e.preventDefault()
      const newOTP = [...otp]
      newOTP[index] = ""
      setOtp(newOTP)
      setActiveOTPIndex(Math.max(0, index - 1))
    }
  }

  const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault() // Prevent the default paste behavior
    const pasteData = e.clipboardData.getData("text").slice(0, fieldCount)
    const newOTP = [
      ...pasteData.split(""),
      ...Array(fieldCount - pasteData.length).fill(""),
    ]
    setOtp(newOTP)
    setActiveOTPIndex(Math.min(pasteData.length, fieldCount) - 1) // Move focus to the last pasted character
    onChange(newOTP)
  }

  useEffect(() => {
    inputRef.current?.focus()
  }, [activeOTPIndex])

  return (
    <div className="flex items-center justify-center space-x-2">
      {otp.map((value, index) => (
        <React.Fragment key={index}>
          <input
            ref={index === activeOTPIndex ? inputRef : null}
            type="text"
            inputMode="numeric"
            maxLength={1}
            value={value}
            onChange={handleOnChange}
            onKeyDown={(e) => handleOnKeyDown(e, index)}
            onPaste={handleOnPaste} // Add the paste event handler
            className="bg-transparent spin-button-none h-[45px] w-[45px] rounded border-2 border-gray-20 text-center text-xl font-semibold text-gray-10 outline-none transition focus:border-gray-20 focus:text-gray-10 focus:ring-gray-20"
          />
          {index < fieldCount - 1 && (
            <span className="bg-gray-400 w-2 py-0.5" />
          )}
        </React.Fragment>
      ))}
    </div>
  )
}

export default OTPField
