import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { yupResolver } from "@hookform/resolvers/yup"
import React from "react"
import { FieldValues, FormProvider, useForm } from "react-hook-form"
import Button from "components/button"
import Input from "components/input"
import { PublicLayout } from "layout"
import { forgotPasswordSchema } from "./validation-schemas"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"
import { useMutation } from "react-query"
import { forgotPassword } from "services/api/auth"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { handleError } from "utils/getAxiosErrorMessage"

const ForgotPassword = () => {
  const navigate = useNavigate()
  const methods = useForm({
    resolver: yupResolver(forgotPasswordSchema),
  })
  const { handleSubmit } = methods

  const { mutate: initiatePwdReset, isLoading } = useMutation(forgotPassword, {
    onSuccess: (response) => {
      toast.success(response.data.message)
      methods.reset()
    },
    onError: (error: AxiosError) => {
      toast.error(handleError(error))
    },
  })

  const onSubmit = (data: FieldValues) => {
    initiatePwdReset({
      email: data.email,
    })
  }

  return (
    <PublicLayout
      mainTitle="Forgot Password?"
      contentArea={
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Input
              label="Email Address"
              placeholder="samplemail@mail.com"
              name="email"
            />

            <Button
              loading={isLoading}
              className="mt-[3.5rem] w-full"
              variant="primary"
            >
              Reset Password
            </Button>
          </form>
        </FormProvider>
      }
    >
      <div
        className="mt-8 flex cursor-pointer items-center gap-2 font-medium text-primary-default"
        onClick={() => navigate("/")}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
        <span>Return to Log in</span>
      </div>
    </PublicLayout>
  )
}

export default ForgotPassword
