import React, { useState } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import { useNavigate } from "react-router-dom"
import Modal from "components/modal/Modal"
import Status from "components/Status/Status"
import { Dispute } from "types/Dispute"

const columnHelper = createColumnHelper<Dispute>()

export const disputeColumns = [
  columnHelper.accessor("disputeReference", {
    cell: (info) => info.getValue(),
    header: () => <span>Reference</span>,
  }),
  columnHelper.accessor("merchantId", {
    cell: (info) => info.getValue(),
    header: () => <span>Merchant Id</span>,
  }),
  columnHelper.accessor((row) => row.merchantName, {
    id: "merchantName",
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <span>Business Name</span>,
  }),
  columnHelper.accessor((row) => row.createdDate, {
    id: "createdDate",
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <span>Date Raised</span>,
  }),

  columnHelper.accessor((row) => row.reason, {
    id: "reason",
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <span>Reason</span>,
  }),

  columnHelper.accessor("resolutionStatus", {
    header: () => <span>Resolution</span>,
    cell: (info) => (
      <Status variant={info.getValue()}>{info.getValue()}</Status>
    ),
  }),
  columnHelper.accessor((row) => row.resolvedOn, {
    id: "resolvedOn",
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <span>Resolved On</span>,
  }),

  columnHelper.accessor("action", {
    header: "Action",
    cell: ({ cell }) => {
      const {
        row: { original: originalData },
      } = cell
      const navigate = useNavigate()
      const [showModal, setShowModal] = useState(false)
      function closeModal() {
        setShowModal(false)
      }
      return (
        <>
          <span
            onClick={() => {
              navigate(
                `details?dispute=${encodeURIComponent(
                  JSON.stringify(originalData)
                )}`
              )
              setShowModal(true)
            }}
            className="cursor-pointer font-semibold text-primary-default"
          >
            View
          </span>
          <Modal isOpen={showModal} closeModal={closeModal}>
            <div className="p-6">What do you want to put here?</div>
          </Modal>
        </>
      )
    },
  }),
]
