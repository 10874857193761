import React from "react"
import { DataRow } from "../../../../../components/DataRow/DataRow"
import useBreadcrumb from "hooks/useBreadcrumb"
import { numberUtils } from "utils/numberUtils"

type Props = {
  transactionDetails: TransactionDetails
  forwardedRef: React.RefObject<HTMLDivElement>
}
const TransactionDetailsPDF: React.FC<Props> = ({
  transactionDetails,
  forwardedRef,
}) => {
  useBreadcrumb([
    "Merchants",
    transactionDetails.customerName,
    transactionDetails.tgipayReference,
  ])
  return (
    <div
      style={{
        position: "absolute",
        left: "100%",
        top: 0,
      }}
    >
      <div className="mt-12 flex flex-col gap-8" ref={forwardedRef}>
        <div className="flex justify-between">
          <div>
            <div className="text-base font-medium">Transaction Details</div>
            <div className="text-sm font-normal text-gray-10/60">
              Details for transaction {transactionDetails?.tgipayReference}
            </div>
          </div>
        </div>

        <DataRow
          label="Transaction Reference"
          value={transactionDetails?.tgipayReference}
        />
        <DataRow
          label="Payment Channel"
          value={transactionDetails.paymentChannel}
        />
        <DataRow
          label="Payment Processor"
          value={transactionDetails.processor}
        />
        <DataRow
          label="Amount"
          value={numberUtils.formatNumber(transactionDetails.amount)}
        />
        <DataRow
          label="Transaction Date"
          value={transactionDetails.transactionDate}
        />
        <DataRow label="Status" value={transactionDetails.status} />
        <DataRow
          label="TGI PAY Fee"
          value={numberUtils.formatNumber(transactionDetails.fee)}
        />
        <DataRow
          label="Merchant Income"
          value={numberUtils.formatNumber(transactionDetails.income)}
        />
        <DataRow label="Transaction Narration" value="n/a" />
      </div>
    </div>
  )
}

export default TransactionDetailsPDF
