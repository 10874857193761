import React from "react"
import TopBar from "layout/protected/ContentArea/TopBar"
import Table from "components/Table"
import PaginationInfo from "components/Pagination/PaginationInfo"
import { Pagination } from "components/Pagination/PaginationComponent"
import Button from "components/button"
import useTransaction from "./hooks/useTransaction"
import { transactionColumns } from "./Table/columns"
import { queryKeys } from "constants/queryKeys"
import TransactionsFilter from "./Filter/TransactionsFilter"
import PermissionGate from "components/PermissionGate/PermissionGate"
import { PERMISSIONS } from "constants/permissions"
import ListAccessDenied from "components/PermissionGate/ListAccessDenied"
import { baseUrl } from "config/baseUrl"
import { transactionEndpoints } from "constants/endpoints"
import useExportModal from "hooks/useExportModal"
import TransactionsPDF from "./pdf/TransactionsPDF"

const Transactions = () => {
  const { filter, transaction, pagination, exportManager, search } =
    useTransaction({ queryKey: queryKeys.transactions.fetchTransactions })

  const { ExportModal, pdfContainer } = useExportModal({
    closeModal: () => exportManager.setShowExportModal(false),
    isOpen: exportManager.showExportModal,
    downloadUrl: `${baseUrl.adminUserService}${transactionEndpoints.exportTransactions}`,
    exportDataName: "Transactions",
    downloadParams: {
      status: search.searchParams.status?.value,
      startDate: search.searchParams.startDate,
      endDate: search.searchParams.endDate,
      transactionReference: search.searchParams?.search?.trim(),
    },
  })

  return (
    <PermissionGate
      allowedPermissions={[PERMISSIONS.VIEW_TRANSACTIONS]}
      fallback={<ListAccessDenied />}
    >
      <div>
        <TopBar
          pageTitle="Transactions"
          subTitle="Manage all transactions made on TGI Pay."
        />

        <div className="flex items-center justify-end gap-6 pt-2">
          <TransactionsFilter filter={filter} search={search} />
        </div>
        <div className="mt-4 border border-[#D3D3D3]">
          <Table
            columns={transactionColumns}
            data={transaction.transactionList}
            isLoading={transaction.isLoading}
            loadingSkeletonCount={10}
          />
          {transaction.transactionList?.length > 0 ? (
            <div className="flex h-[4.3125rem] items-center justify-between bg-white px-5">
              <PaginationInfo pagination={pagination} />

              <div>
                <Pagination pagination={pagination} />
              </div>
            </div>
          ) : null}
        </div>

        {transaction.transactionList?.length > 0 ? (
          <Button
            className="mt-9 w-[6rem]"
            onClick={() => exportManager.setShowExportModal(true)}
          >
            Export
          </Button>
        ) : null}

        {exportManager.showExportModal ? <ExportModal /> : null}
      </div>
      {exportManager.showExportModal && (
        <TransactionsPDF pdfContainerRef={pdfContainer} />
      )}
    </PermissionGate>
  )
}

export default Transactions
