import React from "react"
import { useNavigate } from "react-router-dom"
import TGIPLogo from "../../../assets/images/TGIPrimaryLogo.svg"
import { ReactComponent as LogoutIcon } from "../../../assets/icons/logout.svg"
import Menu from "../Menu/Menu"
import style from "../Menu/Menu.module.scss"

const Sidebar: React.FC = () => {
  const navigate = useNavigate()

  const handleLogout = () => {
    sessionStorage.removeItem("authUser")
    navigate("/")
  }

  return (
    <aside className="fixed hidden h-screen w-48 flex-col bg-white pt-16 md:flex">
      <div className="mb-12 flex items-center justify-center">
        <img src={TGIPLogo} alt="logo" className="h-[1.375rem] w-[5rem]" />
        <span className="ml-[5.33px] text-xs font-medium text-primary-default">
          Admin
        </span>
      </div>
      <div className="flex h-full flex-col">
        <Menu />
        <div
          className={`${style.menu} mt-auto flex h-[2.5rem] w-full cursor-pointer items-center gap-[0.875rem] pl-[0.875rem] text-xs font-normal hover:font-bold hover:text-primary-default`}
          onClick={handleLogout}
        >
          <LogoutIcon />
          Log Out
        </div>
      </div>
    </aside>
  )
}

export default Sidebar
