import React from "react"
import Modal from "components/modal/Modal"
import { MerchantApprovalRequest } from "types/merchants"
import { DataRow } from "components/DataRow/DataRow"
import Button from "components/button"

interface MerchantApprovalModalProps {
  isOpen: boolean
  closeModal: () => void
  originalData: MerchantApprovalRequest
  handleApprove: () => void
  handleDecline: () => void
}

const MerchantApprovalModal: React.FC<MerchantApprovalModalProps> = ({
  isOpen,
  closeModal,
  originalData,
  handleApprove,
  handleDecline,
}) => {
  const dataToDisplay = {
    Reason: originalData.reason,
    "Approval Request Code": originalData.approvalRequestCode,
    "Merchant ID": originalData.merchantId,
    "Business Name": originalData.businessName,
    "Business Type": originalData.type,
    Description: originalData.description,
    Category: originalData.category,
    Address: originalData.address,
    State: originalData.state,
    Country: originalData.country,
  }

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      contentContainerClass="!max-w-3xl"
    >
      <h3 className="p-6 text-center text-3xl font-bold">
        Approval Request Details
      </h3>
      <div className="flex flex-col gap-8 p-6">
        {Object.entries(dataToDisplay).map(([label, value]) => (
          <DataRow key={label} label={label} value={value} />
        ))}
      </div>
      <div className="flex justify-end gap-8 p-6">
        <Button variant="danger" onClick={handleDecline}>
          Decline
        </Button>
        <Button variant="primary" onClick={handleApprove}>
          Approve
        </Button>
      </div>
    </Modal>
  )
}

export default MerchantApprovalModal
