import React from "react"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "components/button"
import PermissionGate from "components/PermissionGate/PermissionGate"
import { PERMISSIONS } from "constants/permissions"
type Props = {
  toggleDropdown: () => void
  setShowCreateDialog: React.Dispatch<React.SetStateAction<boolean>>
  showDropdown: boolean
}
const RoleActions: React.FC<Props> = (props) => {
  return (
    <div className="flex">
      <PermissionGate allowedPermissions={[PERMISSIONS.CREATE_MERCHANT_ROLES]}>
        <Button
          onClick={() => props.setShowCreateDialog(true)}
          className="flex items-center gap-2"
        >
          <FontAwesomeIcon icon={faPlus} />
          <span>Create Merchant Role</span>
        </Button>
      </PermissionGate>
    </div>
  )
}

export default RoleActions
