import React, { useEffect, useRef } from "react"

interface Props {
  timeout: number
  onIdle: () => void
}

const IdleTimer: React.FC<Props> = ({ timeout, onIdle }) => {
  const timeoutRef = useRef<number | null>(null)

  const resetTimeout = () => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = window.setTimeout(onIdle, timeout)
  }

  useEffect(() => {
    resetTimeout()
    window.addEventListener("click", resetTimeout)
    window.addEventListener("keydown", resetTimeout)
    window.addEventListener("keyup", resetTimeout)
    window.addEventListener("mousedown", resetTimeout)
    window.addEventListener("mouseup", resetTimeout)
    window.addEventListener("mousemove", resetTimeout)
    window.addEventListener("wheel", resetTimeout)
    window.addEventListener("focus", resetTimeout)
    window.addEventListener("blur", resetTimeout)
    return () => {
      if (timeoutRef.current) {
        window.clearTimeout(timeoutRef.current)
      }
      window.removeEventListener("click", resetTimeout)
      window.removeEventListener("keydown", resetTimeout)
      window.removeEventListener("keyup", resetTimeout)
      window.removeEventListener("mousedown", resetTimeout)
      window.removeEventListener("mouseup", resetTimeout)
      window.removeEventListener("mousemove", resetTimeout)
      window.removeEventListener("wheel", resetTimeout)
      window.removeEventListener("focus", resetTimeout)
      window.removeEventListener("blur", resetTimeout)
    }
  }, [timeout, onIdle])

  return null
}

export default IdleTimer
