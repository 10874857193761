import { AxiosError } from "axios"
import { queryKeys } from "constants/queryKeys"
import useBreadcrumb from "hooks/useBreadcrumb"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useQuery } from "react-query"
import { toast } from "react-toastify"
import {
  fetchTransactiondetails,
  fetchTransactions,
} from "services/api/transactions"
import { encodeSearchParams } from "utils/encodeSearchParams"
import { handleError } from "utils/getAxiosErrorMessage"

const useTransaction = (txParams: {
  queryKey: string
  merchantId?: string | null
  transactionId?: string | null
  initialPageSize?: number
}) => {
  const { queryKey, merchantId, transactionId } = txParams
  const [showExportModal, setShowExportModal] = React.useState(false)
  const [searchParams, setSearchParams] = useState<{
    status?: { label: string; value: string | undefined }
    startDate?: string
    endDate?: string
    search?: string | undefined
    paymentChannelId?: number
  }>({
    status: { label: "All", value: undefined },
    startDate: "",
    endDate: "",
  })

  useBreadcrumb([])

  const [pageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)

  const { data: transactionResponse, isLoading } = useQuery(
    [queryKeys.merchant.fetchMerchantTxn, currentPage, pageSize, searchParams],
    () =>
      fetchTransactions({
        pageSize,
        currentPage,
        search: encodeSearchParams({
          status: searchParams.status?.value,
          startDate: searchParams.startDate,
          endDate: searchParams.endDate,
          transactionReference: searchParams?.search?.trim(),
        }),
      }),
    {
      enabled: queryKey === queryKeys.transactions.fetchTransactions,
    }
  )

  const { data: transactionResponsePDF, isLoading: isloadingPDF } = useQuery(
    [
      queryKeys.merchant.fetchMerchantTxn,
      queryKey === queryKeys.transactions.fetchTransactionsPDF,
      currentPage,
      pageSize,
      searchParams,
    ],
    () =>
      fetchTransactions({
        pageSize: 100,
        currentPage,
        search: encodeSearchParams({
          status: searchParams.status?.value,
          startDate: searchParams.startDate,
          endDate: searchParams.endDate,
          transactionReference: searchParams?.search?.trim(),
        }),
      }),
    {
      enabled: queryKey === queryKeys.transactions.fetchTransactionsPDF,
    }
  )

  const updateSearchParams = (
    payload: React.SetStateAction<{
      startDate: string
      endDate: string
      search?: string | undefined
    }>
  ) => {
    setSearchParams({
      ...searchParams,
      ...payload,
    })
  }

  const { data: detailsResponse, isLoading: loadingDetails } = useQuery(
    [
      queryKeys.transactions.fetchTransactionDetails,
      currentPage,
      merchantId,
      transactionId,
    ],
    () => fetchTransactiondetails({ transactionId, merchantId }),
    {
      enabled:
        !!transactionId &&
        !!merchantId &&
        queryKey === queryKeys.transactions.fetchTransactionDetails,
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    }
  )

  const transactionList: Transaction[] =
    transactionResponse?.data?.data?.content

  const transactionListPDF: Transaction[] =
    transactionResponsePDF?.data?.data?.content

  const totalElements = transactionResponse?.data?.data?.totalElements
  const totalPages = transactionResponse?.data?.data?.totalPages

  const filterMethods = useForm()
  const searchMethods = useForm()

  const { handleSubmit: handleFilter } = filterMethods
  const { handleSubmit: handleSearch } = searchMethods

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFilter = (data: any) => {
    updateSearchParams({
      ...data,
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSearch = (data: any) => {
    setSearchParams({
      ...searchParams,
      ...data,
    })
  }

  const handlePageChange = (page: { selected: number }) => {
    setCurrentPage(page.selected)
  }

  const handleresetFilter = () => {
    setSearchParams({
      status: { label: "All", value: undefined },
      startDate: "",
      endDate: "",
    })
  }

  const pagination = {
    pageSize,
    currentPage,
    totalElements,
    totalPages,
    handlePageChange,
  }

  const search = {
    searchMethods,
    handleSearch,
    onSearch,
    updateSearchParams,
    searchParams,
  }

  const filter = {
    filterMethods,
    handleFilter,
    onFilter,
    resetFilter: handleresetFilter,
  }

  const transaction = {
    transactionList,
    isLoading,
    transactionListPDF,
    isloadingPDF,
  }
  const transactionDetails: {
    data: TransactionDetails
    loadingDetails: boolean
  } = {
    data: detailsResponse?.data.data,
    loadingDetails,
  }

  const exportManager = { showExportModal, setShowExportModal }

  return {
    transaction,
    search,
    filter,
    pagination,
    exportManager,
    transactionDetails,
  }
}

export default useTransaction
