import Button from "components/button"
import BackArrow from "components/button/BackArrow"
import Input from "components/input"
import TopBar from "layout/protected/ContentArea/TopBar"
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react"
import { FormProvider, useForm } from "react-hook-form"
import EditRole from "./EditRole"
import CreateRole from "./CreateRole"
import RoleActions from "./RoleActions"
import Skeleton from "react-loading-skeleton"
import { queryKeys } from "constants/queryKeys"
import { Role } from "types/roles"
import useRoles from "../hooks/useRoles"
import PermissionGate from "components/PermissionGate/PermissionGate"
import { PERMISSIONS } from "constants/permissions"

const MerchantRoles: React.FC<{
  setShowMerchantRoles: Dispatch<SetStateAction<boolean>>
}> = ({ setShowMerchantRoles }) => {
  const permissionMethods = useForm()
  const [showEditDialog, setShowEditDialog] = useState(false)
  const [showCreateDialog, setShowCreateDialog] = useState(false)
  const { merchantRoles, loadingMerchantRoles } = useRoles({
    queryKey: queryKeys.users.fetchMerchantRoles,
  })

  const [selectedRole, setSelectedRole] = useState<Role>()
  const [showDropdown, setShowDropdown] = useState(false)

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown)
  }

  const selectFirstRole = useCallback(() => {
    if (merchantRoles?.length > 0 && !selectedRole) {
      setSelectedRole(merchantRoles[0])
    } else {
      const newSelected = merchantRoles?.find(
        (role) => role.id === selectedRole?.id
      )
      setSelectedRole(newSelected)
    }
  }, [merchantRoles])

  useEffect(() => {
    selectFirstRole()
  }, [merchantRoles])

  const handleCloseEditRole = () => {
    const newSelected = merchantRoles?.find(
      (role) => role.id === selectedRole?.id
    )
    setSelectedRole(newSelected)
    setShowEditDialog(false)
  }

  return (
    <>
      <TopBar
        pageTitle="Manage Merchant Roles"
        subTitle="Add and manage merchant roles and permissions."
        TopBarActions={
          <RoleActions
            toggleDropdown={toggleDropdown}
            setShowCreateDialog={setShowCreateDialog}
            showDropdown={showDropdown}
          />
        }
      />

      <div className="my-6 flex flex-1 gap-8 bg-white p-4">
        <div className="w-2/5 border border-dashed border-gray-20/80">
          <div className="bg-secondary-default/20 px-8 py-4 text-base font-semibold">
            Current Roles
          </div>
          <div>
            {loadingMerchantRoles ? (
              <Skeleton />
            ) : (
              <>
                {merchantRoles
                  .sort((a, b) => a.name.localeCompare(b.name))
                  ?.map((role, index) => (
                    <div
                      key={index}
                      className={`flex cursor-pointer gap-4 px-8 py-4 ${
                        selectedRole && role.id === selectedRole.id
                          ? "bg-primary-default text-white"
                          : ""
                      }`}
                      onClick={() => setSelectedRole(role)}
                    >
                      <span>{role.name}</span>
                    </div>
                  ))}
              </>
            )}
          </div>
        </div>
        <div className="w-1 bg-gray-20/80"></div>
        <div className="relative w-3/5 border border-dashed border-gray-20/80">
          <div>
            <div className="bg-secondary-default/20 p-4 text-base font-semibold">
              {selectedRole?.name}
            </div>
            <div className="p-4">
              <div className="text-sm">
                {selectedRole?.description || (
                  <span className="text-gray-10/50">
                    Select a role to view details
                  </span>
                )}
              </div>
              <div className="mt-8 text-sm text-gray-10/50">
                Team Members with this role: {selectedRole?.numberOfUsers || 0}
              </div>
            </div>
          </div>
          <div className="">
            <div className="bg-secondary-default/20 p-4 text-base font-semibold">
              Permissions ( {selectedRole?.permissions.length} )
            </div>
            <FormProvider {...permissionMethods}>
              {selectedRole ? (
                <>
                  {selectedRole?.permissions?.map((permission, index) => (
                    <div key={`permission-${index}`} className="flex gap-4 p-4">
                      <Input
                        type="checkbox"
                        name="role"
                        checkBoxProps={{
                          className: "bg-gray-20/50 border-none",
                        }}
                        checkMarkProps={{ fill: "#292727" }}
                        checked={true}
                      />
                      <span>{permission.name}</span>
                    </div>
                  ))}
                </>
              ) : (
                <div className="p-4 text-gray-10/50">
                  Select a role to view permissions
                </div>
              )}
            </FormProvider>
            {selectedRole?.permissions?.length === 0 && (
              <div className="flex h-full items-center justify-center p-4 text-sm text-gray-10/50">
                No permissions assigned to this role
              </div>
            )}
            {selectedRole ? (
              <PermissionGate
                allowedPermissions={[PERMISSIONS.MANAGE_MERCHANT_ROLES]}
              >
                <Button
                  className="absolute bottom-4 right-4"
                  variant="outlinePrimary"
                  onClick={() => setShowEditDialog(true)}
                >
                  Edit {selectedRole?.name}
                </Button>
              </PermissionGate>
            ) : null}
          </div>
        </div>
      </div>

      <BackArrow
        className="mt-auto"
        onClick={() => setShowMerchantRoles(false)}
        label="Go Back"
      />
      {selectedRole && showEditDialog ? (
        <EditRole
          isOpen={showEditDialog}
          handleClose={handleCloseEditRole}
          selectedRole={selectedRole}
        />
      ) : null}
      {showCreateDialog ? (
        <CreateRole
          isOpen={showCreateDialog}
          handleClose={() => setShowCreateDialog(false)}
        />
      ) : null}
    </>
  )
}

export default MerchantRoles
