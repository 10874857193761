import CustomSelect from "components/Select/CustomSelect"
import Button from "components/button"
import Input from "components/input"
import TopBar from "layout/protected/ContentArea/TopBar"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useNavigate, useSearchParams } from "react-router-dom"

const CreateFee: React.FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const tabIndex = searchParams.get("tabIndex")

  const methods = useForm()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onCreate = () => {
    // console.log(data)
  }

  return (
    <div>
      <TopBar
        pageTitle="Create New Fee"
        subTitle="Set fees for existing channels."
      />
      <div className="mt-8 w-full max-w-[65.25rem] rounded-lg bg-white p-7 lg:w-[60%] 2xl:max-w-full">
        <FormProvider {...methods}>
          <form
            className="flex w-[30rem] flex-col gap-4"
            onSubmit={methods.handleSubmit(onCreate)}
          >
            <CustomSelect label="Fee Type" options={[]} name="feeType" />

            <CustomSelect label="Select Channel" options={[]} name="channel" />
            <Input
              hasPrefix
              prefix="%"
              label="Fee Percentage"
              name="feePercentage"
            />
            <Input
              hasPrefix
              prefix="₦"
              label="Transaction Fee Cap"
              name="feeCap"
            />
          </form>
        </FormProvider>
      </div>
      <div className="flex max-w-[62.5rem]  justify-end gap-6 lg:w-[60%]">
        <Button
          className="ml-4 mt-6"
          variant="outlinePrimary"
          onClick={() => navigate(`/configuration?tabIndex=${tabIndex}`)}
        >
          Cancel
        </Button>
        <Button className="mt-6" onClick={() => methods.handleSubmit(onCreate)}>
          Create Fee
        </Button>
      </div>
    </div>
  )
}

export default CreateFee
