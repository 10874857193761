import { ReactComponent as DotsVertical } from "assets/icons/dots-vertical.svg"
import Dropdown from "rc-dropdown"
import "rc-dropdown/assets/index.css"
import React, { Dispatch, SetStateAction } from "react"
import { WorkflowT } from "types/workflow"

const ActionDropdown: React.FC<{
  setShowEditDialog: Dispatch<SetStateAction<boolean>>
  setShowSetupDialog: Dispatch<SetStateAction<boolean>>
  isOpen: boolean
  toggleOpen: () => void
  workflow: WorkflowT
}> = ({ setShowEditDialog, setShowSetupDialog }) => {
  const menu = (
    <div className="flex flex-col gap-2 bg-white shadow-xl">
      {/* <div
        className="cursor-pointer px-3 py-1 hover:bg-gray-20/50"
        onClick={() => setShowEditDialog(true)}
      >
        Edit Workflow
      </div> */}
      <div
        className="cursor-pointer px-3 py-1 hover:bg-gray-20/50"
        onClick={() => setShowSetupDialog(true)}
      >
        Setup Workflow
      </div>
    </div>
  )

  return (
    <div className="min-w-xs">
      <Dropdown trigger={["click"]} overlay={menu} animation="slide-up">
        <DotsVertical className="h-6 cursor-pointer" />
      </Dropdown>
    </div>
  )
}

export default ActionDropdown
