import { queryKeys } from "constants/queryKeys"
import { useQuery } from "react-query"
import { fetchAdminRoles, fetchMerchantRoles } from "services/api/users"
import { Role } from "types/roles"

const useRoles = (payload: { queryKey?: string; refetch?: boolean }) => {
  const {
    data: adminRolesResponse,
    isLoading: loadingAdminRoles,
    refetch: refetchAdminRoles,
  } = useQuery([queryKeys.users.fetchAdminRoles], fetchAdminRoles, {
    enabled: payload.queryKey === queryKeys.users.fetchAdminRoles,
  })

  const { data: merchantRolesResponse, isLoading: loadingMerchantRoles } =
    useQuery([queryKeys.users.fetchMerchantRoles], fetchMerchantRoles, {
      enabled: payload.queryKey === queryKeys.users.fetchMerchantRoles,
    })

  const adminRoles: Role[] = adminRolesResponse?.data.data
  const merchantRoles: Role[] = merchantRolesResponse?.data.data

  return {
    adminRoles,
    merchantRoles,
    loadingAdminRoles,
    loadingMerchantRoles,
    refetchAdminRoles,
  }
}

export default useRoles
