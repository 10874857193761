import Button from "components/button"
import OTPField from "components/input/OTPField"
import { PublicLayout } from "layout"

import { Link } from "react-router-dom"

import BackArrow from "components/button/BackArrow"
import { FC } from "react"
import useTwoFactor from "./hooks/useTwoFactor"
import Spinner from "components/spinner/Spinner"

const TwoFactor: FC = () => {
  const {
    handleRegenerateOtp,
    handleSubmit,
    OTPFieldValue,
    setError,
    error,
    isLoading,
    isRegeneratingOtp,
    timeLeft,
  } = useTwoFactor()

  return (
    <PublicLayout
      mainTitle="Two-Factor Authentication"
      contentArea={
        <form onSubmit={handleSubmit}>
          <div className="mb-8 text-center">
            Enter the authentication code sent to your email below to log in.
          </div>
          <OTPField
            fieldCount={6}
            onChange={(otp: string[]) => {
              OTPFieldValue(otp)
              setError("")
            }}
          />
          {error && (
            <div className="mt-2 text-center text-danger-default">{error}</div>
          )}
          <Button loading={isLoading} className="mt-8 w-full">
            Proceed To Log In
          </Button>

          {/* Resend OTP */}
          {timeLeft !== 0 ? (
            <div className="mt-5 text-center text-sm text-primary-default">
              Resend code in {timeLeft}s
            </div>
          ) : (
            <div className="mt-5 flex justify-center text-center text-sm text-primary-default">
              {isRegeneratingOtp ? (
                <Spinner variant="arrow" className="text-primary-default" />
              ) : null}
              <Button
                onClick={handleRegenerateOtp}
                type="button"
                variant="linkPrimary"
                className=" hover:font-medium hover:underline"
                disabled={isRegeneratingOtp}
              >
                Resend OTP
              </Button>
            </div>
          )}

          <div className="mt-20 flex items-center justify-center text-center">
            <span className="mr-2">
              <BackArrow />
            </span>
            <Link className="text-primary-default" to="/login">
              Go Back
            </Link>
          </div>
        </form>
      }
    />
  )
}
export default TwoFactor
