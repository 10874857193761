import React, { FC } from "react"
import useConfiguration from "../../hooks/useConfiguration"
import { queryKeys } from "constants/queryKeys"
import SettlementTable from "./Table/Table"

type Props = {
  tabIndex: number
}
const SettlementSettings: FC<Props> = () => {
  const { data, loading } = useConfiguration(
    queryKeys.configuration.fetchSettlement
  )

  return (
    <div>
      <div>
        <SettlementTable
          settlementData={data.settlementData}
          loading={loading.loadingSettlement}
        />
      </div>
    </div>
  )
}

export default SettlementSettings
