import { ReactComponent as DotsVertical } from "assets/icons/dots-vertical.svg"
import PermissionGate from "components/PermissionGate/PermissionGate"
import { PERMISSIONS } from "constants/permissions"
import Dropdown from "rc-dropdown"
import "rc-dropdown/assets/index.css"
import React, { Dispatch, SetStateAction } from "react"

const ActionDropdown: React.FC<{
  setShowConfirm: Dispatch<SetStateAction<boolean>>
  setShowEditDialog: Dispatch<SetStateAction<boolean>>
  channel: ChannelType
}> = ({ setShowConfirm, channel, setShowEditDialog }) => {
  const menu = (
    <PermissionGate allowedPermissions={[PERMISSIONS.MANAGE_USERS]}>
      <div className="flex flex-col gap-2 bg-white shadow-xl">
        <div
          onClick={() => setShowConfirm(true)}
          className="cursor-pointer px-3 py-1 hover:bg-gray-20/50"
        >
          {channel.active ? "De-activate Channel" : "Re-activate Channel"}
        </div>
      </div>
      <div className="flex flex-col gap-2 bg-white shadow-xl">
        <div
          onClick={() => setShowEditDialog(true)}
          className="cursor-pointer px-3 py-1 hover:bg-gray-20/50"
        >
          Edit Channel
        </div>
      </div>
    </PermissionGate>
  )

  return (
    <div className="min-w-xs">
      <Dropdown trigger={["click"]} overlay={menu} animation="slide-up">
        <DotsVertical className="h-6 cursor-pointer" />
      </Dropdown>
    </div>
  )
}

export default ActionDropdown
