import React from "react"
import { useAppSelector } from "redux/hooks"

interface PermissionGateProps {
  allowedPermissions: string[]
  children: React.ReactNode
  fallback?: React.ReactNode
}

const PermissionGate: React.FC<PermissionGateProps> = ({
  allowedPermissions,
  children,
  fallback,
}) => {
  const userProfile = useAppSelector((state) => state.user.profile)

  // Check if user has any of the allowed permissions
  const hasPermission = userProfile?.authorizations.some((permission) =>
    allowedPermissions.includes(permission)
  )

  // Render the children components only if user has permission
  // Note: the permission gate does not have any dom element so it does not affect the layout
  return hasPermission ? <>{children}</> : <>{fallback}</>
}

export default PermissionGate
