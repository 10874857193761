import React, { useState } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import { useNavigate } from "react-router-dom"
import Modal from "components/modal/Modal"
import Status from "components/Status/Status"
import { Merchant } from "types/merchants"

const columnHelper = createColumnHelper<Merchant>()

export const merchantColumns = [
  columnHelper.accessor("merchantId", {
    cell: (info) => info.getValue(),
    header: () => <span>Merchant ID</span>,
  }),
  columnHelper.accessor((row) => row.businessName, {
    id: "businessName",
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <span>Business Name</span>,
  }),
  columnHelper.accessor("adminFirstName", {
    header: () => "First Name",
    cell: (info) => <span>{info.renderValue()}</span>,
  }),
  columnHelper.accessor("adminLastName", {
    header: () => "Last Name",
    cell: (info) => <span>{info.renderValue()}</span>,
  }),
  columnHelper.accessor("state", {
    header: () => <span>State</span>,
  }),
  columnHelper.accessor("adminEmail", {
    header: "Admin Email",
  }),
  columnHelper.accessor("createdOn", {
    header: "Date Onboarded",
    cell: (info) => (
      <Status variant={info.getValue()}>{info.getValue()}</Status>
    ),
  }),
  columnHelper.accessor("action", {
    header: "Action",
    cell: ({ cell }) => {
      const {
        row: { original: originalData },
      } = cell
      const navigate = useNavigate()
      const [showModal, setShowModal] = useState(false)
      function closeModal() {
        setShowModal(false)
      }
      return (
        <>
          <span
            onClick={() => {
              navigate(`details?merchantId=${originalData.merchantId}`)
              setShowModal(true)
            }}
            className="cursor-pointer font-semibold text-primary-default"
          >
            View
          </span>
          <Modal isOpen={showModal} closeModal={closeModal}>
            <div className="p-6">What do you want to put here?</div>
          </Modal>
        </>
      )
    },
  }),
]
