import Button from "components/button"
import Modal from "components/modal/Modal"
import React from "react"

import { KYC } from "types/KYC"

type ApproveKYCModalProps = {
  isOpen: boolean
  closeApprovalDialog: () => void
  kycDetails: KYC
}
const ApproveKYCModal: React.FC<ApproveKYCModalProps> = ({
  isOpen,
  closeApprovalDialog,
  kycDetails,
}) => {
  return (
    <Modal isOpen={isOpen} closeModal={closeApprovalDialog}>
      <div className="flex flex-col items-center p-6">
        <h5 className="text-xl font-semibold">Approve KYC Request</h5>

        <div className="mt-6 text-center">
          You are about to approve KYC request for{" "}
          <span className="font-bold">{kycDetails.businessName}</span> and grant
          them permission to carry out live transactions on TGI pay.
        </div>
        <div className="mt-7 flex gap-4">
          <Button
            onClick={closeApprovalDialog}
            className="h-8"
            variant="linkPrimary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              closeApprovalDialog()
            }}
            className="h-8"
          >
            Approve
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ApproveKYCModal
