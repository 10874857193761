import React from "react"
import { Navigate } from "react-router-dom"
import { getAuthUser } from "utils/auth"

type ProtectedRouteT = {
  children: JSX.Element
}

const ProtectedRoute = ({ children }: ProtectedRouteT) => {
  const user = getAuthUser()
  if (!user) {
    return <Navigate to="/" replace />
  }
  return children
}
export default ProtectedRoute
