import jwt_decode from "jwt-decode"
import { TokenDetails } from "types/auth"

export const decodeJWT = (token: string | null): TokenDetails | undefined => {
  try {
    if (token) {
      const decoded: TokenDetails = jwt_decode(token)
      return decoded
    }
  } catch (error) {
    return
  }
}
