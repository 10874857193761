import { AxiosError } from "axios"
import { queryKeys } from "constants/queryKeys"
import { useQuery } from "react-query"
import { toast } from "react-toastify"
import {
  fetchMerchantAdmin,
  fetchMerchantDetails,
  fetchMerchantUserCount,
  fetchSettlementAccount,
} from "services/api/merchants"
import { handleError } from "utils/getAxiosErrorMessage"

export function useMerchantDetails(
  queryKey: string,
  merchantId: number | undefined,
  merchantUserId?: number | undefined
) {
  const { data: merchantResponse, isLoading } = useQuery(
    [queryKeys.merchant.fetchMerchantDetails, merchantId],
    () => fetchMerchantDetails(merchantId),
    {
      enabled:
        !!merchantId && queryKey === queryKeys.merchant.fetchMerchantDetails,
      onError: (error: AxiosError) => {
        if (queryKey === queryKeys.merchant.fetchMerchantDetails) {
          toast.error(handleError(error))
        }
      },
    }
  )

  const { data: userCountResponse, isLoading: loadingUserCount } = useQuery(
    [queryKeys.merchant.fetchMerchantUserCount, merchantId],
    () => fetchMerchantUserCount({ merchantId }),
    {
      enabled:
        !!merchantId && queryKey === queryKeys.merchant.fetchMerchantUserCount,
      onError: (error: AxiosError) => {
        if (queryKey === queryKeys.merchant.fetchMerchantUserCount) {
          toast.error(handleError(error))
        }
      },
    }
  )

  const { data: adminResponse, isLoading: loadingAdmin } = useQuery(
    [queryKeys.merchant.fetchMerchantAdmin, merchantId, merchantUserId],
    () => fetchMerchantAdmin({ merchantId, userId: merchantUserId }),
    {
      enabled:
        !!merchantId &&
        !!merchantUserId &&
        queryKey === queryKeys.merchant.fetchMerchantAdmin,
      onError: (error: AxiosError) => {
        if (queryKey === queryKeys.merchant.fetchMerchantAdmin) {
          toast.error(handleError(error))
        }
      },
    }
  )

  const { data: settlementAcctResponse, isLoading: loadingSettlementAcct } =
    useQuery(
      [queryKeys.merchant.fetchSettlementAccount, merchantId],
      () => fetchSettlementAccount({ merchantId }),
      {
        enabled:
          !!merchantId &&
          queryKey === queryKeys.merchant.fetchSettlementAccount,
        onError: (error: AxiosError) => {
          if (queryKey === queryKeys.merchant.fetchSettlementAccount) {
            toast.error(handleError(error))
          }
        },
      }
    )

  return {
    merchantData: merchantResponse?.data?.data,
    isLoading,
    userCount: userCountResponse?.data?.data,
    loadingUserCount,
    adminData: adminResponse?.data?.data,
    loadingAdmin,
    settlementAcctData: settlementAcctResponse?.data?.data,
    loadingSettlementAcct,
  }
}
