import React from "react"
import Modal from "components/modal/Modal"
import { ReactComponent as ExclamationDanger } from "assets/icons/merchants/exclamation-danger.svg"
import Button from "components/button"
import { useMutation } from "react-query"
import { toggleMerchantStatus } from "services/api/merchants"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { handleError } from "utils/getAxiosErrorMessage"
import { queryClient } from "index"
import { queryKeys } from "constants/queryKeys"
import { FormProvider, useForm } from "react-hook-form"
import TextArea from "components/input/TextArea"

const DeactivateDialog: React.FC<{
  isOpen: boolean
  merchantId: string | null
  closeModal: () => void
  merchantName: string | undefined
}> = ({ isOpen, closeModal, merchantId, merchantName }) => {
  const methods = useForm()
  const values = methods.watch()
  const { mutate: disableMerchant, isLoading } = useMutation(
    toggleMerchantStatus,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(queryKeys.merchant.fetchMerchantDetails)
        toast.success(response.data.message)
        closeModal()
      },
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    }
  )

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <FormProvider {...methods}>
        <div className="flex flex-col items-center p-6">
          <div className="flex max-w-xs flex-col items-center">
            <ExclamationDanger className="mb-5" />
            <h5 className="text-xl font-semibold ">Disable Merchant?</h5>
            <span className="mt-4 text-sm font-normal">
              Are you sure you want to disable{" "}
              <span className="font-medium">{merchantName}</span>?
            </span>

            <TextArea
              inputContainerProps={{ className: "w-full" }}
              name="reason"
              rows={3}
              placeholder="Reason for disabling..."
            />
            <div className="mt-8 flex w-full max-w-xs justify-between">
              <Button
                onClick={closeModal}
                variant="default"
                className="h-9 w-24 text-sm"
              >
                Cancel
              </Button>
              <Button
                loading={isLoading}
                onClick={() =>
                  disableMerchant({
                    merchantId,
                    enable: false,
                    reason: values.reason,
                  })
                }
                className="h-9 w-24 text-sm"
                variant="danger"
              >
                Proceed
              </Button>
            </div>
          </div>
        </div>{" "}
      </FormProvider>
    </Modal>
  )
}

export default DeactivateDialog
