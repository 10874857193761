import { yupResolver } from "@hookform/resolvers/yup"
import React from "react"
import { FieldValues, FormProvider, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import Button from "components/button"
import Input from "components/input"
import { PublicLayout } from "layout"
import { forgotPasswordSchema } from "./validation-schemas"
import { useMutation } from "react-query"
import { AxiosError } from "axios"
import { forgotPassword } from "services/api/auth"
import { toast } from "react-toastify"
import { handleError } from "utils/getAxiosErrorMessage"

const InvalidResetLink: React.FC = () => {
  const navigate = useNavigate()
  const methods = useForm({
    resolver: yupResolver(forgotPasswordSchema),
  })

  const { handleSubmit } = methods

  const { mutate: initiatePwdReset, isLoading } = useMutation(forgotPassword, {
    onSuccess: (response) => {
      toast.success(response.data.message)
      methods.reset()
    },
    onError: (error: AxiosError) => {
      toast.error(handleError(error))
    },
  })

  const onSubmit = (data: FieldValues) => {
    initiatePwdReset({
      email: data.email,
    })
  }
  return (
    <PublicLayout
      mainTitle="Invalid reset link"
      subTitle="Sorry, your password reset link is no longer valid. 
    Please make another request."
      contentArea={
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Input
              label="Email Address"
              placeholder="samplemail@mail.com"
              name="email"
            />

            <Button
              loading={isLoading}
              className="mt-[3.25rem] w-full"
              variant="primary"
            >
              Request Password Reset
            </Button>
          </form>
        </FormProvider>
      }
    />
  )
}

export default InvalidResetLink
