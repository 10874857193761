import { queryKeys } from "constants/queryKeys"
import React from "react"
import { DataRow } from "../../../../../components/DataRow/DataRow"
import { useMerchantDetails } from "../../hooks/useMerchantDetails"

const SettlementAccoount: React.FC<{ merchantId: number | undefined }> = ({
  merchantId,
}) => {
  const { settlementAcctData, loadingSettlementAcct } = useMerchantDetails(
    queryKeys.merchant.fetchSettlementAccount,
    merchantId
  )

  const bankData = {
    "Bank Name": settlementAcctData?.accountName,
    "Account Number": settlementAcctData?.accountNumber,
  }

  return (
    <div className="mt-11 flex min-h-352 max-w-4xl flex-col gap-8">
      {Object.entries(bankData).map(([label, value]) => (
        <DataRow
          key={label}
          isLoading={loadingSettlementAcct}
          label={label}
          value={value}
        />
      ))}
    </div>
  )
}

export default SettlementAccoount
