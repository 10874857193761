import { queryKeys } from "constants/queryKeys"
import { useQuery } from "react-query"
import { fetchAdminPermissions } from "services/api/users"

const usePermissions = (payload: { queryKey: string }) => {
  const { data: adminPermissionResponse, isLoading: loadingAdminPermissions } =
    useQuery([queryKeys.users.fetchAdminPermissions], fetchAdminPermissions, {
      enabled: payload.queryKey === queryKeys.users.fetchAdminPermissions,
    })

  const {
    data: merchantPermissionResponse,
    isLoading: loadingMerchantPermissions,
  } = useQuery([queryKeys.users.fetchAdminPermissions], fetchAdminPermissions, {
    enabled: payload.queryKey === queryKeys.users.fetchMerchantPermissions,
  })

  const adminPermissions: Permission[] = adminPermissionResponse?.data.data
  const merchantPermissions: Permission[] =
    merchantPermissionResponse?.data.data

  return {
    adminPermissions,
    merchantPermissions,
    loadingAdminPermissions,
    loadingMerchantPermissions,
  }
}

export default usePermissions
