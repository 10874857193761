import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "components/button"
import React, { FC } from "react"

type Props = {
  setShowCreateDialog: React.Dispatch<React.SetStateAction<boolean>>
}
const WorkflowActions: FC<Props> = (props) => {
  return (
    <Button
      onClick={() => props.setShowCreateDialog(true)}
      className="flex items-center gap-2"
    >
      <FontAwesomeIcon icon={faPlus} />
      <span>Create Workflow</span>
    </Button>
  )
}

export default WorkflowActions
