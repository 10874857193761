import { Tab } from "@headlessui/react"
import React from "react"
import { classNames } from "utils/classNames"

type TabProps = {
  label: string
  icon?: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

type TabPanelProps = {
  content: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

type TabsProps = {
  tabs: TabProps[]
  panels: TabPanelProps[]
  defaultIndex: number
}

export default function CustomTab({ tabs, panels, defaultIndex }: TabsProps) {
  return (
    <div className="w-full px-2  sm:px-0">
      <Tab.Group manual defaultIndex={defaultIndex}>
        <Tab.List className="flex justify-start gap-12 border-b-[3px] border-b-[#eae9e9]/60">
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              className={({ selected }) =>
                classNames(
                  "mb-[-3px] flex py-2.5 text-sm font-medium leading-5",
                  " ring-white ring-opacity-60 focus:outline-none",
                  selected
                    ? "border-b-[3px] border-b-primary-default font-semibold text-primary-default"
                    : "text-blue-100 text-gray-10/50 hover:bg-white/[0.12] hover:text-primary-default",
                  tab.className || ""
                )
              }
            >
              {tab.icon && <span className="mr-2">{tab.icon}</span>}
              {tab.label}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          {panels.map((panel, index) => (
            <Tab.Panel
              key={index}
              className={classNames(
                "rounded-xl bg-white p-3",
                " ring-white ring-opacity-60 focus:outline-none",
                panel.className || ""
              )}
              style={panel.style}
            >
              {panel.content}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}
