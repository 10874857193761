import React, { ReactNode } from "react"
import { twMerge } from "tailwind-merge"
import { STATUS } from "types/statuses"
type StatusProps = {
  children: ReactNode
  variant: string | boolean
}
const Status: React.FC<StatusProps> = ({ children, variant }) => {
  const getVariantClass = (variant: string | boolean) => {
    switch (variant?.toString()?.toLowerCase()) {
      case STATUS.SUCCESS:
      case STATUS.KYC.APPROVED:
      case STATUS.TRANSACTION.SUCCESSFUL:
      case STATUS.SETTLEMENT.SUCCESSFUL:
      case STATUS.CHANNEL.ACTIVE:
      case STATUS.DISPUTE.RESOLVED:
        return "text-success-default"
      case STATUS.ERROR:
      case STATUS.KYC.REJECTED:
      case STATUS.TRANSACTION.FAILED:
      case STATUS.CHANNEL.DEACTIVATED:
      case STATUS.SETTLEMENT.FAILED:
        return "text-danger-default"
      case STATUS.WARNING:
      case STATUS.KYC.PENDING:
      case STATUS.TRANSACTION.PENDING:
      case STATUS.DISPUTE.UNRESOLVED:
      case STATUS.SETTLEMENT.PENDING:
        return "text-warning-default"
      default:
        return ""
    }
  }

  return (
    <span
      className={twMerge(
        `font-medium ${
          variant?.toString()?.length > 0 ? getVariantClass(variant) : ""
        }`
      )}
    >
      {children}
    </span>
  )
}

export default Status
