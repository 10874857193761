import { DataRow } from "components/DataRow/DataRow"
import Status from "components/Status/Status"
import React from "react"
import { KYCListEntry } from "../types/kyc"
import { changeDateFormat, formatDate } from "utils/date"
import { DateFormat } from "constants/date"

type Props = {
  kycDetails: KYCListEntry
  isLoading: boolean
}
const MerchantInfo: React.FC<Props> = (props) => {
  const { kycDetails, isLoading } = props
  const { DAY_MONTH_YEAR, YEAR_MONTH_DAY } = DateFormat

  const formattedDate = formatDate(
    kycDetails.created
      ? new Date(
          changeDateFormat(
            kycDetails.created,
            DAY_MONTH_YEAR,
            YEAR_MONTH_DAY
          ) ?? kycDetails.created
        )
      : new Date()
  ).date

  const KYCDetailsData = [
    { label: "Business Name", value: kycDetails.businessName },
    { label: "Business Type", value: kycDetails.businessType },
    { label: "Business Category", value: kycDetails.businessCategory },
    { label: "Country", value: kycDetails.country },
    { label: "State", value: kycDetails.state },
    { label: "Business Address", value: kycDetails.address },
    { label: "Business Admin Email", value: kycDetails.businessAdminEmail },
    { label: "Admin First Name", value: kycDetails.businessAdminFirstName },
    { label: "Admin Last Name", value: kycDetails.businessAdminLastName },
    {
      label: "Date Created",
      value: formattedDate,
    },
    {
      label: "Business Description",
      value: kycDetails.businessDescription,
    },
    {
      label: "Tax Identification Number",
      value: kycDetails.tin,
    },
    {
      label: "Status",
      value: <Status variant={kycDetails.status}>{kycDetails.status}</Status>,
    },
  ]

  return (
    <>
      {KYCDetailsData.map((data, index) => (
        <DataRow
          key={`business-data-${index}`}
          label={data.label}
          value={data.value}
          isLoading={isLoading}
        />
      ))}
    </>
  )
}

export default MerchantInfo
