import { PaymentAxiosInstance } from "../axios/payment"
import { UserAxiosInstance } from "../axios/userApi"
import {
  kycEndpoints,
  paymentEndpoints,
  settlementEndpoints,
} from "../../constants/endpoints"
import { generateQueryString } from "utils/generateQueryString"
import { DateGrouping } from "constants/dashboard"

export const fetchTransactions = async (payload: {
  currentPage: number
  pageSize: number
}) => {
  const response = await PaymentAxiosInstance.get(
    `${paymentEndpoints.fetchTransactions}?page=${payload.currentPage}&size=${payload.pageSize}`
  )
  return response
}

export const fetchTransactiondetails = async (payload: {
  transactionId: string | null | undefined
}) => {
  const response = await PaymentAxiosInstance.get(
    `${paymentEndpoints.fetchTransactiondetails}/${payload.transactionId}`
  )
  return response
}

export const fetchTotalValueByDate = async (payload: {
  startDate: string
  endDate: string
}) => {
  const { startDate, endDate } = payload
  const queryParams = generateQueryString({ startDate, endDate })

  const endpoint = `${paymentEndpoints.fetchTotalValueByDate}${queryParams}`
  const response = await UserAxiosInstance.get(endpoint)
  return response
}

export const fetchAllTxByDateCount = async (payload: {
  startDate: string
  endDate: string
}) => {
  const { startDate, endDate } = payload
  const queryParams = generateQueryString({ startDate, endDate })

  const endpoint = `${paymentEndpoints.fetchAllTxByDateCount}${queryParams}`
  const response = await UserAxiosInstance.get(endpoint)

  return response
}

export const fetchSuccessfulValuePoints = async (payload: {
  startDate: string
  endDate: string
  dateGrouping: DateGrouping
}) => {
  const { startDate, endDate, dateGrouping } = payload

  const queryParams = generateQueryString({ startDate, endDate, dateGrouping })

  const endpoint = `${paymentEndpoints.fetchSuccessfulValuePoints}${queryParams}`
  const response = await UserAxiosInstance.get(endpoint)
  return response
}

export const fetchSuccessfulByDateCount = async (payload: {
  startDate: string
  endDate: string
}) => {
  const { startDate, endDate } = payload
  const queryParams = generateQueryString({ startDate, endDate })

  const endpoint = `${paymentEndpoints.fetchSuccessfulByDateCount}${queryParams}`
  const response = await UserAxiosInstance.get(endpoint)
  return response
}

export const fetchPendingKYCCount = async () => {
  const endpoint = `${kycEndpoints.pendingKYCCount}`
  const response = await UserAxiosInstance.get(endpoint)
  return response
}

export const fetchPendingSettlementCount = async (payload: {
  startDate: string
  endDate: string
}) => {
  const { startDate, endDate } = payload
  const queryParams = generateQueryString({ startDate, endDate })

  const endpoint = `${settlementEndpoints.fetchPendingByDateCount}${queryParams}`
  const response = await UserAxiosInstance.get(endpoint)
  return response
}

export const fetchFailedByDateCount = async (payload: {
  startDate: string
  endDate: string
}) => {
  const { startDate, endDate } = payload
  const queryParams = generateQueryString({ startDate, endDate })

  const endpoint = `${paymentEndpoints.fetchFailedByDateCount}${queryParams}`
  const response = await UserAxiosInstance.get(endpoint)
  return response
}

export const fetchActiveMerchantsByDate = async (payload: {
  startDate: string
  endDate: string
}) => {
  const { startDate, endDate } = payload
  const queryParams = generateQueryString({ startDate, endDate })

  const endpoint = `${paymentEndpoints.fetchActiveMerchantsByDate}${queryParams}`
  const response = await UserAxiosInstance.get(endpoint)
  return response
}
