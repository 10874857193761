import React from "react"

export const FileRenderer: React.FC<{
  contentType: string
  filePath: string
}> = ({ contentType, filePath }) => {
  if (contentType.startsWith("image/")) {
    return <img src={filePath} alt="file" />
  } else if (contentType === "application/pdf") {
    return (
      <div className="min-h-[70vh] w-full">
        <iframe
          src={`https://docs.google.com/gview?url=${filePath}&embedded=true`}
          style={{ height: "100%", width: "95%", position: "absolute" }}
        ></iframe>
      </div>
    )
  } else {
    return <p>Unsupported file type</p>
  }
}
