import { faUser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ReactComponent as MerchantProfileIcon } from "assets/icons/merchants/merchant-profile.svg"
import { ReactComponent as SettlementIcon } from "assets/icons/merchants/settlement.svg"
import TopBar from "layout/protected/ContentArea/TopBar"
import React from "react"

import CustomTab from "components/Tab/Tab"

import KYCListByStatus from "./KYCListByStatus"
import { STATUS } from "types/statuses"
import { useSearchParams } from "react-router-dom"
import KYCFromMakerChecker from "./KYCFromMakerChecker"

const KYC: React.FC = () => {
  const [searchParams] = useSearchParams()
  const tabIndex = searchParams.get("tabIndex")

  return (
    <div>
      <TopBar
        pageTitle="KYC Management"
        subTitle="Manage KYC documents of merchants."
      />

      <div className="mt-8 rounded-lg bg-white p-7 2xl:max-w-full">
        <CustomTab
          tabs={[
            {
              label: "Submitted for review",
              icon: <FontAwesomeIcon icon={faUser} />,
            },
            {
              label: "Approved",
              icon: <SettlementIcon />,
            },
            {
              label: "Declined",
              icon: <MerchantProfileIcon />,
            },
          ]}
          panels={[
            {
              content: (
                <KYCFromMakerChecker
                  status={STATUS.KYC.SUBMITTED}
                  tabIndex={0}
                />
              ),
            },
            {
              content: (
                <KYCListByStatus status={STATUS.KYC.APPROVED} tabIndex={1} />
              ),
            },
            {
              content: (
                <KYCListByStatus status={STATUS.KYC.REJECTED} tabIndex={2} />
              ),
            },
          ]}
          defaultIndex={Number(tabIndex)}
        />
      </div>
    </div>
  )
}

export default KYC
