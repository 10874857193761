import React, { FC, useState } from "react"
import BackArrow from "components/button/BackArrow"
import TransactionDetails from "./TransactionDetails"
import useBreadcrumb from "hooks/useBreadcrumb"
import TransactionTable from "./TransactionTable/TransactionTable"

const Transactions: FC<{ merchantId: number | undefined }> = ({
  merchantId,
}) => {
  const [transactionDetails, setTransactionDetails] =
    useState<TransactionDetails | null>(null)

  const { setData: updateBreadcrumb } = useBreadcrumb()

  return (
    <div className="flex min-h-352 flex-col">
      {transactionDetails ? (
        <>
          <TransactionDetails
            merchantId={merchantId}
            transactionDetails={transactionDetails}
          />
          <div className="mt-auto pt-8">
            <BackArrow
              onClick={() => {
                setTransactionDetails(null)
                updateBreadcrumb(["Merchants"])
              }}
              label="Back"
            />
          </div>
        </>
      ) : (
        <>
          <TransactionTable
            setTransactionDetails={setTransactionDetails}
            merchantId={merchantId}
          />
        </>
      )}
    </div>
  )
}

export default Transactions
