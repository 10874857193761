import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "components/button"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

interface TeamProps {
  icon: IconProp
  title: string
  description: string
  handleOpen?: () => void
}
export const Team: React.FC<TeamProps> = ({
  icon,
  title,
  description,
  handleOpen,
}) => {
  return (
    <div className="w-52 rounded bg-white p-4 shadow-custom">
      <div className="flex h-14 w-12 items-center justify-center bg-secondary-default/20 text-2xl">
        <FontAwesomeIcon icon={icon} />
      </div>
      <h6 className="mt-3 text-base font-bold">{title}</h6>
      <div className="mt-3 text-xs">{description}</div>
      <Button
        onClick={handleOpen}
        className="mt-5 h-9"
        variant="outlinePrimary"
      >
        Open
      </Button>
    </div>
  )
}
