import Table from "components/Table"
import React from "react"
import { SettlementColumns } from "./Columns"

import useConfiguration from "pages/protected/Configuration/hooks/useConfiguration"
import { queryKeys } from "constants/queryKeys"
type Props = {
  settlementData: SettlementType[]
  loading: boolean
}
const SettlementTable: React.FC<Props> = () => {
  const { data, loading } = useConfiguration(
    queryKeys.configuration.fetchSettlement
  )

  return (
    <div className="py-6">
      <Table
        columns={SettlementColumns}
        data={data.settlementData}
        isLoading={loading.loadingSettlement}
      />
    </div>
  )
}

export default SettlementTable
