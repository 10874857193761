import React, { FC } from "react"
import { FieldValues, FormProvider, useForm } from "react-hook-form"
import Input from "components/input"
import Modal from "components/modal/Modal"
import { yupResolver } from "@hookform/resolvers/yup"
import { createWorkflowSchema } from "../Teams/validation-schemas/workflow"

import CustomSelect from "components/Select/CustomSelect"
import Button from "components/button"
import { useWorkflow } from "./hooks/useWorkflow"
import { numberOfLevelOptions } from "constants/workflow"

type Props = {
  isOpen: boolean
  handleClose: () => void
}
const WorkflowCreate: FC<Props> = ({ isOpen, handleClose }) => {
  const methods = useForm({
    resolver: yupResolver(createWorkflowSchema),
  })

  const errors = methods.formState.errors

  const { workflow } = useWorkflow()

  const onCreate = (data: FieldValues) => {
    const payload = {
      name: data.name,
      clientAppCode: data.clientAppCode,
      numberOfLevel: data.numberOfLevel.value,
    }
    workflow.create.handleCreate(payload, {
      onSuccess: () => {
        handleClose()
      },
    })
  }

  return (
    <Modal
      contentContainerClass="p-6 max-w-sm"
      isOpen={isOpen}
      closeModal={handleClose}
    >
      <div className="mb-6">
        <h5 className="text-lg font-medium">Create Workflow</h5>
        <div className="text-xs font-normal">
          Provide details of the new workflow.
        </div>
      </div>

      <FormProvider {...methods}>
        <form
          className="flex flex-col gap-4"
          onSubmit={methods.handleSubmit(onCreate)}
        >
          <Input label="Workflow Name" name="name" />
          <CustomSelect
            label="Number of Levels"
            options={numberOfLevelOptions}
            name="numberOfLevel"
            //@ts-expect-error Error
            error={errors.numberOfLevel?.value?.message}
          />
          <Button type="submit" loading={workflow.create.isCreating}>
            Create Workflow
          </Button>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default WorkflowCreate
