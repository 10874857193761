import { queryKeys } from "constants/queryKeys"
import useBreadcrumb from "hooks/useBreadcrumb"
import { useState } from "react"
import { useQuery } from "react-query"
import {
  fetchMerchantTransactionDetails,
  fetchMerchantTransactions,
  fetchTotalSuccessfulTx,
  fetchTotalTxValue,
} from "services/api/merchants"

type MerchantTxPayload = {
  merchantId?: number | undefined | null
  transactionId?: string | null | undefined
  queryKey: string
}

const useMerchantTransactions = (payload: MerchantTxPayload) => {
  const { merchantId, queryKey, transactionId } = payload
  useBreadcrumb([])

  const [pageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)

  const { data: merchantTxResponse, isLoading } = useQuery(
    [queryKeys.merchant.fetchAllMerchants, currentPage],
    () => fetchMerchantTransactions({ pageSize, currentPage, merchantId }),
    {
      enabled: !!merchantId && queryKey === queryKeys.merchant.fetchMerchantTxn,
    }
  )

  const { data: merchantTxDetailsResponse, isLoading: loadingDetails } =
    useQuery(
      [queryKeys.merchant.fetchMerchantDetails, currentPage],
      () => fetchMerchantTransactionDetails({ merchantId, transactionId }),
      {
        enabled:
          !!transactionId &&
          !!merchantId &&
          queryKey === queryKeys.merchant.transactionDetails,
      }
    )

  const { data: successfulTxCountResponse } = useQuery(
    [queryKeys.merchant.fetchMerchantDetails],
    () => fetchTotalSuccessfulTx({ merchantId }),
    {
      enabled:
        !!merchantId && queryKey === queryKeys.merchant.fetchSuccessfulTxCount,
    }
  )

  const { data: totalTxResponse } = useQuery(
    [queryKeys.merchant.fetchTotalTxValue],
    () => fetchTotalTxValue({ merchantId }),
    {
      enabled:
        !!merchantId && queryKey === queryKeys.merchant.fetchSuccessfulTxCount,
    }
  )

  const { data: totalPayoutResponse } = useQuery(
    [queryKeys.merchant.fetchTotalPayout],
    () => fetchTotalTxValue({ merchantId }),
    {
      enabled:
        !!merchantId && queryKey === queryKeys.merchant.fetchSuccessfulTxCount,
    }
  )

  const merchantTxList: Transaction[] = merchantTxResponse?.data?.data?.content
  const totalElements = merchantTxResponse?.data?.data?.totalElements
  const totalPages = merchantTxResponse?.data?.data?.totalPages

  const handlePageChange = (page: { selected: number }) => {
    setCurrentPage(page.selected)
  }

  const pagination = {
    pageSize,
    currentPage,
    totalElements,
    totalPages,
    handlePageChange,
  }

  const merchantTx = { merchantTxList, isLoading }
  const merchantTxDetails = { merchantTxDetailsResponse, loadingDetails }
  const successfulTxCount = successfulTxCountResponse?.data?.data
  const totalTransactionValue = totalTxResponse?.data?.data
  const totalPayout = totalPayoutResponse?.data?.data

  return {
    merchantTx,
    pagination,
    merchantTxDetails,
    successfulTxCount,
    totalTransactionValue,
    totalPayout,
  }
}

export default useMerchantTransactions
