import { AxiosError } from "axios"
import Button from "components/button"
import TextArea from "components/input/TextArea"
import Modal from "components/modal/Modal"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { KYC, KYCDetailsType } from "types/KYC"
import { handleError } from "utils/getAxiosErrorMessage"
import { queryClient } from "index"
import { queryKeys } from "constants/queryKeys"
import { useNavigate } from "react-router-dom"
import env from "config/env"
import { declineWorkflowRequest } from "services/api/workflow"

type DeclineKYCModalProps = {
  isOpen: boolean
  closeDeclineDialog: () => void
  kycDetails: KYC
}
const DeclineKYCModal: React.FC<DeclineKYCModalProps> = ({
  isOpen,
  closeDeclineDialog,
  kycDetails,
}) => {
  const methods = useForm()
  const values = methods.watch()
  const navigate = useNavigate()

  const { mutate: handleKYCDecline, isLoading } = useMutation(
    declineWorkflowRequest,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(queryKeys.kyc.fetchPendingKYC)
        toast.success(response.data.message)
        closeDeclineDialog()
        navigate("/kyc")
      },
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    }
  )

  const handleClose = () => {
    methods.reset()
    closeDeclineDialog()
  }

  return (
    <Modal isOpen={isOpen} closeModal={handleClose}>
      <FormProvider {...methods}>
        <div className="flex flex-col items-center p-6">
          <h5 className="text-xl font-semibold">Decline KYC Request</h5>

          <div className="mt-6 text-center">
            You are about to decline KYC request for{" "}
            <span className="font-bold">{kycDetails.businessName}</span> Please
            state reason why this request is being declined.
          </div>
          <TextArea
            inputContainerProps={{ className: "w-full" }}
            name="reasonForDecline"
            rows={3}
            placeholder="Description..."
          />
          <div className="mt-7 flex gap-4">
            <Button onClick={handleClose} className="h-8" variant="linkPrimary">
              Cancel
            </Button>
            <Button
              loading={isLoading}
              onClick={() =>
                handleKYCDecline({
                  approvalRequestCode: kycDetails.approvalRequestCode,
                  workFlowCode: kycDetails.workFlowCode,
                  clientAppCode: env.WORKFLOW_CLIENT_CODE as string,
                  reason: values.reasonForDecline,
                })
              }
              className="h-8"
            >
              Decline
            </Button>
          </div>
        </div>
      </FormProvider>
    </Modal>
  )
}

export default DeclineKYCModal
