type SearchParams = Record<string, number | string | undefined>

export function encodeSearchParams(searchParams: SearchParams): string {
  const encodedParams: string[] = []

  // Loop through the keys of the object
  for (const key in searchParams) {
    if (Object.prototype.hasOwnProperty.call(searchParams, key)) {
      const value = searchParams[key]

      // Check if the value is not null, undefined, or an empty string
      if (value !== null && value !== undefined && value !== "") {
        // Append key and value as a query parameter to the encodedParams array
        const encodedKey = encodeURIComponent(key)
        const encodedValue = encodeURIComponent(value)
        encodedParams.push(`${encodedKey}=${encodedValue}`)
      }
    }
  }

  // Join the encodedParams array with '&' to create the final URL-encoded search query string
  const searchQuery = encodedParams.join("&")

  return searchQuery
}
