import { AxiosError } from "axios"
import CustomSelect from "components/Select/CustomSelect"
import Button from "components/button"
import BackArrow from "components/button/BackArrow"
import Input from "components/input"
import { queryKeys } from "constants/queryKeys"
import { queryClient } from "index"
import TopBar from "layout/protected/ContentArea/TopBar"
import React from "react"
import { FieldValues, FormProvider, useForm } from "react-hook-form"
import { useMutation } from "react-query"
import { useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import { createSettlement } from "services/api/configuration"
import { handleError } from "utils/getAxiosErrorMessage"

const CreateSettlement: React.FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const tabIndex = searchParams.get("tabIndex")
  const methods = useForm()

  const { mutate: handleCreateSettlement, isLoading: creatingSettlement } =
    useMutation(createSettlement, {
      onSuccess: (response) => {
        toast.success(response?.data?.message)
        queryClient.invalidateQueries(queryKeys.configuration.fetchSettlement)
      },
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onCreate = (data: FieldValues) => {
    const payload = {
      name: data.settlementName,
      type: data.settlementType.value,
      startTime: data.startTime,
      maximumAmount: data.maximumAmount,
    }
    handleCreateSettlement(payload)
  }
  return (
    <div>
      <TopBar
        pageTitle="Create Settlement"
        subTitle="Manage settings of merchant settlements."
      />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onCreate)}>
          <div className="mt-8 w-full max-w-[65.25rem] rounded-lg bg-white p-7 lg:w-[60%] 2xl:max-w-full">
            <div className="flex w-[30rem] flex-col gap-4">
              <Input label="Name" name="settlementName" />

              <CustomSelect
                label="Settlement Type"
                options={[
                  { label: "Manual", value: "manual" },
                  { label: "Automatic", value: "automatic" },
                ]}
                name="settlementType"
              />

              <Input label="Start Time" name="startTime" type="time" />
              <Input label="Max Amount Per Batch" name="maximumAmount" />
            </div>
          </div>
          <div className="flex justify-end gap-6">
            <Button
              className="ml-4 mt-6"
              variant="outlinePrimary"
              onClick={() => navigate(`/configuration?tabIndex=${tabIndex}`)}
              type="submit"
            >
              Cancel
            </Button>
            <Button loading={creatingSettlement} className="mt-6">
              Save Settlement
            </Button>
          </div>
        </form>
      </FormProvider>

      <BackArrow
        onClick={() => navigate(`/configuration?tabIndex=${tabIndex}`)}
        label="Return to Configuration Page"
        className="mt-6"
      />
    </div>
  )
}

export default CreateSettlement
