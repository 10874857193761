import React from "react"
import ExportModal from "../../../components/ExportModal/ExportModal"
import MerchantsFilter from "./Filter/MerchantsFilter"
import { merchantColumns } from "./Table/columns"
import { Pagination } from "components/Pagination/PaginationComponent"
import Table from "components/Table"
import PaginationInfo from "components/Pagination/PaginationInfo"
import useMerchants from "./hooks/useMerchants"
import PermissionGate from "components/PermissionGate/PermissionGate"
import { PERMISSIONS } from "constants/permissions"
import ListAccessDenied from "components/PermissionGate/ListAccessDenied"
import { merchantEndpoints } from "constants/endpoints"
import { baseUrl } from "config/baseUrl"

const MerchantList: React.FC = () => {
  const { filter, merchant, pagination, exportManager, search } = useMerchants()

  return (
    <PermissionGate
      allowedPermissions={[PERMISSIONS.VIEW_MERCHANTS]}
      fallback={<ListAccessDenied />}
    >
      <div className="relative">
        <div className="flex items-center justify-end gap-6 pt-2">
          <MerchantsFilter filter={filter} search={search} />
        </div>
        <div className="mt-4 border border-[#D3D3D3]">
          <Table
            columns={merchantColumns}
            data={merchant.merchantList}
            isLoading={merchant.isLoading}
            loadingSkeletonCount={10}
          />
          {merchant?.merchantList?.length > 0 ? (
            <div className="flex h-[4.3125rem] items-center justify-between bg-white px-5">
              <PaginationInfo pagination={pagination} />

              <div>
                <Pagination pagination={pagination} />
              </div>
            </div>
          ) : null}
        </div>

        {/* {merchant?.merchantList?.length > 0 ? (
          <Button
            className="mt-9 w-[6rem]"
            onClick={() => exportManager.setShowExportModal(true)}
          >
            Export
          </Button>
        ) : null} */}

        {exportManager.showExportModal ? (
          <ExportModal
            isOpen={exportManager.showExportModal}
            closeModal={() => exportManager.setShowExportModal(false)}
            exportDataName="Merchants"
            downloadUrl={`${baseUrl.adminUserService}${merchantEndpoints.fetchMerchantData}`}
            downloadParams={{
              state: search.searchParams.state?.label,
              country: search.searchParams.country?.label,
              dateCreated: search.searchParams.dateCreated,
              businessName: search.searchParams?.search?.trim(),
            }}
          />
        ) : null}
      </div>
    </PermissionGate>
  )
}

export default MerchantList
