import Table from "components/Table"
import React from "react"
import { merchantTransCols } from "./columns"
import useMerchantTransactions from "pages/protected/Merchants/hooks/useMerchantTransactions"
import { queryKeys } from "constants/queryKeys"
import PaginationInfo from "components/Pagination/PaginationInfo"
import { Pagination } from "components/Pagination/PaginationComponent"
type Props = {
  setTransactionDetails: React.Dispatch<
    React.SetStateAction<TransactionDetails | null>
  >
  merchantId: number | undefined
}
const TransactionTable: React.FC<Props> = ({
  setTransactionDetails,
  merchantId,
}) => {
  const { merchantTx, pagination } = useMerchantTransactions({
    queryKey: queryKeys.merchant.fetchMerchantTxn,
    merchantId,
  })
  function handleRowClick(rowData: TransactionDetails) {
    rowData && setTransactionDetails(rowData)
  }
  return (
    <>
      <Table
        columns={merchantTransCols}
        data={merchantTx.merchantTxList}
        handleRowClick={handleRowClick}
        isLoading={merchantTx.isLoading}
      />
      {merchantTx.merchantTxList?.length > 0 ? (
        <div className="flex h-[4.3125rem] items-center justify-between bg-white px-5">
          <PaginationInfo pagination={pagination} />

          <div>
            <Pagination pagination={pagination} />
          </div>
        </div>
      ) : null}
    </>
  )
}

export default TransactionTable
