import * as yup from "yup"

export const createUserSchema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email is required"),
  role: yup
    .object()
    .shape({
      value: yup.string().required("Role is required"),
    })
    .required("iss req"),
})
