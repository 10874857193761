import BackArrow from "components/button/BackArrow"
import { DataRow } from "components/DataRow/DataRow"
import Status from "components/Status/Status"
import { queryKeys } from "constants/queryKeys"
import useTransaction from "pages/protected/Transactions/hooks/useTransaction"
import React from "react"
import { numberUtils } from "utils/numberUtils"
type Props = {
  transactionId: number
  merchantId: number
  closeTransactionDetails: () => void
}
const TransactionDetails: React.FC<Props> = ({
  merchantId,
  transactionId,
  closeTransactionDetails,
}) => {
  const { transactionDetails } = useTransaction({
    queryKey: queryKeys.transactions.fetchTransactionDetails,
    transactionId: String(transactionId),
    merchantId: String(merchantId),
  })
  const { data, loadingDetails } = transactionDetails

  const personalData = {
    "Transaction Reference": data?.tgipayReference,
    "Payment Channel": data?.paymentChannel,
    "Payment Processor": data?.processor,
    "Merchant ID ": data?.merchantId,
    "Merchant Details": data?.merchantName,
    "Customer Details":
      data && data?.customerFirstName + " " + data?.customerLastName,
    Amount: numberUtils.formatNumber(data?.amount),
    "TGI Pay Fee": numberUtils.formatNumber(data?.fee),
    "Transaction Date": data?.transactionDate,
    Status: <Status variant={data?.status}>{data?.status}</Status>,
  }
  return (
    <>
      <div className="  bg-white">
        <div className="flex justify-between border-b border-b-gray-10/20 p-6">
          <div className="">
            <div className="text-base font-medium">Transaction Details</div>
            <div className="text-sm text-gray-10/60">
              Details for transaction {data?.tgipayReference ?? " "}
            </div>
          </div>
        </div>
        <div className="flex min-h-352 max-w-4xl flex-col gap-8 p-6">
          {Object.entries(personalData).map(([label, value]) => (
            <DataRow
              key={label}
              isLoading={loadingDetails}
              label={label}
              value={value}
            />
          ))}
          <BackArrow
            onClick={() => closeTransactionDetails()}
            label="Return to Dispute Details"
          />
        </div>
      </div>
    </>
  )
}
export default TransactionDetails
