import React, { ReactNode } from "react"
import { formatDate } from "utils/date"
interface Props {
  pageTitle: string
  subTitle?: ReactNode
  TopBarActions?: React.ReactNode
}

const TopBar: React.FC<Props> = ({ pageTitle, subTitle, TopBarActions }) => {
  return (
    <div className="sticky top-16 z-10 flex flex-col bg-[#F9FAFB]">
      <div className=" ml-auto mt-4 text-sm font-normal text-gray-10/50">
        {/* Today: 30th Oct 2022, 10:23 */}
        Today: {formatDate(new Date()).dateTime}
      </div>
      <div className="">
        <h3 className="mb-[7px] text-2xl font-semibold text-gray-10">
          {pageTitle}
        </h3>
        <div className="mb-[10px] flex items-center justify-between">
          {subTitle ? <p className="text-gray-10/60">{subTitle}</p> : null}
          {TopBarActions ? <div>{TopBarActions}</div> : null}
        </div>
      </div>
    </div>
  )
}

export default TopBar
