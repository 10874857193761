import { ReactComponent as DotsVertical } from "assets/icons/dots-vertical.svg"
import PermissionGate from "components/PermissionGate/PermissionGate"
import { PERMISSIONS } from "constants/permissions"
import Dropdown from "rc-dropdown"
import "rc-dropdown/assets/index.css"
import React, { Dispatch, SetStateAction } from "react"

const ActionDropdown: React.FC<{
  setShowEditDialog: Dispatch<SetStateAction<boolean>>
  setShowConfirm: Dispatch<SetStateAction<boolean>>
  isOpen: boolean
  toggleOpen: () => void
  user: User
}> = ({ setShowEditDialog, setShowConfirm, user }) => {
  const menu = (
    <PermissionGate allowedPermissions={[PERMISSIONS.MANAGE_USERS]}>
      <div className="flex flex-col gap-2 bg-white shadow-xl">
        <div
          className="cursor-pointer px-3 py-1 hover:bg-gray-20/50"
          onClick={() => setShowEditDialog(true)}
        >
          Edit User
        </div>
        <div
          onClick={() => setShowConfirm(true)}
          className="cursor-pointer px-3 py-1 hover:bg-gray-20/50"
        >
          {user.activated ? "De-activate User" : "Re-activate User"}
        </div>
      </div>
    </PermissionGate>
  )

  return (
    <div className="min-w-xs">
      <Dropdown trigger={["click"]} overlay={menu} animation="slide-up">
        <DotsVertical className="h-6 cursor-pointer" />
      </Dropdown>
    </div>
  )
}

export default ActionDropdown
