import { AxiosError } from "axios"
import { queryKeys } from "constants/queryKeys"
import { useQuery } from "react-query"
import { toast } from "react-toastify"
import { fetchDocumentTypes, fetchKYCDocuments } from "services/api/KYC"
import { handleError } from "utils/getAxiosErrorMessage"
import { KYCDocument } from "../Details/Details"
import { fetchMerchantBvnDetails } from "services/api/merchants"
import { BvnDetailsT } from "types/merchants"

type QueryDataProps = {
  merchantId?: number | undefined
  queryKey?: string
}

const useKYCDocs = (queryData: QueryDataProps) => {
  const { merchantId, queryKey } = queryData
  const { kyc, merchant } = queryKeys

  const { data: kycDocsResponse, isLoading } = useQuery(
    [kyc.fetchKYCDocuments, merchantId],
    () => fetchKYCDocuments({ merchantId }),
    {
      enabled: !!merchantId,
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    }
  )

  const { data: documentTypesResponse, isLoading: loadingDocTypes } = useQuery(
    [kyc.fetchDocumentTypes],
    () => fetchDocumentTypes(),
    {
      enabled: queryKey === kyc.fetchDocumentTypes,
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    }
  )

  const { data: bvnDetailsResponse, isLoading: loadingBvnDetails } = useQuery(
    [merchant.fetchBvnDetails],
    () => fetchMerchantBvnDetails({ merchantId }),
    {
      enabled: queryKey === kyc.fetchDocumentTypes,
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    }
  )

  const documentTypes = documentTypesResponse?.data.data
  const kycDocs: KYCDocument[] =
    kycDocsResponse?.data?.data?.documentDataList ?? []
  const bvnDetails: BvnDetailsT = bvnDetailsResponse?.data.data

  return {
    kycDocs,
    isLoading,
    documentTypes,
    loadingDocTypes,
    bvnDetails,
    loadingBvnDetails,
  }
}

export default useKYCDocs
