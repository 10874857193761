import React, { FC } from "react"
import Table from "components/Table"
import { queryKeys } from "constants/queryKeys"
import PermissionGate from "components/PermissionGate/PermissionGate"
import { PERMISSIONS } from "constants/permissions"
import ListAccessDenied from "components/PermissionGate/ListAccessDenied"
import { auditColumnsPDF } from "./columns"
import useAudit from "../hooks/useAudit"

type Props = {
  pdfContainerRef: React.RefObject<HTMLDivElement>
}

const AuditPDF: FC<Props> = ({ pdfContainerRef }) => {
  const { audit } = useAudit(queryKeys.audit.fetchAuditPDF)

  return (
    <PermissionGate
      allowedPermissions={[PERMISSIONS.VIEW_AUDIT_TRAIL]}
      fallback={<ListAccessDenied />}
    >
      <div
        style={{
          position: "absolute",
          left: "100%",
          top: 0,
          width: "100%",
        }}
      >
        <div ref={pdfContainerRef}>
          <div className="p-4">
            <h2 className="text-center text-4xl font-bold">Audit Trail</h2>
          </div>
          <div className="mt-4 border border-[#D3D3D3]">
            <Table
              columns={auditColumnsPDF}
              data={audit.auditPDFList}
              isLoading={audit.isLoadingPDF}
              loadingSkeletonCount={10}
            />
          </div>
        </div>
      </div>
    </PermissionGate>
  )
}

export default AuditPDF
