import React from "react"
import { createColumnHelper } from "@tanstack/react-table"
import Status from "components/Status/Status"
import { Audit } from "types/audit"
import { STATUS } from "types/statuses"

const columnHelper = createColumnHelper<Audit>()

export const auditColumnsPDF = [
  columnHelper.accessor("username", {
    cell: (info) => info.getValue(),
    header: () => <span>User Email</span>,
  }),
  columnHelper.accessor("activity", {
    cell: (info) => info.getValue(),
    header: () => <span>Activity</span>,
  }),

  columnHelper.accessor((row) => row.requestDate, {
    id: "requestDate",
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <span>Request Date</span>,
  }),

  columnHelper.accessor((row) => row.responseDate, {
    id: "Response Date",
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <span>Response Date</span>,
  }),

  columnHelper.accessor("requestProcessed", {
    header: () => <span>Status</span>,
    cell: (info) => (
      <Status variant={info.getValue() ? STATUS.SUCCESS : STATUS.ERROR}>
        {info.getValue() ? STATUS.SUCCESS : STATUS.ERROR}
      </Status>
    ),
  }),
]
