import React from "react"
interface Props {
  children?: React.ReactNode
}
const ContentArea: React.FC<Props> = ({ children }) => {
  return (
    <div className="absolute top-[3.875rem] w-full px-8 pb-8 md:left-48 md:w-[calc(100vw_-_12rem)]">
      {children}
    </div>
  )
}

export default ContentArea
