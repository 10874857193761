import { AxiosError } from "axios"
import { useState } from "react"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { validateUserOTP, generateUserOTP } from "services/api/otp"
import { handleError } from "utils/getAxiosErrorMessage"

export function useOTP() {
  const [inputedOTP, setInputedOTP] = useState<string>()

  const { mutate: handleOTPGeneration, isLoading: isGenerating } = useMutation(
    generateUserOTP,
    {
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    }
  )

  const { mutate: handleOTPValidation, isLoading: isValidating } = useMutation(
    validateUserOTP,
    {
      onError: (error: AxiosError) => {
        toast.error(handleError(error))
      },
    }
  )

  const otp = {
    generate: {
      isGenerating,
      handleOTPGeneration,
    },
    validate: {
      isValidating,
      handleOTPValidation,
    },
    inputedOTP,
    setInputedOTP,
  }

  return {
    otp,
  }
}
