import React from "react"
import { setBreadcrumbData } from "redux/ui/uiSlice"
import { useAppDispatch } from "redux/hooks"

const useBreadcrumb = (breadcrumData?: string[]) => {
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    dispatch(setBreadcrumbData(breadcrumData))
  }, [breadcrumData])

  React.useEffect(() => {
    return () => {
      dispatch(setBreadcrumbData([]))
    }
  }, [])

  const setData = (newData: string[]) => {
    dispatch(setBreadcrumbData(newData))
  }

  return { setData }
}

export default useBreadcrumb
