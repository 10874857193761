import React from "react"
import { createColumnHelper } from "@tanstack/react-table"
import Status from "components/Status/Status"
import { numberUtils } from "utils/numberUtils"

const columnHelper = createColumnHelper<Transaction>()

export const transactionColumnsPDF = [
  columnHelper.accessor("tgipayReference", {
    cell: (info) => <span className="min-w-max">{info.getValue()}</span>,
    header: () => <span>Transaction Ref</span>,
  }),
  columnHelper.accessor("merchantId", {
    cell: (info) => info.getValue(),
    header: () => <span>Merchant ID</span>,
  }),
  columnHelper.accessor((row) => row.amount, {
    id: "transactionAmount",
    cell: (info) => <span>{numberUtils.formatNumber(info.getValue())}</span>,
    header: () => <span>Amount(NGN)</span>,
  }),
  columnHelper.accessor("fee", {
    header: () => "TGI Pay Income (NGN)",
    cell: (info) => <span>{numberUtils.formatNumber(info.getValue())}</span>,
  }),
  columnHelper.accessor("paymentChannel", {
    header: () => "Payment Channel",
    cell: (info) => <span>{info.renderValue()}</span>,
  }),
  columnHelper.accessor("status", {
    header: () => <span>Status</span>,
    cell: (info) => (
      <Status variant={info.getValue()}>{info.getValue()}</Status>
    ),
  }),
  columnHelper.accessor("transactionDate", {
    header: "Created",
    cell: (info) => <span>{info.getValue()}</span>,
  }),
]
