import { queryKeys } from "constants/queryKeys"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { useQuery } from "react-query"
import {
  fetchChannels,
  fetchEmailSettings,
  fetchFeeSettings,
  fetchSettlement,
} from "services/api/configuration"

const useConfiguration = (queryKey: string) => {
  const [pageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)
  const filterMethods = useForm()
  const { handleSubmit: handleFilter } = filterMethods

  const { data: channelsResponse, isLoading: loadingChannels } = useQuery(
    [queryKeys.configuration.fetchChannels, currentPage, pageSize],
    () => fetchChannels(),
    {
      enabled: queryKey === queryKeys.configuration.fetchChannels,
    }
  )

  const { data: feeSettingsResponse, isLoading: loadingFeeSettings } = useQuery(
    [queryKeys.configuration.fetchFeeSettings, currentPage, pageSize],
    () => fetchFeeSettings(),
    {
      enabled: queryKey === queryKeys.configuration.fetchFeeSettings,
    }
  )

  const { data: settlementResponse, isLoading: loadingSettlement } = useQuery(
    [queryKeys.configuration.fetchSettlement, currentPage, pageSize],
    () => fetchSettlement(),
    {
      enabled: queryKey === queryKeys.configuration.fetchSettlement,
    }
  )

  const { data: emailSettingsResponse, isLoading: loadingEmailSettings } =
    useQuery(
      [queryKeys.configuration.fetchEmailSettings, currentPage, pageSize],
      () => fetchEmailSettings(),
      {
        enabled: queryKey === queryKeys.configuration.fetchEmailSettings,
      }
    )

  const handlePageChange = (page: { selected: number }) => {
    setCurrentPage(page.selected)
  }

  const pagination = {
    pageSize,
    currentPage,
    totalElements: {
      channels: channelsResponse?.data?.data?.totalElements,
      feeSettings: feeSettingsResponse?.data?.totalElements,
      settlement: settlementResponse?.data?.totalElements,
      email: emailSettingsResponse?.data?.totalElements,
    },
    totalPages: {
      channels: channelsResponse?.data?.data?.totalPages,
      feeSettings: feeSettingsResponse?.data?.totalPages,
      settlement: settlementResponse?.data?.totalPages,
      emailData: emailSettingsResponse?.data?.totalPages,
    },
    handlePageChange,
  }

  const onFilter = () => {
    // console.log("filter data", data)
  }

  const channelData: ChannelType[] = channelsResponse?.data?.data || []
  const feeSettingsData: FeeSettingsType[] =
    feeSettingsResponse?.data?.data || []
  const settlementData: SettlementType[] = settlementResponse?.data?.data || []
  const emailData: EmailSettingsType[] = emailSettingsResponse?.data?.data || []

  const filter = {
    filterMethods,
    handleFilter,
    onFilter,
    resetFilter: filterMethods.reset,
  }
  const data = {
    channelData,
    feeSettingsData,
    settlementData,
    emailData,
  }

  const loading: ConfigLoading = {
    loadingChannels,
    loadingFeeSettings,
    loadingSettlement,
    loadingEmailSettings,
  }

  return {
    data,
    loading,
    pagination,
    filter,
  }
}

export default useConfiguration
