import { queryKeys } from "constants/queryKeys"
import React from "react"
import { DataRow } from "../../../../../components/DataRow/DataRow"
import { useMerchantDetails } from "../../hooks/useMerchantDetails"
type Props = {
  merchantId: number | undefined
  merchantUserId: number | undefined
}
const PersonalProfile: React.FC<Props> = ({ merchantId, merchantUserId }) => {
  const { loadingAdmin, adminData } = useMerchantDetails(
    queryKeys.merchant.fetchMerchantAdmin,
    merchantId,
    merchantUserId
  )

  const personalData = {
    "First Name": adminData?.firstName,
    "Last Name": adminData?.lastName,
    "User Email Address": adminData?.email,
    Role: adminData?.role,
  }
  return (
    <div className="mt-11 flex min-h-352 max-w-4xl flex-col gap-8">
      {Object.entries(personalData).map(([label, value]) => (
        <DataRow
          key={label}
          isLoading={loadingAdmin}
          label={label}
          value={value}
        />
      ))}
    </div>
  )
}

export default PersonalProfile
