import { OTPEndpoints } from "constants/endpoints"
import { UserAxiosInstance } from "services/axios/userApi"

export const generateUserOTP = async (email: string) => {
  return UserAxiosInstance.post(`${OTPEndpoints.generateOTP}/${email}/send`)
}

export const validateUserOTP = async (payload: {
  email: string
  otp: number
}) => {
  return UserAxiosInstance.post(`${OTPEndpoints.validateOTP}`, payload)
}
