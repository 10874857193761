import React from "react"
import { ReactComponent as AccessDenied } from "assets/images/access-denied.svg"

const ListAccessDenied: React.FC = () => {
  return (
    <div>
      <div className="flex h-[60vh] flex-col items-center justify-center">
        <AccessDenied className="h-fit w-[10rem]" />
        <p className="text-black mt-4 text-2xl font-semibold">Access Denied</p>
        <p className="text-gray-900">
          You do not have permission to access this page
        </p>
      </div>
    </div>
  )
}

export default ListAccessDenied
