// import { UserAxiosInstance } from "../axios/userApi"
import { UserAxiosInstance } from "services/axios/userApi"
import { disputeEndpoints } from "../../constants/endpoints"

export const fetchDispute = async (payload: {
  currentPage: number
  pageSize: number
  search: string
}) => {
  const response = await UserAxiosInstance.get(
    `${disputeEndpoints.fetchDispute}?page=${payload.currentPage}&size=${payload.pageSize}&${payload.search}`
  )
  return response
}

export const fetchDisputeDetails = async (payload: {
  disputeId: string | null | undefined
}) => {
  const response = await UserAxiosInstance.get(
    `${disputeEndpoints.fetchDisputeDetails}/${payload.disputeId}`
  )
  return response
}

export const resolveDispute = async (payload: { disputeId: number }) => {
  const response = await UserAxiosInstance.put(
    `${disputeEndpoints.resolveDispute}/${payload.disputeId}`
  )
  return response
}
