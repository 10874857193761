import React, { useState } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import ActionDropdown from "./ActionDropdown"
import ChannelStatusUpdater from "./ChannelStatusUpdater"
import Status from "components/Status/Status"
import { STATUS } from "types/statuses"
import EditChannelDialog from "../EditChannel"
import { numberUtils } from "utils/numberUtils"
const columnHelper = createColumnHelper<ChannelType>()

export const ChannelColumns = [
  columnHelper.accessor("name", {
    cell: (info) => info.getValue(),
    header: () => <span>Payment Channel</span>,
  }),
  columnHelper.accessor("feeType", {
    cell: (info) => info.getValue(),
    header: () => <span>Fee Type</span>,
  }),
  columnHelper.accessor("flatFee", {
    cell: (info) => <span>{numberUtils.formatNumber(info.getValue())}</span>,
    header: () => <span>Flat Fee</span>,
  }),
  columnHelper.accessor("feePercentage", {
    cell: (info) => info.getValue(),
    header: () => <span>Percentage</span>,
  }),
  columnHelper.accessor("feeCap", {
    cell: (info) => <span>{numberUtils.formatNumber(info.getValue())}</span>,
    header: () => <span>Fee Cap</span>,
  }),

  columnHelper.accessor("active", {
    header: "Status",
    cell: (info) => (
      <Status
        variant={
          info.getValue() ? STATUS.CHANNEL.ACTIVE : STATUS.CHANNEL.DEACTIVATED
        }
      >
        {info.getValue() ? STATUS.CHANNEL.ACTIVE : STATUS.CHANNEL.DEACTIVATED}
      </Status>
    ),
  }),
  columnHelper.accessor("lastUpdated", {
    header: "Last Updated",
  }),
  columnHelper.accessor("action", {
    header: "Action",
    cell: (info) => {
      const {
        row: { original: originalData },
      } = info.cell

      const [showEditDialog, setShowEditDialog] = useState(false)
      const [showConfirm, setShowConfirm] = useState(false)

      return (
        <React.Fragment>
          <ActionDropdown
            setShowConfirm={setShowConfirm}
            channel={originalData}
            setShowEditDialog={setShowEditDialog}
          />

          {showConfirm ? (
            <ChannelStatusUpdater
              isOpen={showConfirm}
              closeStatusDialog={() => setShowConfirm(false)}
              channel={originalData}
            />
          ) : null}

          {showEditDialog ? (
            <EditChannelDialog
              handleClose={() => setShowEditDialog(false)}
              isOpen={showEditDialog}
              selectedChannel={originalData}
            />
          ) : null}
        </React.Fragment>
      )
    },
  }),
]
